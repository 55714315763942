import disposableDomainsArray from "consts/disposableDomains";
import validConsts from "consts/validations";
import _ from "lodash";
import { I18n } from "react-redux-i18n";
import { Toast } from "./toast";

export const isInteger = (value) =>
  value !== null && value !== undefined && !Number.isInteger(value)
    ? undefined
    : I18n.t("integerNumberValidation");

export const noDash = (value) => {
  return value && value[0] === "-"
    ? I18n.t("Must not start with -")
    : undefined;
};

export const required = (value) => (value ? undefined : I18n.t("Required"));

export const maxLength = (max) => (value) =>
  value && value.length > max
    ? I18n.t(`Must be ${max} characters or less`)
    : undefined;

export const minLength = (min) => (value) =>
  value && value.length < min
    ? I18n.t(`Must be ${min} characters or more`)
    : undefined;
export const notOnlyNumbers = (value) =>
  /^\d+$/.test(value) ? `Must not be numbers only.` : undefined;

export const mp4URL = (value) => {
  let field = value.name || value;
  return field &&
    field.length >= 4 &&
    `${field[field.length - 4]}${field[field.length - 3]}${
      field[field.length - 2]
    }${field[field.length - 1]}`.toLowerCase() === ".mp4"
    ? undefined
    : I18n.t("Must be a mp4 url");
};

export const gifURL = (value) => {
  let field = value.name || value;
  return field &&
    field.length >= 4 &&
    [".gif", ".jpg"].includes(
      `${field[field.length - 4]}${field[field.length - 3]}${
        field[field.length - 2]
      }${field[field.length - 1]}`.toLowerCase()
    )
    ? undefined
    : I18n.t("Must be a gif url");
};

export const fileIsMp4 = (value) =>
  value.type != "video/mp4" ? I18n.t("Must be a mp4 file") : undefined;
export const fileSize = (value) =>
  value.size > 500000 ? I18n.t("Maximum file is 500 KB") : undefined;
export const minNumLength = (min) => (value) =>
  value && value.length < min
    ? I18n.t(`Must be ${min} Numbers or more`)
    : undefined;
export const isAlphaNumericWithSpaces = (value) =>
  value && !validConsts.regex.WithSpaces.test(value)
    ? I18n.t(
        "Must only contain alpha-numeric characters, underscores, and dashes"
      )
    : undefined;

export const isNoLimiation5To500 = (value) =>
  value && !validConsts.regex.Limiation5To500.test(value)
    ? I18n.t("Must be less than 500 characters and more than 4 characters")
    : undefined;

export const validUrl = (value) =>
  value && !validConsts.regex.validUrl.test(value)
    ? I18n.t("Must be URL or DeepLink")
    : undefined;

export const validUrlnotdeeplink = (value) =>
  value && !validConsts.regex.validUrl.test(value)
    ? I18n.t("Must be URL")
    : undefined;
export const isImage = (value) =>
  value && !validConsts.regex.isImage.test(value)
    ? I18n.t("Must be URL with image extension  PNG Or JPG")
    : undefined;

export const isGooglePlayUrl = (value) =>
  value && !validConsts.regex.isGooglePlayUrl.test(value)
    ? I18n.t("Must be google play URL")
    : undefined;

export const isAppleStoreUrl = (value) =>
  value && !validConsts.regex.isAppleStoreUrl.test(value)
    ? I18n.t("Must be apple store URL")
    : undefined;

export const isAndroidDeeplink = (value) =>
  value && !validConsts.regex.isAndroidDeeplink.test(value)
    ? I18n.t("Invalid Android Deeplink  URL")
    : undefined;

export const isAppleDeeplinkUrl = (value) =>
  value && !validConsts.regex.isAppleDeeplinkUrl.test(value)
    ? I18n.t("Invalid apple deeplink url")
    : undefined;

export const isAlphaSpaces = (value) =>
  value && !validConsts.regex.isAlphaSpaces.test(value)
    ? I18n.t(
        "Must be  English only ,less than 40 characters and more than 5 characters"
      )
    : undefined;

export const isEmail = (value) =>
  value && !validConsts.regex.isEmail.test(value)
    ? I18n.t("Invalid email")
    : undefined;

export const isNumber = (value) =>
  value && !validConsts.regex.isNumber.test(value)
    ? I18n.t("Must be a number")
    : undefined;
export const isDecimal = (value) =>
  value && !validConsts.regex.isDecimal.test(value)
    ? I18n.t("Must be a decimal number")
    : undefined;
export const isNumberwithzero = (value) =>
  value && !validConsts.regex.isNumberwithzero.test(value)
    ? I18n.t("Must be a number")
    : undefined;

export const isImgUrl = (value) =>
  value && !validConsts.regex.isImgUrl.test(value)
    ? I18n.t("Must be URL with image extension  PNG Or JPG")
    : undefined;

export const notContainSpeciealChars = (value) =>
  value && validConsts.regex.notContainSpeciealChars.test(value)
    ? I18n.t(
        "Must not contain alpha-numeric characters, underscores, and dashes"
      )
    : undefined;

export const hasWhiteSpaces = (value) =>
  value && validConsts.regex.hasWhiteSpaces.test(value)
    ? I18n.t("You are not allowed to enter any spaces")
    : undefined;

export const trimm = (value) =>
  value && validConsts.regex.trimm.test(value)
    ? I18n.t("You are not allowed to enter any spaces in first and end ")
    : undefined;
export const trimmspace = (value) =>
  value && validConsts.regex.trimmspace.test(value)
    ? I18n.t("You are not allowed to enter any spaces in first and end ")
    : undefined;

export const isContaineForbiddens = (value) =>
  value && !validConsts.regex.forbiddenNames.test(value)
    ? I18n.t(
        "Subdomain Must Not Contain Forbidden Names, Only characters from (a-z)"
      )
    : undefined;

export const validateConfirmPassword = (value, allValues) =>
  value === allValues.newpass ? undefined : I18n.t("passwords don't match");

export const endofspicialChar = (value) =>
  value && value[value.length - 1] === ","
    ? I18n.t("Must not end with ,")
    : undefined;
export const validcustomDomain = (value) =>
  value && validConsts.regex.validcustomDomain.test(value)
    ? I18n.t("must be no spaces, not starts with http, have (dot)")
    : undefined;

export const noHttp = (value) =>
  value && validConsts.regex.noHttp.test(value)
    ? I18n.t("URL must be no have  ftp | http |  https")
    : undefined;

export const socialmediaValidation = (value) =>
  value && !validConsts.regex.socialmediaValidation.test(value)
    ? I18n.t("You enter invalid data")
    : undefined;
export const mobileNumberValidation = (value) =>
  value && !validConsts.regex.mobileNumberValidation.test(value)
    ? I18n.t("You enter invalid Number")
    : undefined;

export const mobValidate = (value) =>
  value && !validConsts.regex.mobValidate.test(value)
    ? I18n.t("You enter invalid Number")
    : undefined;

export const slugvalid = (value) =>
  value && validConsts.regex.slugvalid.test(value)
    ? I18n.t("must have not backslash (/)")
    : undefined;

export const specialCharacters = (value) =>
  value && validConsts.regex.specialCharacters.test(value)
    ? I18n.t("must have not any special character")
    : undefined;

export const alphanumeric = (value) =>
  value && !validConsts.regex.alphanumeric.test(value)
    ? I18n.t("must have only english characters and numbers")
    : undefined;

export const englishLetters = (value) =>
  value && !validConsts.regex.englishLetters.test(value)
    ? I18n.t("must have only english characters")
    : undefined;

export const disposableDomains = (value) =>
  value &&
  disposableDomainsArray.filter((domain) => value.indexOf(domain) !== -1)
    .length > 0
    ? I18n.t("Disposable domains not allowed")
    : undefined;

export const isValidUrl = (value) =>
  value && !validConsts.regex.validUrl.test(value)
    ? I18n.t("Must be a valid URL")
    : undefined;

export const isMultipleEmail = (value) => {
  if (value) {
    let idx = _.findIndex(
      value.split(","),
      (x) => !validConsts.regex.isEmail.test(x)
    );
    return idx === -1
      ? undefined
      : I18n.t(`Invalid email at position ${idx + 1}`);
  } else {
    return undefined;
  }
};

export const hasAccess = (suitId, inputProduct, permission) => {
  let user = localStorage.getItem("user.data");
  user = JSON.parse(user);
  if (!user.projects_products) return true;

  let suit = user.projects_products.filter((suit) => suit.suit_id == suitId);
  suit = suit[0];
  for (let product of suit.products)
    if (product.name == inputProduct && product.permission == permission)
      return true;

  Toast.error(
    I18n.t(
      "youDontHaveAccessPrivilegesToDoThisActionPleaseContactYourAccountAdministrator"
    )
  );

  return false;
};
