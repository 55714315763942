import { Spin, Switch } from "antd";
import React, { Component } from "react";
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import { getCurrentSuit, updateSuit } from "reducers/user";
import automessages from "../../../api/Automator";
import classes from "./ConfigureAutomators.module.scss";

const AUTOMATORS_LIST = [
  {
    id: 1,
    name: "automator_onSignUp",
  },
  {
    id: 2,
    name: "automator_abandonedCart",
  },
  {
    id: 3,
    name: "automator_orderConfirmation",
  },
];

const automationsMap = {
  automator_onSignUp: "onSignup",
  automator_abandonedCart: "abandonedCart",
  automator_orderConfirmation: "orderConfirmation",
};

class ConfigureAutomators extends Component {
  constructor(props) {
    super(props);

    this.state = {
      automators: {
        automator_onSignUp: false,
        automator_abandonedCart: false,
        automator_orderConfirmation: false,
      },

      loading: false,
    };

    this.currentSuit = null;
  }

  async componentDidMount() {
    const {
      currentSuit: { id: suitId },
      getCurrentSuit,
    } = this.props;

    this.setState({ loading: true });

    try {
      const suitData = (await getCurrentSuit(suitId)).value.body;
      this.currentSuit = suitData;

      const onboardingAutomations =
        suitData?.meta?.onboarding?.automations || [];

      if (onboardingAutomations.length) {
        const updatedAutomators = { ...this.state.automators };

        Object.entries(automationsMap).forEach(([key, value]) => {
          if (onboardingAutomations.includes(value)) {
            updatedAutomators[key] = true;
          }
        });

        this.setState({ automators: updatedAutomators });
      }
    } catch (error) {
      console.error("Error fetching suit data:", error);
    } finally {
      this.setState({ loading: false });
    }
  }

  handleSyncAutomators = async () => {
    const { currentSuit, handleSteps, handleAutomationsStep } = this.props;
    const { automators } = this.state;

    try {
      this.setState({ loading: true });

      const onboarding = this.currentSuit?.meta?.onboarding;
      const onboardingAutomations = new Set(onboarding?.automations || []);

      const automations = Object.keys(automators)
        .filter(
          (automator) =>
            automators[automator] &&
            !onboardingAutomations.has(automationsMap[automator])
        )
        .map((automator) => automationsMap[automator]);

      if (!automations.length) {
        return handleSteps();
      }

      await automessages.syncPrebuiltAutomators(currentSuit.id, {
        automations,
      });

      handleAutomationsStep(automations);
      handleSteps();
    } catch (error) {
      console.error(error);
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    const { handleBack, isArabic, isSalla } = this.props;

    const { loading } = this.state;

    return (
      <Spin spinning={loading}>
        <div
          className={classes.configureAutomators}
          dir={isArabic ? "rtl" : "ltr"}
        >
          <p className={classes.description}>
            {I18n.t("onBoarding_automators_desc")}
          </p>

          <div className={classes.automatorsList}>
            {AUTOMATORS_LIST.map((automator) => (
              <div key={automator.id} className={classes.automatorRow}>
                <span className={classes.automatorName}>
                  {I18n.t(automator.name)}
                </span>
                <Switch
                  className={classes.automatorSwitch}
                  checked={this.state.automators[automator.name]}
                  onChange={(checked) =>
                    this.setState({
                      automators: {
                        ...this.state.automators,
                        [automator.name]: checked,
                      },
                    })
                  }
                />
              </div>
            ))}
          </div>

          <div className="buttonsContainer">
            <button
              onClick={() => {
                handleBack();
              }}
              className="confirm cancel"
            >
              {isArabic ? <BsArrowRightShort /> : <BsArrowLeftShort />}
              <span>{I18n.t("backBtn")}</span>
            </button>
            <button
              onClick={() => {
                this.handleSyncAutomators();
              }}
              className="confirm"
            >
              <span>{I18n.t("continueBtn")}</span>
              {isArabic ? <BsArrowLeftShort /> : <BsArrowRightShort />}
            </button>
          </div>
        </div>
      </Spin>
    );
  }
}

export default connect((state) => ({ user: state.user }), {
  updateSuit,
  getCurrentSuit,
})(ConfigureAutomators);
