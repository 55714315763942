import MicroPages from "api/MicroPages";
import {
  CURRENCY,
  DATE_TYPE,
  NUMBER,
  OBJECT,
  STRING,
  URL,
} from "consts/TableColumnTypes";
import _ from "lodash";
import moment from "moment";
import { RESET_ERRORS } from "reducers/user";

// ------------------------------------
// Constants
// ------------------------------------

const GET_SUIT_LANDING_PAGES = "GET_SUIT_LANDING_PAGES";
const GET_SUIT_MICRO_PAGES = "GET_SUIT_MICRO_PAGES";
const GET_CURRENT_LANDINGPAGE = "GET_CURRENT_LANDINGPAGE";
const REMOVE_CURRENT_LANDINGPAGE = "REMOVE_CURRENT_LANDINGPAGE";
const CREATE_LANDINGPAGE = "CREATE_LANDINGPAGE";
const UPDATE_LANDINGPAGE = "UPDATE_LANDINGPAGE";
const DELETE_LANDINGPAGE = "DELETE_LANDINGPAGE";
const GET_SUIT_LANDINGPAGES_STATISTICS = "GET_SUIT_LANDINGPAGES_STATISTICS";
const GET_LANDINGPAGE_ANALYTICS = "GET_LANDINGPAGE_ANALYTICS";
const MOBILE_LANDING_PAGE_TOGGLE_ROW_ACTION_MENU =
  "MOBILE_LANDING_PAGE_TOGGLE_ROW_ACTION_MENU";
const CUSTOMER_MOBILE_LANDING_PAGE_CHECK_DATATABLE_ROW =
  "CUSTOMER_MOBILE_LANDING_PAGE_CHECK_DATATABLE_ROW";
const CUSTOMER_MOBILE_LANDING_PAGE_COLUMN_TOGGLE =
  "CUSTOMER_MOBILE_LANDING_PAGE_COLUMN_TOGGLE";
const CUSTOMER_MOBILE_LANDING_PAGE_RESET_COLUMNS =
  "CUSTOMER_MOBILE_LANDING_PAGE_RESET_COLUMNS";
const SORT_CUSTOMER_MOBILE_LANDING_PAGE = "SORT_CUSTOMER_MOBILE_LANDING_PAGE";
const CUSTOMER_MOBILE_LANDING_PAGE_CHECK_ALL_TABLE_ROWS =
  "CUSTOMER_MOBILE_LANDING_PAGE_CHECK_ALL_TABLE_ROWS";
const CUSTOMER_MOBILE_LANDING_PAGE_CLEAR_ACTION_MENU =
  "CUSTOMER_MOBILE_LANDING_PAGE_CLEAR_ACTION_MENU";
const DELETE_LANDINGPAGE_BULK = "DELETE_LANDINGPAGE_BULK";
const SET_MICRO_PAGE_META_DATA = "SET_MICRO_PAGE_META_DATA";

// ------------------------------------
// Actions
// ------------------------------------

export function getSuitLandingPages(suitId, apiKey, data, page) {
  return {
    type: GET_SUIT_LANDING_PAGES,
    payload: MicroPages.getSuitLandingPages(suitId, apiKey, data, page),
  };
}

export function getSuitMicroPages(suitId, apiKey, data, page) {
  return {
    type: GET_SUIT_MICRO_PAGES,
    payload: MicroPages.getSuitLandingPages(suitId, apiKey, data, page),
  };
}

export function getCurrentLandingPage(suitId, landingpageId) {
  return {
    type: GET_CURRENT_LANDINGPAGE,
    payload: MicroPages.getCurrentLandingPage(suitId, landingpageId),
  };
}

export function removeCurrentLandingPage() {
  return {
    type: REMOVE_CURRENT_LANDINGPAGE,
  };
}

export function createLandingPage(suitId, data) {
  return {
    type: CREATE_LANDINGPAGE,
    payload: MicroPages.createLandingPage(suitId, data),
  };
}

export function updateLandingPage(suitId, landingpageId, data) {
  //   ;
  return {
    type: UPDATE_LANDINGPAGE,
    payload: MicroPages.updateLandingPage(suitId, landingpageId, data),
  };
}

export function deleteLandingPage(suitId, landingPageId) {
  return {
    type: DELETE_LANDINGPAGE,
    payload: MicroPages.deleteLandingPage(suitId, landingPageId),
  };
}

// "landing_pages_ids": ["test1", "test2"]
export function deleteMicroPageBulk(suitId, landingPagesIds) {
  return {
    type: DELETE_LANDINGPAGE_BULK,
    payload: MicroPages.deleteMicroPageBulk(suitId, landingPagesIds),
  };
}

export function getSuitLandingPagesStatistics(suitId, data) {
  return {
    type: GET_SUIT_LANDINGPAGES_STATISTICS,
    payload: MicroPages.getSuitLandingPagesStatistics(suitId, data),
  };
}

export function getLandingPageAnalytics(suitId, landingPageId, data) {
  //   ;
  return {
    type: GET_LANDINGPAGE_ANALYTICS,
    payload: MicroPages.getLandingPageAnalytics(suitId, landingPageId, data),
  };
}

export function toggleRowActionMenu(index) {
  return {
    type: MOBILE_LANDING_PAGE_TOGGLE_ROW_ACTION_MENU,
    payload: index,
  };
}

export function checkDataTableRow(idx) {
  return {
    type: CUSTOMER_MOBILE_LANDING_PAGE_CHECK_DATATABLE_ROW,
    payload: idx,
  };
}

export function onColumnToggle(idx) {
  return {
    type: CUSTOMER_MOBILE_LANDING_PAGE_COLUMN_TOGGLE,
    payload: idx,
  };
}

export function onResetColumns() {
  return {
    type: CUSTOMER_MOBILE_LANDING_PAGE_RESET_COLUMNS,
  };
}

export const sortTable = (name, type) => async (dispatch) => {
  dispatch({
    type: SORT_CUSTOMER_MOBILE_LANDING_PAGE,
    payload: {
      type,
      name: name.split("_1")[0],
      descending: name.includes("_1"),
    },
  });
};

export function checkAllTableRows(newChecked) {
  return {
    type: CUSTOMER_MOBILE_LANDING_PAGE_CHECK_ALL_TABLE_ROWS,
    payload: newChecked,
  };
}

export function clearActionMenu(idx) {
  return {
    type: CUSTOMER_MOBILE_LANDING_PAGE_CLEAR_ACTION_MENU,
    payload: idx,
  };
}

export function setMicroPageMetaData(data) {
  return {
    type: SET_MICRO_PAGE_META_DATA,
    payload: data,
  };
}

// ------------------------------------
// Initial State
// ------------------------------------

const initialState = {
  landingpages: null,
  microPagesCount: 0,

  microPagesRaw: [],
  tableData: null,

  // cashing
  page: 1,
  fromDate: null,
  toDate: null,

  landingpage: null,

  statistics: null,
  analytics: null,

  isPending: false,
  error: false,
};

// ------------------------------------
// Reducer
// ------------------------------------

export default function MicroPagesReducer(state = initialState, action) {
  switch (action.type) {
    case RESET_ERRORS:
      return {
        ...state,
        error: false,
      };
    case `${GET_SUIT_LANDING_PAGES}_PENDING`:
    case `${GET_SUIT_MICRO_PAGES}_PENDING`:
    case `${GET_CURRENT_LANDINGPAGE}_PENDING`:
    case `${CREATE_LANDINGPAGE}_PENDING`:
    case `${UPDATE_LANDINGPAGE}_PENDING`:
    case `${DELETE_LANDINGPAGE}_PENDING`:
    case `${GET_SUIT_LANDINGPAGES_STATISTICS}_PENDING`:
    case `${GET_LANDINGPAGE_ANALYTICS}_PENDING`:
    case `${DELETE_LANDINGPAGE_BULK}_PENDING`:
      return {
        ...state,
        isPending: true,
        error: false,
      };
    case `${GET_SUIT_LANDING_PAGES}_REJECTED`:
    case `${GET_SUIT_MICRO_PAGES}_REJECTED`:
    case `${GET_CURRENT_LANDINGPAGE}_REJECTED`:
    case `${CREATE_LANDINGPAGE}_REJECTED`:
    case `${UPDATE_LANDINGPAGE}_REJECTED`:
    case `${DELETE_LANDINGPAGE}_REJECTED`:
    case `${GET_SUIT_LANDINGPAGES_STATISTICS}_REJECTED`:
    case `${GET_LANDINGPAGE_ANALYTICS}_REJECTED`:
    case `${DELETE_LANDINGPAGE_BULK}_REJECTED`:
      return {
        ...state,
        isPending: false,
        error: action.payload,
      };
    case `${GET_SUIT_LANDING_PAGES}_FULFILLED`:
      return {
        ...state,
        isPending: false,
        landingpages: action.payload.body.landingpages,
        microPagesCount: action.payload.body.count,
      };
    case `${GET_CURRENT_LANDINGPAGE}_FULFILLED`:
      return {
        ...state,
        isPending: false,
        landingpage: action.payload.body,
      };
    case REMOVE_CURRENT_LANDINGPAGE:
      return {
        ...state,
        landingpage: null,
        analytics: null,
      };
    case `${CREATE_LANDINGPAGE}_FULFILLED`:
    case `${UPDATE_LANDINGPAGE}_FULFILLED`:
    case `${DELETE_LANDINGPAGE}_FULFILLED`:
    case `${DELETE_LANDINGPAGE_BULK}_FULFILLED`:
      return {
        ...state,
        isPending: false,
      };
    case `${GET_SUIT_LANDINGPAGES_STATISTICS}_FULFILLED`:
      return {
        ...state,
        statistics: action.payload.body,
        isPending: false,
      };
    case `${GET_LANDINGPAGE_ANALYTICS}_FULFILLED`:
      return {
        ...state,
        analytics: action.payload.body,
        isPending: false,
      };

    case MOBILE_LANDING_PAGE_TOGGLE_ROW_ACTION_MENU:
      return {
        ...state,
        tableData: {
          ...state.tableData,
          rows: state.tableData.rows.map((row, rowIdx) => {
            return rowIdx === action.payload
              ? {
                  ...state.tableData.rows[rowIdx],
                  actionsMenu: !state.tableData.rows[rowIdx].actionsMenu,
                }
              : state.tableData.rows[rowIdx];
          }),
        },
      };

    case CUSTOMER_MOBILE_LANDING_PAGE_CHECK_DATATABLE_ROW:
      return {
        ...state,
        tableData: {
          ...state.tableData,
          rows: state.tableData.rows.map((row, rowIdx) => {
            return rowIdx === action.payload
              ? {
                  ...state.tableData.rows[rowIdx],
                  checked: !state.tableData.rows[rowIdx].checked,
                }
              : state.tableData.rows[rowIdx];
          }),
        },
      };

    case CUSTOMER_MOBILE_LANDING_PAGE_COLUMN_TOGGLE:
      return {
        ...state,
        tableData: {
          ...state.tableData,
          labels: state.tableData.labels.map((label, idx) => {
            return idx === action.payload
              ? {
                  ...label,
                  selected: !label.selected,
                }
              : label;
          }),
        },
      };

    case CUSTOMER_MOBILE_LANDING_PAGE_RESET_COLUMNS:
      return {
        ...state,
        tableData: {
          ...state.tableData,
          labels: state.tableData.labels.map((label) => ({
            ...label,
            selected: true,
          })),
        },
      };

    case SORT_CUSTOMER_MOBILE_LANDING_PAGE: {
      const indexOfKey = state.tableData.labels
        .map((label) => label.name)
        .indexOf(action.payload.name);

      let tableRows = null;

      switch (action.payload.type) {
        case NUMBER:
          tableRows = _.sortBy(
            state.tableData.rows,
            (row) => row.values[indexOfKey]
          );
          break;
        case STRING:
          tableRows = _.sortBy(state.tableData.rows, (row) =>
            row.values[indexOfKey].toLowerCase()
          );
          break;
        case CURRENCY:
          tableRows = _.sortBy(state.tableData.rows, (row) =>
            parseFloat(row.values[indexOfKey].split(" ")[0])
          );
          break;
        case DATE_TYPE:
          tableRows = _.sortBy(state.tableData.rows, (row) =>
            new Date(row.values[indexOfKey]).getTime()
          );
          break;
      }
      if (action.payload.descending) {
        _.reverse(tableRows);
      }
      return {
        ...state,
        tableData: {
          ...state.tableData,
          rows: tableRows,
        },
      };
    }

    case CUSTOMER_MOBILE_LANDING_PAGE_CHECK_ALL_TABLE_ROWS:
      return {
        ...state,
        tableData: {
          ...state.tableData,
          rows: state.tableData.rows.map((row) => ({
            ...row,
            checked: action.payload,
          })),
        },
      };

    case CUSTOMER_MOBILE_LANDING_PAGE_CLEAR_ACTION_MENU:
      return {
        ...state,
        tableData: {
          ...state.tableData,
          rows: state.tableData.rows.map((row, idx) => {
            return idx === action.payload
              ? { ...row, actionsMenu: false }
              : row;
          }),
        },
      };

    case `${GET_SUIT_MICRO_PAGES}_FULFILLED`:
      return {
        ...state,
        isPending: false,
        tableData: prepareTableDate(action.payload.body.landingpages),
        microPagesCount: action.payload.body.count,
        microPagesRaw: action.payload.body.landingpages,
      };

    case SET_MICRO_PAGE_META_DATA:
      return {
        ...state,
        page: action.payload.page,
        fromDate: action.payload.fromDate,
        toDate: action.payload.toDate,
      };
    default:
      return state;
  }
}

function prepareTableDate(tableData) {
  const dateFormat = "DD MMM YY HH:mm:ss";

  const labels = [
    {
      name: "Name",
      has_sort: false,
      type: STRING,
      selected: true,
      varName: ["name"],
    },
    {
      name: "URL",
      has_sort: false,
      type: URL,
      selected: true,
      varName: ["url"],
    },
    {
      name: "HITS",
      has_sort: true,
      type: NUMBER,
      selected: true,
      varName: ["clicks"],
    },
    {
      name: "DATE CREATED",
      has_sort: true,
      type: DATE_TYPE,
      selected: true,
      varName: ["created_at"],
    },
    {
      name: "SLUG",
      has_sort: false,
      type: STRING,
      selected: false,
      varName: ["slug"],
      defaultValue: "N/A",
    },
    {
      name: "MEDIA SOURCE",
      has_sort: false,
      type: STRING,
      selected: false,
      varName: ["media_source"],
      defaultValue: "N/A",
    },
    {
      name: "TAG",
      has_sort: false,
      type: STRING,
      selected: false,
      varName: ["tag"],
      defaultValue: "N/A",
    },
  ];

  const rows = tableData.map((row) => ({
    checked: false,
    actionsMenu: false,
    id: row.id,

    values: labels.map((label) => {
      if (label.type === DATE_TYPE) {
        return moment(moment.utc(_.get(row, label.varName, label.defaultValue)))
          .local()
          .format(dateFormat);
      } else if (label.type === OBJECT) {
        return JSON.stringify(_.get(row, label.varName, label.defaultValue));
      }
      return _.get(row, label.varName, label.defaultValue);
    }),
  }));

  return { labels, rows, actions: true };
}
