import { DatePicker } from "antd";
import { arabicDateFormat, normalizeDate } from "modules/helpers";
import { locale } from "modules/i18n";
import { getSuitBySubdomain } from "modules/user";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import "react-day-picker/lib/style.css";
import { I18n } from "react-redux-i18n";
import classes from "./DateFilter.scss";

class DateFilter extends React.Component {
  state = {
    type: "this-month",
    fromDate: moment().startOf("month").format("YYYY-MM-DD"),
    toDate: moment().endOf("month").format("YYYY-MM-DD"),
    lastType: "this-month",
    projectCreatedDate: moment.utc(
      getSuitBySubdomain(this.props.subdomain)
        ? getSuitBySubdomain(this.props.subdomain).created_at
        : moment().endOf("month")
    ),
    open: false,
    initialized: false,
  };

  componentWillMount() {
    if (this.props.initial) {
      let updatedValues = { lastType: this.props.initial };
      let values = this.props.values;
      if (values) {
        if (values.fromDate) updatedValues.fromDate = values.fromDate;
        if (values.toDate) updatedValues.toDate = values.toDate;
      }
      this.setState(updatedValues, () => {
        this.onSelectReadyMade(this.props.initial);
      });
    }
  }

  // if passed initial values, set them as default
  // i.e initialValues=['2022-10-04','2023-08-12']
  componentDidMount() {
    if (this.props.initialValues) {
      const [start, end] = this.props.initialValues;
      this.setState({
        fromDate: start,
        toDate: end,
        lastType: "custom",
        type: "custom",
        initialized: true,
      });
    }
  }

  onExitFilter = () => {
    this.setState({
      open: false,
      error: null,
    });
  };

  onAddFilter = () => {
    this.props.onDateChanged(
      this.state.fromDate,
      this.state.toDate,
      this.state.type
    );
    this.setState({
      open: false,
      lastType: this.state.type,
    });
  };

  onSelectReadyMade = (type) => {
    const formatDate = "YYYY-MM-DD";
    const today = moment().format(formatDate);
    const fromDateMap = {
      custom: moment(this.state.fromDate).format(formatDate),
      yesterday: moment().add(-1, "day").format(formatDate),
      today: today,
      "last-month": moment()
        .subtract(1, "month")
        .startOf("month")
        .format(formatDate),
      "this-month": moment().startOf("month").format(formatDate),
      "all-time": moment(this.state.projectCreatedDate).format(formatDate),
    };
    const toDateMap = {
      custom: moment(this.state.toDate).format(formatDate),
      yesterday: today,
      today: moment().add(1, "day").format(formatDate),
      "last-month": moment()
        .subtract(1, "month")
        .endOf("month")
        .format(formatDate),
      "this-month": moment().endOf("month").format(formatDate),
      "all-time": today,
    };

    const fromDate = fromDateMap[type] || null;
    const toDate = toDateMap[type] || null;

    this.setState({ type, fromDate, toDate });
  };

  onSelectOneDate = (key, value) => {
    this.setState({
      [key]: moment(value).format("YYYY-MM-DD"),
      type: "custom",
    });
  };

  toggleOpen = () => {
    this.setState({ open: !this.state.open }, () => {
      if (this.state.open) {
        this.dateFilterPicker.classList.add(classes.noAnimation);
      } else {
        this.dateFilterPicker.classList.remove(classes.noAnimation);
      }
    });
  };

  renderCustomDate = (fromDate, toDate) => {
    const currentLocale = locale();

    const arabicLocale = currentLocale === "ar";

    if (arabicLocale) {
      return `${arabicDateFormat(fromDate)} : ${arabicDateFormat(toDate)}`;
    } else {
      return `${moment(fromDate).format("YYYY-MM-DD")} : ${moment(
        toDate
      ).format("YYYY-MM-DD")}`;
    }
  };

  render() {
    const { isPending, disabled, yesterday } = this.props;

    const { fromDate, toDate, type, open } = this.state;

    const disabledLogic =
      !fromDate ||
      !toDate ||
      new Date(normalizeDate(fromDate)).getTime() >
        new Date(normalizeDate(toDate)).getTime();

    const currentLocale = locale();

    return (
      <div
        className={`${classes.dateFilter} ${
          currentLocale === "ar" && classes.rtl
        }`}
      >
        {isPending ? (
          <div className={classes["skeleton-date-filter"]} />
        ) : (
          <div id="date-filter">
            <button
              className={classes.dateFilterButton}
              onClick={this.toggleOpen}
              disabled={disabled}
            >
              <i className="fa fa-calendar-o" aria-hidden="true" />
              {type === "custom" && this.renderCustomDate(fromDate, toDate)}
              {type !== "custom" &&
                I18n.t(type.replace("-", " ").toLowerCase())}

              <i className="fa fa-caret-down" aria-hidden="true" />
            </button>

            <div
              ref={(node) => (this.dateFilterPicker = node)}
              className={`${open ? classes.menuOpen : classes.menuClosed} ${
                classes.dateFilterMenu
              } ${currentLocale === "ar" && classes.rtl} ${
                classes.noAnimation
              }`}
            >
              <p className={classes.title}>{I18n.t("dataFilter_date")}</p>
              <div className={classes.dayPickersContainer}>
                <div>
                  <DatePicker
                    value={moment(fromDate)}
                    onChange={(date) => this.onSelectOneDate("fromDate", date)}
                    allowClear={false}
                    suffixIcon={null}
                  />
                </div>
                <div>
                  <DatePicker
                    value={moment(toDate)}
                    onChange={(date) => this.onSelectOneDate("toDate", date)}
                    allowClear={false}
                  />
                </div>
              </div>
              {type === "custom" &&
                fromDate &&
                toDate &&
                new Date(normalizeDate(fromDate)).getTime() >
                  new Date(normalizeDate(toDate)).getTime() && (
                  <span className="text-danger">
                    <strong>{I18n.t("dataFilter_ToDate")}</strong>
                    {I18n.t("dataFilter_dateNote")}
                    <strong>{I18n.t("dataFilter_FromDate")}</strong>
                  </span>
                )}

              <div className={classes.dateButtonsContainer}>
                {yesterday ? (
                  <button
                    onClick={() => this.onSelectReadyMade("yesterday")}
                    className={`${type === "yesterday" && classes.activeBtn} ${
                      classes.dateBtn
                    }`}
                  >
                    {I18n.t("dataFilter_Yesterday")}
                  </button>
                ) : (
                  <button
                    onClick={() => this.onSelectReadyMade("today")}
                    className={`${type === "today" && classes.activeBtn} ${
                      classes.dateBtn
                    }`}
                  >
                    {I18n.t("dataFilter_Today")}
                  </button>
                )}
                <button
                  onClick={() => this.onSelectReadyMade("this-month")}
                  className={`${type === "this-month" && classes.activeBtn} ${
                    classes.dateBtn
                  }`}
                >
                  {I18n.t("dataFilter_ThisMonth")}
                </button>
                <button
                  onClick={() => this.onSelectReadyMade("last-month")}
                  className={`${type === "last-month" && classes.activeBtn} ${
                    classes.dateBtn
                  }`}
                >
                  {I18n.t("dataFilter_LastMonth")}
                </button>
                <button
                  onClick={() => this.onSelectReadyMade("all-time")}
                  className={`${type === "all-time" && classes.activeBtn} ${
                    classes.dateBtn
                  }`}
                >
                  {I18n.t("dataFilter_AllTime")}
                </button>
              </div>

              <div className={classes.actionsButtonsContainer}>
                <button
                  onClick={this.onExitFilter}
                  className={classes.cancelBtn}
                >
                  {I18n.t("dataFilter_CancelBtn")}
                </button>

                <button
                  disabled={disabledLogic}
                  onClick={this.onAddFilter}
                  className={classes.applyDateBtn}
                >
                  {I18n.t("dataFilter_ApplyBtn")}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default DateFilter;

DateFilter.propTypes = {
  onDateChanged: PropTypes.func.isRequired,
  isPending: PropTypes.bool,
  disabled: PropTypes.bool,
  yesterday: PropTypes.bool,
  subdomain: PropTypes.string,
  initial: PropTypes.string,
  values: PropTypes.object,
  initialValues: PropTypes.array,
};
