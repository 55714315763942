import EP from "consts/api";
import api from "modules/api";

export default class UploadCsvAppUsers {
  static uploadAppUsersCsv = (
    suitId,
    apiKey,
    subdomain,
    email,
    serv,
    appid,
    master,
    data,
    query = "upload_files=true"
  ) => {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key], data.file);
    });
    return api(
      "post",
      EP.APPUSER.UploadCsv(suitId, apiKey, subdomain, email, serv),
      formData,
      query
    )
      .set("APP_ID", `${appid}`)
      .set("APP_MASTER_KEY", `${master}`);
  };
}
