import UploadCsvAppUsers from "api/UploadCsvAppUsers";
import { RESET_ERRORS } from "reducers/user";

export const UPLOAD_CSV_APP_USERS = "UPLOAD_CSV_APP_USERS";

export function uploadCsvAppUsers(
  suitId,
  apiKey,
  subdomain,
  email,
  serv,
  appid,
  master,
  data
) {
  return {
    type: UPLOAD_CSV_APP_USERS,
    payload: UploadCsvAppUsers.uploadAppUsersCsv(
      suitId,
      apiKey,
      subdomain,
      email,
      serv,
      appid,
      master,
      data
    ),
  };
}

const initialState = {
  result: null,
};

// Reducer
export default function ReportsReducer(state = initialState, action) {
  switch (action.type) {
    case RESET_ERRORS:
      return {
        ...state,
        error: false,
      };
    case `${UPLOAD_CSV_APP_USERS}_PENDING`:
      return {
        ...state,
        isPending: true,
        error: false,
      };
    case `${UPLOAD_CSV_APP_USERS}_REJECTED`:
    case `${UPLOAD_CSV_APP_USERS}_FULFILLED`:
      return {
        ...state,
        isPending: false,
        // result: action.payload.body.result,
      };
    default:
      return state;
  }
}
