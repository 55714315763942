import EP from "../consts/api";
import api from "../modules/api";

export default class automessages {
  static getSuitAutomator = (suitId, page) =>
    api("get", EP.AUTOMATOR.AUTOMESSAGES(suitId, page));

  static getSuitAutomatorTrue = (suitId) =>
    api("get", EP.AUTOMATOR.AUTOMESSAGESTRUE(suitId));

  static deleteAutomator = (suitId, msgId) =>
    api("delete", EP.AUTOMATOR.AUTOMESSAGE(suitId, msgId));

  static deleteAutomatorBulk = (suitId, automessageIds) =>
    api("delete", EP.AUTOMATOR.BULK_DELETE(suitId), automessageIds);

  static getCurrentAutomator = (suitId, msgId) =>
    api("get", EP.AUTOMATOR.AUTOMESSAGE(suitId, msgId));

  static createAutomator = (suitId, data, apiKey) =>
    api("post", EP.AUTOMATOR.AUTOMESSAGES(suitId), data).set(
      "appApiKey",
      apiKey
    );

  static editAutomator = (suitId, msgId, data) =>
    api("put", EP.AUTOMATOR.AUTOMESSAGE(suitId, msgId), data);
  static getAutomatorAnalytics = (suitId, msgId) =>
    api("get", EP.AUTOMATOR.ANALYTICS(suitId, msgId));

  static syncPrebuiltAutomators = (suitId, automations) =>
    api("post", EP.AUTOMATOR.PREBUILT_AUTOMATORS(suitId), automations);
}
