import {
  HEADER_STYLE_BREADCRUMBS,
  HEADER_STYLE_SIMPLE,
} from "layouts/DefaultLayout/modules/layout";
import { findActiveNodes } from "modules/router";
import PropTypes from "prop-types";
import React from "react";
import { Col, Grid, Row } from "react-bootstrap";
import { connect } from "react-redux";
import classes from "./Header.scss";

const headers = {
  [HEADER_STYLE_SIMPLE]: (title, path, containerFluid, children) => (
    <div className="sub-navbar sub-navbar__header">
      <Grid fluid={containerFluid}>
        <Row>
          <Col lg={12}>
            <div className={classes.simpleHeaderWrap}>
              <Row>
                <Col xs={children ? 6 : 12}>
                  <div className={classes.headerPart}>
                    <h1>{title}</h1>
                  </div>
                </Col>
                {children && (
                  <Col xs={6}>
                    <div className={classes.headerPart}>{children}</div>
                  </Col>
                )}
              </Row>
            </div>
          </Col>
        </Row>
      </Grid>
    </div>
  ),
  [HEADER_STYLE_BREADCRUMBS]: () => (
    <div className="sub-navbar sub-navbar__header-breadcrumbs"></div>
  ),
};

const Header = (props) => {
  const path = findActiveNodes(props.sidebar.items, props.currentUrl);
  const { title } = path && path.length > 0 ? path[0] : "";

  return headers[props.style](title, path, props.fluid, props.children);
};

Header.propTypes = {
  currentUrl: PropTypes.string.isRequired,
  style: PropTypes.string.isRequired,
  fluid: PropTypes.bool.isRequired,
  children: PropTypes.node,
};

Header.defaultProps = {
  children: null,
};

export default connect(
  (store) => ({
    sidebar: store.sidebar,
  }),
  {}
)(Header);
