import { Icon } from "antd";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import { Toast } from "../../../modules/toast";

class Copyicon extends Component {
  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  render() {
    const { value } = this.props;
    return (
      <CopyToClipboard text={value}>
        <a
          onClick={() => {
            Toast.success(I18n.t("Copied"));
          }}
        >
          {this.props.isRed ? (
            <i
              style={{ color: "#f22e52" }}
              className="fa fa-clone copyButton"
            />
          ) : (
            <Icon type="copy" theme="twoTone" twoToneColor="#630bde" />
          )}
        </a>
      </CopyToClipboard>
    );
  }
}
export default connect()(Copyicon);
