import arabic from "@emoji-mart/data/i18n/ar.json";
import { OutsideClick } from "components";
import { Picker } from "emoji-mart";
import "emoji-mart/css/emoji-mart.css";
import { locale } from "modules/i18n";
import React from "react";
import classes from "./EmojiPicker.scss";

class EmojiPicker extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      selected: "😍",
    };
    this.handleEmojiChange = this.handleEmojiChange.bind(this);
    this.toggleOpen = this.toggleOpen.bind(this);
    this.close = this.close.bind(this);
  }

  toggleOpen() {
    this.setState((prevState) => ({ open: !prevState.open }));
  }

  close() {
    this.setState({ open: false });
  }

  handleEmojiChange({ native }) {
    this.setState({ selected: native });
    this.props.handleChange(native);
  }

  render() {
    const { open, selected } = this.state;
    const { styledown } = this.props;

    const currentLocale = locale();

    return (
      <OutsideClick onClickOutside={this.close}>
        <div
          className={styledown === true ? classes.containdown : classes.contain}
        >
          <span className={classes.button} onClick={this.toggleOpen}>
            {selected}
          </span>
          {open && (
            <div className={classes.popOver}>
              <Picker
                native
                showPreview={false}
                onSelect={this.handleEmojiChange}
                i18n={currentLocale === "ar" && arabic}
              />
            </div>
          )}
        </div>
      </OutsideClick>
    );
  }
}
export default EmojiPicker;
