const validations = {
  regex: {
    email: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,

    isAlphaNumaricSpacesLong: /^([a-zA-Z0-9 ]{5,40})$/i,

    isAlphaNumaricSpacesShort: /^([a-zA-Z0-9 ]{5,25})$/i,

    containSpecialChars: /[`~,.<>;':"/[\]|{}()=_#!%&$^*]/,

    forbiddenNames:
      /^(?!app$)(?!prod$)(?!stg$)(?!www$)(?!blog$)(?!notify$)(?!parse$)(?!appbackend$)(?!appboost$)(?!api$)(?!demo$)(?!marketing$)(?!sales$)(?!servers$)(?!fw$)(?!do$)(?!fw$)(?!srv$)(?!kong$)(?!dashboard$)(?!reports$)(?!appboost$)(?!hack$)[a-z0-9]+$/,

    WithSpaces: /^[a-zA-Z0-9\u0621-\u064A\u0660-\u0669 _-]+$/i,

    Limiation5To500: /^[\s\S]{5,500}$/,

    validUrl: /^[\W\w]+:[\W\w]*$/,

    validUrl2:
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,

    validcustomDomain: /^(?=.*?[a-zA-Z])[^.]+$/,

    isImage: /\.(jpg|png)$/i,

    isGooglePlayUrl:
      /^(market:\/\/|https?:\/\/play\.google\.com\/store\/apps\/)details\?id=([\w\d.=&]+)$/i,

    isAppleStoreUrl:
      /^https?:\/\/itunes\.apple\.com\/ae\/app\/[a-z0-9.-_/]{10,30}$/i,

    isAndroidDeeplink: /^(?=[\S\s]{4,30})[\S\s]*$/,

    isAppleDeeplinkUrl: /^[a-z]+:\/\/$/i,

    isAlphaSpaces: /^([a-zA-Z ]{5,40})$/i,

    isEmail:
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/i,

    isNumber: /^[1-9]\d*$/,
    isNumberwithzero: /^[0-9]\d*$/,
    isDecimal: /^\d*(\.\d+)?$/,

    isImgUrl:
      /^(https?:\/\/)([\da-z.-]+)\.([a-z.]{2,6})([/\w\d\u060C-\u0669?=%&.-]*)*\/?\.(?:jpg|png)$/i,

    notContainSpeciealChars: /[`~,.<>;':"/[\]|{}()=_#!%&$^*]/,

    hasWhiteSpaces: /\s/,

    trimm: /^\s+|\s+$/,

    trimmspace: /^[ \t]+/,
    noWhiteSpace: /^\S/,

    noHttp: /(ftp|http|https)/,

    socialmediaValidation:
      /^[\w\u060C-\u0669 –.,;:_~!@#$%^*><+=&/\-/{}()[\]\n\t]+$/,

    validationDeeplinkEmail:
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,

    mobileNumberValidation: /^(?:00)[0-9]{8,15}(?:,(?:00)[0-9]{8,15})*$/,
    mobValidate: /^[0-9+-.]{10,13}/,

    slugvalid: /[/[\]|]/,
    noDash: /^[1-9]\d*[^-]$/,
    specialCharacters: /\W|_/g,
    alphanumeric: /^\w+$/,
    englishLetters: /^[a-zA-Z]+$/,
  },
};

export default validations;
