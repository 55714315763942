import React from "react";
import { I18n } from "react-redux-i18n";
import IOSBg from "static/iphone-lock-screen.png";
import classes from "../DevicePreview.scss";
const LandingPreview = ({ data, type }) => {
  let css = [];
  css.push(classes.AppPushPreviewContain);
  if (type === "sms") css.push(classes.smsCampaign);
  return (
    <div className={css.join(" ")}>
      <img src={IOSBg} className={classes.previewBg} />
      <span className={classes.previewArea}>
        {data || I18n.t("AppPushPreview")}
      </span>
    </div>
  );
};

export default LandingPreview;
