import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { Button as BootstrapButton } from "react-bootstrap";
import _ from "underscore";

import classes from "./Button.scss";

const BS_STYLES = [
  "default",
  "link",
  "primary",
  "success",
  "warning",
  "danger",
  "info",
];

class Button extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    outline: PropTypes.bool,
    bsStyle: PropTypes.string,
    style: PropTypes.string,
    customColor: PropTypes.string,
  };

  static defaultProps = {
    outline: false,
    bsStyle: "default",
  };

  render() {
    const {
      className,
      children,
      bsStyle,
      style,
      outline,
      customColor,
      ...otherProps
    } = this.props;

    const isBsStyle = _.contains(BS_STYLES, bsStyle);

    const buttonClass = classNames(
      {
        [`${classes.outline}`]: outline && !isBsStyle,
        "btn-outline": outline && isBsStyle,
      },
      className,
      classes.buttonStructure
    );

    const additionalStyle = {
      color: outline ? customColor : "#fff",
      backgroundColor: customColor,
      borderColor: customColor,
    };

    const newStyles = !isBsStyle ? additionalStyle : null;
    return (
      <BootstrapButton
        {...otherProps}
        bsStyle={isBsStyle ? bsStyle : null}
        className={buttonClass}
        style={{ ...newStyles, ...style }}
      >
        {children}
      </BootstrapButton>
    );
  }
}

export default Button;
