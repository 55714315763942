import AppPush from "api/AppPush";
import { RESET_ERRORS } from "reducers/user";

const SEND_APP_PUSH_FULLFILLED = "SEND_APP_PUSH_FULLFILLED";
const SEND_APP_PUSH_REJECTED = "SEND_APP_PUSH_REJECTED";
const SEND_APP_PUSH = "SEND_APP_PUSH";
const SEND_APP_PUSH_PENDING = "SEND_APP_PUSH_PENDING";
const APP_PUSH_CLEAR_PENDING = "APP_PUSH_CLEAR_PENDING";

export function clearPending() {
  return {
    type: APP_PUSH_CLEAR_PENDING,
  };
}

export function sendPush(serverUrl, apiKey, data) {
  return {
    type: SEND_APP_PUSH,
    payload: AppPush.sendAppPush(serverUrl, apiKey, data),
  };
}

const initialState = {
  isPending: false,
  error: false,
  validation: true,
};

export default function MobileAttributeReducer(state = initialState, action) {
  switch (action.type) {
    case APP_PUSH_CLEAR_PENDING:
      return {
        ...state,
        isPending: false,
      };

    case RESET_ERRORS:
      return {
        ...state,
        error: false,
      };

    case SEND_APP_PUSH_PENDING:
      return {
        ...state,
        isPending: true,
        error: false,
      };

    case SEND_APP_PUSH_FULLFILLED:
      return {
        ...state,
        isPending: false,
        error: false,
      };
    case SEND_APP_PUSH_REJECTED:
      return {
        ...state,
        isPending: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
