import EP, { config } from "consts/api";
import _ from "lodash";
import api from "modules/api";
import { userData } from "modules/user";

export default class User {
  static loginUser = ({ email, password, recaptcha }) =>
    api("post", EP.USER.LOGIN(), { recaptcha }, false, false).auth(
      email,
      password
    );
  static generateAPIKey = (data, appId) =>
    api("put", EP.USER.GENERATE(appId), data);

  static registerUser = (data) => api("post", EP.USER.REGISTER(), data);

  static forgotPassword = (data) =>
    api("post", EP.USER.FORGOT_PASSWORD(), data);

  static resetPassword = (data, token) =>
    api("post", EP.USER.RESETPASSWORD(token), data);
  static resendConfirmation = (email, recaptcha) =>
    api("post", EP.USER.RESEND_CONFIRMATION(email), { recaptcha });

  static getUserSuits = () => {
    const user = userData();

    if (user !== null) {
      return api("get", EP.USER.APPS()).set("authToken", user.auth_token);
    }
  };

  static getUserProfile = () => api("get", EP.USER.USER_LINK());

  static updateUserProfile = (data, query = "") => {
    let changeEmailLock = false;
    if (data.queryString) {
      return api("put", EP.USER.USER_LINK(changeEmailLock), { ...data }, query);
    }
    if (data.email !== data.newemail && data.newemail !== undefined) {
      changeEmailLock = true;
      return api(
        "put",
        EP.USER.USER_LINK(changeEmailLock),
        { ...data, email: data.email, newemail: data.newemail },
        query
      );
    } else {
      return api(
        "put",
        EP.USER.USER_LINK(changeEmailLock),
        { ...data, email: data.email },
        query
      );
    }
  };

  static getCurrentSuit = (suitId) => api("get", EP.USER.APP(suitId));
  static createSuit = (data) => api("post", EP.USER.APPS(), data);

  static updateSuit = (suitId, data) => api("put", EP.USER.APP(suitId), data);

  static deleteSuit = (suitId) => api("delete", EP.USER.APP(suitId));

  static getInvoices = (customer) => {
    return api("get", EP.STRIPE.INVOICES())
      .query({ customer, limit: 100 })
      .set("Authorization", config.STRIPE_INVOICES_KEY);
  };
  static submitStripeToken = (stripeKey, userId) =>
    api("post", EP.STRIPE.TOKEN(userId), { stripeKey }, false, false);

  static deletUser = (data, auth) => {
    return api("delete", EP.USER.DELETEUSER(), data, false, false).set(
      "Authorization",
      auth
    );
  };

  static addon = (suitId, apiKey, data) => {
    api("post", EP.USER.addON(suitId), data)
      .set("appApiKey", apiKey)
      .set("Content-Type", "application/json");
  };

  static MediaLibrary = (suitId, apiKey) => {
    return api("get", EP.USER.MediaLibrary(suitId)).set("appApiKey", apiKey);
  };

  static FillDemoData = (suitId) => {
    return api("post", `https://api.shrinkit.me/create_dummy_data/${suitId}`);
  };

  static ClearDemoData = (suitId) => {
    return api("delete", `https://api.shrinkit.me/delete_dummy_data/${suitId}`);
  };

  static DeleteMedia = (suitId, apiKey) => {
    return api("delete", EP.USER.DeleteMedia(suitId)).set("appApiKey", apiKey);
  };

  static checkSubscribed = (serverURL, appId, suitId, masterKey, apiKey) => {
    return api("POST", EP.USER.checkSubscribed(serverURL, suitId, apiKey))
      .set("x-parse-application-id", appId)
      .set("x-parse-master-key", masterKey)
      .set("Content-Type", "application/json")
      .set("Accept", "application/json");
  };

  static getAppCurrency = (serverURL, appId, suitId, masterKey, apiKey) => {
    return api("POST", EP.USER.getAppCurrency(serverURL, suitId, apiKey))
      .set("x-parse-application-id", appId)
      .set("x-parse-master-key", masterKey)
      .set("Content-Type", "application/json")
      .set("Accept", "application/json");
  };
  static upgradePlan = (
    suit_id,
    plan_id,
    infra_type,
    coupon,
    subscription_type
  ) => {
    return api(
      "PUT",
      EP.USER.upgradePlan(suit_id, plan_id),
      _.pickBy({ infra_type, coupon, subscription_type })
    )
      .set("Content-Type", "application/json")
      .set("Accept", "application/json");
  };

  static addSubUser = (data) =>
    api("POST", EP.USER.SubUser(), data)
      .set("Content-Type", "application/json")
      .set("Accept", "application/json");

  static getSubUsers = () =>
    api("GET", EP.USER.SubUser())
      .set("Content-Type", "application/json")
      .set("Accept", "application/json");

  static editSubUser = (data) =>
    api("PUT", EP.USER.SubUser(), data)
      .set("Content-Type", "application/json")
      .set("Accept", "application/json");

  static deleteSubUser = (data) =>
    api("DELETE", EP.USER.SubUser(), data)
      .set("Content-Type", "application/json")
      .set("Accept", "application/json");
}
