import $ from "jquery";
import { locale } from "modules/i18n";
import React, { Component } from "react";
import ReactDOM from "react-dom";
import classes from "./ActionMenu.scss";

class ActionMenu extends Component {
  componentWillMount() {
    document.addEventListener("click", this.handleClick, false);
  }

  componentDidMount() {
    $(ReactDOM.findDOMNode(this)).hide().slideDown(500);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClick, false);
  }

  handleClick = (e) => {
    if (!ReactDOM.findDOMNode(this).contains(e.target)) {
      $(ReactDOM.findDOMNode(this)).slideUp(500, () => {
        this.props.clearActionMenu();
      });
      return;
    }
  };

  render() {
    const currentLocale = locale();

    return (
      <div
        ref={(component) => (this.actionsMenu = component)}
        className={`action-menu-data-table ${classes["div-filter-category"]} ${
          this.props.largeActionMenu ? classes["div-filter-category-large"] : ""
        } ${currentLocale === "ar" ? classes.rtl : null}`}
      >
        {this.props.actions &&
          this.props.actions.map((action, index) => (
            <p
              style={{ textTransform: "capitalize" }}
              key={index}
              className={`${
                action.name.toLowerCase() === "delete"
                  ? classes["hover-danger"]
                  : ""
              }`}
              onClick={() => {
                this.props.onActionClick(action.name);
                this.props.clearActionMenu();
              }}
            >
              {action.name}
            </p>
          ))}
      </div>
    );
  }
}

export default ActionMenu;
