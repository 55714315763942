import React from "react";
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa6";
import { IoLogoTiktok } from "react-icons/io5";
import { I18n } from "react-redux-i18n";
import footerLogo from "../../static/logos/shrink-it-footer-logo.png";

const SOCIAL_ICONS = [
  {
    name: "facebook",
    icon: FaFacebook,
    link: "https://www.facebook.com/profile.php?id=100077163408077",
    color: "#fff",
  },
  {
    name: "tiktok",
    icon: IoLogoTiktok,
    link: "https://www.tiktok.com/@shrinkit.me",
    color: "#fff",
  },
  {
    name: "instagram",
    icon: FaInstagram,
    link: "https://www.instagram.com/shrinkit.me",
    color: "#fff",
  },
];

const Footer = () => {
  return (
    <footer style={{ backgroundColor: "#630BDE" }}>
      <section
        style={{
          backgroundColor: "#630BDE",
          textAlign: "center",
          padding: "30px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <img src={footerLogo} style={{ width: "7.5%", marginBottom: "15px" }} />
        <p style={{ color: "white" }}></p>
        <article
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "16px",
          }}
        >
          {SOCIAL_ICONS.map((icon) => (
            <a
              key={icon.name}
              href={icon.link}
              rel="noreferrer noopener"
              target="_blank"
              style={{ textDecoration: "none", display: "inline-block" }}
            >
              <icon.icon
                style={{
                  width: "30px",
                  height: "30px",
                  color: icon.color,
                }}
              />
            </a>
          ))}
        </article>
      </section>
      <section
        style={{
          padding: "6px",
          background: "#8A48E6",
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
          color: "white",
          width: "100%",
        }}
      >
        <p>{I18n.t("MadeBy")}</p>
        <p>{I18n.t("rights")}</p>
      </section>
    </footer>
  );
};

export default Footer;
