import ShopifySubscripe from "api/ShopifySubscripe";
export const GET_REDIRECTION_LINK = "GET_REDIRECTION_LINK";

export const shopifySubscripe = (shopifyName, planId) => {
  try {
    return {
      type: GET_REDIRECTION_LINK,
      payload: ShopifySubscripe.shopifySubscripe(shopifyName, planId),
    };
  } catch (err) {
    console.error(err);
  }
};

const initialState = {
  isPending: false,
  redirectionLink: null,
};

export default function shopifySubscripeReducer(state = initialState, action) {
  switch (action.type) {
    case GET_REDIRECTION_LINK:
      return {
        ...state,
        isPending: false,
        redirectionLink: action.payload,
      };
    default:
      return state;
  }
}
