import ENDPOINTS from "consts/api";
import api from "modules/api";

export default class Config {
  static getNotifyConfig = (appId) =>
    api("get", ENDPOINTS.NOTIFY.CONFIG(appId));

  static setNotifyConfig = (appId, data) =>
    api("put", ENDPOINTS.NOTIFY.CONFIG(appId), data);

  // static getNotifyLogs = (appId, type = 'all') => api('get', ENDPOINTS.NOTIFY.CONFIG(appId, type))

  static sendPush = (data) => api("post", ENDPOINTS.SEND_LATER.NOTIFY(), data);

  static deleteUser = (data) =>
    api("post", ENDPOINTS.SEND_LATER.DELETE_USER(), data, false, false);
}
