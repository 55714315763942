import React from "react";
import classnames from "classnames";
import classes from "./Loader.scss";
const Loader = (big) => (
  <div className={classnames([classes.container, { [classes.big]: big }])}>
    <i className="fa fa-spinner fa-spin" />
  </div>
);

export default Loader;
