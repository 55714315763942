import { Checkbox, Dropdown, Icon, Menu } from "antd";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { I18n } from "react-redux-i18n";
import PrimaryButton from "./Form/Buttons/PrimaryButton/PrimaryButton";

/**
 * RenderTableColumnsVisibility component.
 *
 * @component
 * @extends {Component}
 *
 * @property {Object} props - Component props.
 * @property {Object} props.columnsVisible - An object representing the visibility of each column.
 * @property {Array.<Object>} props.columns - An array of objects representing the columns.
 * @property {Function} props.updateColumnsVisible - A function to update the visibility of columns.
 * @property {string} props.id - The id of the component.
 *
 * @property {Object} state - Component state.
 * @property {boolean} state.showDropdownOverlay - A boolean representing whether the dropdown overlay is visible or not.
 *
 * @returns {React.Element} Rendered RenderTableColumnsVisibility component.
 */
class RenderTableColumnsVisibility extends Component {
  state = {
    showDropdownOverlay: false,
  };

  handleClick = (e) => {
    const { columnsVisible, updateColumnsVisible } = this.props;

    const { key } = e;
    const newColumnsVisible = {
      ...columnsVisible,
      [key]: !columnsVisible[key],
    };
    updateColumnsVisible(newColumnsVisible);
    this.setState({ showDropdownOverlay: true });
  };

  render() {
    const { columnsVisible, columns, id } = this.props;
    const { showDropdownOverlay } = this.state;

    const menu = (
      <Menu onClick={this.handleClick}>
        {Object.keys(columnsVisible)
          .filter((key) => key !== "actions")
          .map((key) => (
            <Menu.Item key={key}>
              <span>
                <Checkbox
                  checked={columnsVisible[key]}
                  style={{ marginLeft: 8 }}
                  onChange={() => {}}
                >
                  {columns.find((c) => c.key === key)?.title ?? key}
                </Checkbox>
              </span>
            </Menu.Item>
          ))}
      </Menu>
    );

    return (
      <Dropdown
        trigger={["click"]}
        overlay={menu}
        visible={showDropdownOverlay}
        onVisibleChange={(v) => this.setState({ showDropdownOverlay: v })}
      >
        <PrimaryButton icon={<Icon type="setting" />} id={id}>
          {I18n.t("tableSettings")}
        </PrimaryButton>
      </Dropdown>
    );
  }
}

export default RenderTableColumnsVisibility;

RenderTableColumnsVisibility.propTypes = {
  columnsVisible: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired,
  updateColumnsVisible: PropTypes.func.isRequired,
  id: PropTypes.string,
};
