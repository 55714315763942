import { Checkbox } from "antd";
import PropTypes from "prop-types";
import React from "react";
import classes from "./CustomCheckboxReduxForm.module.scss";

const CustomCheckboxReduxForm = ({
  input,
  label,
  disabled,
  meta: { touched, error },
  style,
}) => {
  const { value, ...restInput } = input;
  return (
    <div className={classes.checkbox} style={style}>
      <Checkbox checked={Boolean(value)} {...restInput} disabled={disabled}>
        <span className={classes.label}>{label}</span>
      </Checkbox>
      {touched && error && <span className={classes.error}>{error}</span>}
    </div>
  );
};

export default CustomCheckboxReduxForm;

CustomCheckboxReduxForm.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  meta: PropTypes.object,
  style: PropTypes.object,
};
