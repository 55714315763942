import { Icon, Tooltip } from "antd";
import { SpinLoader } from "components";
import PropTypes from "prop-types";
import React from "react";
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";
import { I18n } from "react-redux-i18n";
import { getCurrentSuit, updateSuit } from "reducers/user";
import { connect } from "routes/routedComponent";
import { Toast } from "../../../modules/toast";
import classes from "./OnBoardingModal.scss";

const fields = [
  {
    id: "1",
    label: "onBoarding_integrations_facebook_title",
    description: "onBoarding_integrations_facebook_desc",
    propertyName: "FBpixel_tracking_code",
    placeholder: "onBoarding_integrations_facebook_placeholder",
  },
  {
    id: "2",
    label: "onBoarding_integrations_TikTok_title",
    description: "onBoarding_integrations_TikTok_desc",
    propertyName: "tiktok_pixel_id",
    placeholder: "onBoarding_integrations_TikTok_placeholder",
  },
  {
    id: "3",
    label: "onBoarding_integrations_Twitter_title",
    description: "onBoarding_integrations_Twitter_desc",
    propertyName: "twitter_pixel_id",
    placeholder: "onBoarding_integrations_Twitter_placeholder",
  },
  {
    id: "4",
    label: "onBoarding_integrations_GoogleAds_title",
    description: "onBoarding_integrations_GoogleAds_desc",
    propertyName: "google_ads_id",
    placeholder: "onBoarding_integrations_GoogleAds_placeholder",
  },
  {
    id: "5",
    label: "onBoarding_integrations_Snapchat_title",
    description: "onBoarding_integrations_Snapchat_desc",
    propertyName: "snapchat_id",
    placeholder: "onBoarding_integrations_Snapchat_placeholder",
  },
  {
    id: "6",
    label: "onBoarding_integrations_LinkedIn_title",
    description: "onBoarding_integrations_LinkedIn_desc",
    propertyName: "linkedIn_id",
    placeholder: "onBoarding_integrations_LinkedIn_placeholder",
  },
];

const RenderInputRow = ({
  label,
  propertyName,
  description,
  formData,
  placeholder,
  changeFormData,
  isArabic,
}) => {
  const translatedLabel = I18n.t(label);
  const translatedDescription = I18n.t(description);
  const translatedPlaceholder = I18n.t(placeholder);

  return (
    <div
      className={classes.inputRowContainer}
      style={{ direction: isArabic ? "rtl" : "ltr" }}
    >
      <div className={classes.labelContainer}>
        <label htmlFor={propertyName}>{translatedLabel}</label>
        <Tooltip title={translatedDescription} placement="right">
          <Icon
            type="info-circle"
            theme="twoTone"
            twoToneColor="#630BDE80"
            style={{ fontSize: "16px", marginLeft: "0.3em" }}
          />
        </Tooltip>
      </div>
      <input
        id={propertyName}
        className={classes.input}
        placeholder={translatedPlaceholder}
        name={propertyName}
        value={formData[propertyName]}
        onChange={(e) =>
          changeFormData({ ...formData, [e.target.name]: e.target.value })
        }
        type="text"
      />
    </div>
  );
};

class Retargeting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showIntegrationsModal: false,
      formData: {},
      editFieldsLoading: false,
    };
  }

  componentDidMount() {
    // initialize form data from current suit
    const { currentSuit } = this.props;

    const formData = {};

    fields.forEach((field) => {
      formData[field.propertyName] = currentSuit?.[field.propertyName] || "";
    });

    this.setState({ formData });
  }

  changeFormData = (formData) => {
    this.setState({ formData });
  };

  handleSaveButtonClick = async () => {
    const {
      user: { activeSuitId },
      updateSuit,
      getCurrentSuit,
      currentSuit,
      handleOnboardingSteps,
    } = this.props;

    const { formData } = this.state;

    let finalCurrentSuit = currentSuit;
    if (!finalCurrentSuit) {
      finalCurrentSuit = await getCurrentSuit(activeSuitId);
    }

    // update suit
    try {
      this.setState({ editFieldsLoading: true });

      handleOnboardingSteps("retargeting");
      if (Object.keys(formData).length !== 0) {
        await updateSuit(activeSuitId, {
          ...formData,
          suit_name: finalCurrentSuit.name,
        });
        Toast.success(I18n.t("SettingsPage_integrations_editMsg"));
      }

      // get updated suit
      await getCurrentSuit(activeSuitId);

      this.props.handleSteps();
    } catch (error) {
      console.error(error);
      Toast.error(I18n.t("SettingsPage_integrations_editMsgError"));
    } finally {
      this.setState({ editFieldsLoading: false });
    }
  };

  render() {
    const { formData } = this.state;

    const {
      handleBack,
      user: { isPending },
      isArabic,
    } = this.props;

    return (
      <div className={classes.Retargeting}>
        {isPending && <SpinLoader />}

        {fields.map((field) => (
          <RenderInputRow
            key={field.id}
            {...field}
            formData={formData}
            isArabic={isArabic}
            changeFormData={this.changeFormData}
          />
        ))}
        <div className="buttonsContainer">
          <button
            disabled={isPending}
            onClick={() => {
              handleBack();
            }}
            className="confirm cancel"
          >
            {isArabic ? <BsArrowRightShort /> : <BsArrowLeftShort />}

            {I18n.t("backBtn")}
          </button>

          <button
            disabled={isPending}
            onClick={() => {
              this.handleSaveButtonClick();
            }}
            className="confirm"
          >
            {I18n.t("continueBtn")}
            {isArabic ? <BsArrowLeftShort /> : <BsArrowRightShort />}
          </button>
        </div>
      </div>
    );
  }
}

export default connect(
  (store) => ({
    user: store.user,
  }),
  {
    updateSuit,
    getCurrentSuit,
  }
)(Retargeting);

Retargeting.propTypes = {
  currentSuit: PropTypes.object,
  user: PropTypes.object,
  updateSuit: PropTypes.func,
  getCurrentSuit: PropTypes.func,
  handleOnboardingSteps: PropTypes.func,
  handleSteps: PropTypes.func,
  handleBack: PropTypes.func,
  isArabic: PropTypes.bool,
};
