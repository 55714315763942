export const valueOrEmpty = (condition) => (value) =>
  condition ? [value] : [];

export const productExists = (products) => (name) =>
  products.some((product) => product.name === name);

// export const offerExists = offerss_values =>
//   offerss_values  === offers_values

export const offerExists = function (offers_values) {
  return function () {
    return offers_values;
  };
};
export const addProductIfExists = (products) => (name) =>
  valueOrEmpty(productExists(products)(name));

export const valueOrEmptyModified = function () {
  return function (value) {
    return [value];
  };
};
