import { Colors } from "consts";
import timezoneOptions from "consts/timezoneOptions";
import lodash from "lodash";
import { locale } from "modules/i18n";
import React from "react";

export function isMacintosh() {
  return navigator.platform.indexOf("Mac") > -1;
}

export function statusToColor(status) {
  switch (status) {
    case "Online":
      return Colors.brandSuccess;
    case "Busy":
      return Colors.brandDanger;
    case "Away":
      return Colors.brandWarning;
    default:
    case "Offline":
      return Colors.grayLighter;
  }
}

export const numToString = (num) =>
  num +
  (num % 10 > 3 ? "th" : num % 10 === 3 ? "rd" : num % 10 === 2 ? "nd" : "st");

export const formatNumber = (num) => {
  const parts = num.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
};

export const prepareThreeStatsCardAnalyticsValues = (statistics) => {
  const total = statistics?.totalClickcount || 0;
  const desktop = statistics?.desktopClickcount || 0;
  const android = statistics?.androidClickcount || 0;
  const ios = statistics?.iosClickcount || 0;
  return {
    total,
    desktop,
    android,
    ios,
  };
};

export const prepareOptinOptoutCardAnalyticsValues = (summary) => {
  let total;
  let optIn;
  let optOut;

  if (summary?.web_push_subscribers?.charts?.subscribers) {
    const subscribers = summary.web_push_subscribers.charts.subscribers;
    if (subscribers.every((el) => !el)) {
      total = 0;
      optIn = 0;
      optOut = 0;
    } else {
      optIn = subscribers[0].count;
      optOut = subscribers[1].count;
      total = subscribers.reduce((acc, curr) => {
        if (curr && curr.count) {
          return acc + curr.count;
        } else {
          return acc;
        }
      }, 0);
    }
  }

  return {
    total,
    optIn,
    optOut,
  };
};

function numberWithCommas(x) {
  return x !== undefined || x !== null
    ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    : "";
}

function addCommasToNumber(number) {
  const numberString = String(number);

  let formattedNumber = "";
  let counter = 0;

  for (let i = numberString.length - 1; i >= 0; i--) {
    formattedNumber = numberString[i] + formattedNumber;
    counter++;

    if (counter === 3 && i !== 0) {
      formattedNumber = "," + formattedNumber;
      counter = 0;
    }
  }

  return formattedNumber;
}

function convertToArabicNumber(number) {
  const arabicNumbers = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];

  if (typeof number !== "number") {
    return "Invalid input. Please provide a valid number.";
  }

  const arabicNumberString = String(number).replace(/\d/g, (match) => {
    return arabicNumbers[Number(match)];
  });

  return arabicNumberString;
}

// label, usage, unit, barProgress(%)
export const prepareUsageBlockData = (products) => {
  const currentLocale = locale();
  const isArabic = currentLocale === "ar";

  const data = [];

  const KEYS_TO_SKIP = ["EMAIL", "SMS", "date_range"];

  Object.entries(products).forEach(([key, value]) => {
    if (KEYS_TO_SKIP.includes(key)) return;

    data.push({
      label: value.label,
      usage: isArabic
        ? () => (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "4px",
              }}
            >
              <span>
                {addCommasToNumber(convertToArabicNumber(value.usage))}
              </span>
              /
              <span>
                {addCommasToNumber(convertToArabicNumber(value.limit))}
              </span>
            </div>
          )
        : () => (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "4px",
              }}
            >
              <span>{numberWithCommas(value.usage)}</span>/
              <span>{numberWithCommas(value.limit)}</span>
            </div>
          ),
      unit: value.second_label,
      barProgress: Math.ceil((value.usage / value.limit) * 100),
    });
  });

  return data;
};

export const normalizeDate = (date) => {
  function isArabicDateFormat(dateString) {
    // Regular expression to match Arabic characters
    const arabicRegex =
      /[\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFBC1\uFE70-\uFEFF]/;

    // Check if the date string contains Arabic characters
    return arabicRegex.test(dateString);
  }

  if (isArabicDateFormat(date)) {
    const englishDate = date
      .replace(/٠/g, "0")
      .replace(/١/g, "1")
      .replace(/٢/g, "2")
      .replace(/٣/g, "3")
      .replace(/٤/g, "4")
      .replace(/٥/g, "5")
      .replace(/٦/g, "6")
      .replace(/٧/g, "7")
      .replace(/٨/g, "8")
      .replace(/٩/g, "9");

    return englishDate;
  } else {
    return date;
  }
};

export const convertFullArabicDateToEnglishDate = (dateStr) => {
  // Check if the input date string is empty, null, or not a string
  if (typeof dateStr !== "string" || dateStr.trim() === "") {
    return "";
  }

  // Define the Arabic and English digits
  const arabicDigits = "٠١٢٣٤٥٦٧٨٩";
  const englishDigits = "0123456789";

  // Replace Arabic digits with English digits in the date string
  for (let i = 0; i < arabicDigits.length; i++) {
    const arabicDigit = arabicDigits[i];
    const englishDigit = englishDigits[i];
    dateStr = dateStr.replace(new RegExp(arabicDigit, "g"), englishDigit);
  }

  // Split the date string into its components
  const parts = dateStr.split(/[^\d]+/);

  // Ensure the date string has the necessary components
  if (parts.length !== 6) {
    return "";
  }

  // Rearrange the components in the English date format
  const [year, month, day, hours, minutes] = parts;
  const englishDate = `${year}/${month}/${day},${hours}:${minutes}`;

  return englishDate;
};

export function arabicDateFormat(arabicDate) {
  const date = new Date(normalizeDate(arabicDate));

  // Format the date as desired
  const formattedDate = date.toLocaleDateString("ar-EG", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });

  return formattedDate;
}

export const removeNullAndEmpty = (obj) => {
  for (const key in obj) {
    if (obj[key] === null || obj[key] === "") {
      delete obj[key];
    } else if (typeof obj[key] === "object") {
      if (lodash.isEmpty(obj[key])) {
        delete obj[key];
      }

      removeNullAndEmpty(obj[key]);
    }
  }
};

/**
 * Adds a suffix to a number based on the locale or English rules.
 * @param {number} number - The number to add a suffix to.
 * @returns {string} The number with the appropriate suffix added.
 */
export const addNumberSuffix = (number) => {
  const isArabic = locale() === "ar";

  if (isArabic) {
    const arabicSuffixes = [
      "",
      "الأول",
      "الثاني",
      "الثالث",
      "الرابع",
      "الخامس",
      "السادس",
      "السابع",
      "الثامن",
      "التاسع",
      "العاشر",
    ];
    const lastDigit = number % 10;
    return arabicSuffixes[lastDigit];
  }

  if (number % 10 === 1 && number % 100 !== 11) {
    return number + "st";
  } else if (number % 10 === 2 && number % 100 !== 12) {
    return number + "nd";
  } else if (number % 10 === 3 && number % 100 !== 13) {
    return number + "rd";
  } else {
    return number + "th";
  }
};

/**
 * Converts a file to base64 encoding.
 *
 * @param {File} file - The file to be converted.
 * @return {Promise<string>} A promise that resolves to the base64 encoded string.
 */
export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

/**
 * This function is used to get the default timezone from the suit of the user.

  The function will return the timezone in the format of object as below:
  {
    name: "Asia/Kuala_Lumpur",
    offset: "+08:00"
    value: "something",
    label: "Asia/Kuala_Lumpur (GMT+08:00)"
  }

  The function will return null if the timezone is not found.

  params:
    - suit: object
  return:
    - timezone: object
 */
export const getDefaultTimezoneFromSuit = (suit) => {
  if (!suit) return null;

  const suitDefaultTimezone = suit.defaultTimeZone;
  const suitDefaultTimezoneOption = timezoneOptions.find(
    (timezone) =>
      timezone.offset === suitDefaultTimezone?.offset &&
      timezone.name === suitDefaultTimezone?.timezone
  );

  return suitDefaultTimezoneOption || null;
};

export const stripDashes = (str) => str.replace(/-/g, "");
