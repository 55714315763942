import classNames from "classnames";
import {
  SIDEBAR_STYLE_BIGICONS,
  SIDEBAR_STYLE_DEFAULT,
  SIDEBAR_STYLE_SLIM,
} from "layouts/DefaultLayout/modules/layout";
import { addAppToUrl, findActiveNodes } from "modules/router";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import { Link } from "react-router";
import { getCurrentSuit } from "reducers/user";
import _ from "underscore";
import { v4 as uuidv4 } from "uuid";
import Velocity from "velocity-animate";
import classes from "./../../Sidebar.scss";

let animationInProgress = false;

const findSubmenu = (parentNodeElement) =>
  _.find(
    parentNodeElement?.children,
    (childElement) => childElement.tagName === "UL"
  );

// Animate open and close
const animateOpenNode = (
  nodeElement,
  cbComplete,
  cbStart,
  animationSettings
) => {
  const subMenuElement = findSubmenu(nodeElement);
  animationInProgress = true;

  subMenuElement.style.display = "block";

  Velocity(
    subMenuElement,
    {
      height: [subMenuElement.scrollHeight, 0],
    },
    {
      ...animationSettings,
      complete: () => {
        subMenuElement.style.height = null;
        animationInProgress = false;
        cbComplete();
      },
    }
  );

  cbStart({
    heightDiff: subMenuElement.scrollHeight,
  });
};

const animateCloseNode = (
  nodeElement,
  cbComplete,
  cbStart,
  animationSettings
) => {
  const subMenuElement = findSubmenu(nodeElement);

  if (!subMenuElement) {
    return;
  }

  animationInProgress = true;

  Velocity(
    subMenuElement,
    {
      height: [0, subMenuElement.scrollHeight],
    },
    {
      ...animationSettings,
      complete: () => {
        subMenuElement.style.height = null;
        animationInProgress = false;
        cbComplete();
      },
    }
  );

  cbStart({
    heightDiff: -subMenuElement.scrollHeight,
  });
};

class Menu extends React.Component {
  static propTypes = {
    currentUrl: PropTypes.string,
    sidebarStyle: PropTypes.string,
    onHeightChange: PropTypes.func,

    animationDuration: PropTypes.node,
    animationEasing: PropTypes.string,
  };

  static defaultProps = {
    sidebarStyle: SIDEBAR_STYLE_DEFAULT,
    onHeightChange: () => {},

    animationDuration: 300,
    animationEasing: "ease-in-out",
  };

  constructor(props, context) {
    super(props, context);

    this.state = Object.assign({}, this.state, {
      expandedNodes: [],
    });
  }

  expandNode(nodeDef, expand = true) {
    if (animationInProgress) {
      return;
    }

    const { state } = this;

    const currentLevelExpandedNode = _.find(
      state.expandedNodes,
      (node) => node.subMenuLevel === nodeDef.subMenuLevel
    );

    const nextExpandedNodes = _.without(
      state.expandedNodes,
      currentLevelExpandedNode
    );

    const updateState = (expandedNodes) => {
      const newState = Object.assign({}, state, { expandedNodes });
      this.setState(newState);
    };
    // Animate close and update state if no other node will be expanded
    if (currentLevelExpandedNode) {
      animateCloseNode(
        currentLevelExpandedNode.element,
        () => !expand && updateState(nextExpandedNodes),
        (e) => {
          this.props.onHeightChange(e.heightDiff);
        }
      );
    }

    if (expand) {
      nextExpandedNodes.push(nodeDef);

      animateOpenNode(
        nodeDef.element,
        () => updateState(nextExpandedNodes),
        (e) => {
          this.props.onHeightChange(e.heightDiff);
        }
      );
    }
  }

  isNodeExpanded(nodeDef) {
    const { state } = this;
    return _.some(
      state.expandedNodes,
      (node) =>
        node.subMenuLevel === nodeDef.subMenuLevel && node.key === nodeDef.key
    );
  }

  toggleNode(nodeDef) {
    const isExpanded = this.isNodeExpanded(nodeDef);
    this.expandNode(nodeDef, !isExpanded);
  }

  setSidebarNodesHighlights(url, items) {
    if (this.props.currentUrl) {
      const activeNodes = findActiveNodes(items, url);
      this.setState(
        Object.assign({}, this.state, {
          activeNodes,
          expandedNodes: activeNodes,
        })
      );
    }
  }

  generateLink(nodeDef) {
    const clickHandler = (nodeDef) => {
      if (
        this.props.sidebarStyle === SIDEBAR_STYLE_DEFAULT ||
        (this.props.sidebarStyle === SIDEBAR_STYLE_BIGICONS &&
          nodeDef.subMenuLevel > 0)
      ) {
        this.toggleNode(nodeDef);
      }
    };

    if (nodeDef.children) {
      return (
        <a
          href="javascript:void(0)"
          onClick={() => clickHandler(nodeDef)}
          className={classes.containerLink}
        >
          {nodeDef.icon ? <i className={nodeDef.icon} /> : null}
          <span className="nav-label">
            {I18n.t(nodeDef.title?.toLowerCase())}
          </span>
          {nodeDef.icon2 ? <i className={nodeDef.icon2} /> : null}
          {nodeDef.sidebarElement ? (
            nodeDef.sidebarElement
          ) : nodeDef.children ? (
            <i style={{ transform: "rotateZ(180deg)" }} className="fa arrow" />
          ) : null}
        </a>
      );
    } else {
      const { suits, activeSuitId } = this.props.user;
      return nodeDef.external ? (
        <a
          href={nodeDef.url}
          className={classes.sidebarLink}
          target={nodeDef.newTab ? "_blank" : "_self"}
          rel="noreferrer"
        >
          {nodeDef.icon ? <i className={nodeDef.icon} /> : null}
          <div className={classes.sidebarLinkText}>
            <span className="nav-label">
              {I18n.t(nodeDef.title?.toLowerCase())}
            </span>
            {nodeDef.sidebarElement?.toLowerCase()}
          </div>
        </a>
      ) : (
        <Link
          to={addAppToUrl(nodeDef.url, suits, activeSuitId)}
          onClick={() => {}}
          className={classes.sidebarLink}
        >
          {nodeDef.icon ? <i className={nodeDef.icon} /> : null}
          <div className={classes.sidebarLinkText}>
            <span className="nav-label">
              {I18n.t(nodeDef.title?.toLowerCase())}
            </span>
            {nodeDef.sidebarElement}
          </div>
          {nodeDef.icon2 ? <i className={nodeDef.icon2} /> : null}
        </Link>
      );
    }
  }

  generateSubNodes(nodeDefs, subMenuLevel = 1, title = false) {
    const nodes = _.map(nodeDefs, (nodeDef) => {
      const nodeId = nodeDef.id || uuidv4();
      const classes = classNames({
        "has-submenu": !!nodeDef.children,
        expanded:
          this.props.sidebarStyle !== SIDEBAR_STYLE_SLIM &&
          this.isNodeExpanded(nodeDef),
        "nested-active":
          nodeDef.children && _.contains(this.state.activeNodes, nodeDef),
        active: nodeDef.url && _.contains(this.state.activeNodes, nodeDef),
      });

      return (
        <li
          key={nodeId}
          className={classes}
          ref={(element) => (nodeDef.element = element)}
        >
          {this.generateLink(nodeDef)}
          {nodeDef.children
            ? this.generateSubNodes(nodeDef.children, subMenuLevel + 1)
            : null}
        </li>
      );
    });

    return (
      <ul
        className={`submenu-level-${subMenuLevel}`}
        style={{
          display:
            this.props.sidebarStyle === SIDEBAR_STYLE_DEFAULT ? "block" : "",
        }}
        data-submenu-title={title}
      >
        {nodes}
      </ul>
    );
  }

  generateRootNodes(nodeDefs) {
    return _.map(nodeDefs, (nodeDef) => {
      const nodeId = nodeDef.id || uuidv4();
      const classes = classNames("primary-submenu", {
        "has-submenu": !!nodeDef.children,
        expanded:
          this.props.sidebarStyle === SIDEBAR_STYLE_DEFAULT &&
          this.isNodeExpanded(nodeDef),
        "nested-active":
          nodeDef.children && _.contains(this.state.activeNodes, nodeDef),
        active: nodeDef.url && _.contains(this.state.activeNodes, nodeDef),
        "has-icon": nodeDef.icon,
      });

      return (
        <li
          key={nodeId}
          className={classes}
          ref={(element) => (nodeDef.element = element)}
        >
          {this.generateLink(nodeDef)}
          {nodeDef.children
            ? this.generateSubNodes(nodeDef.children, 1, nodeDef.title)
            : null}
        </li>
      );
    });
  }

  componentWillMount() {
    this.setSidebarNodesHighlights(
      this.props.currentUrl,
      this.props.sidebar.items
    );
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.currentUrl !== this.props.currentUrl) {
      this.setSidebarNodesHighlights(
        nextProps.currentUrl,
        nextProps.sidebar.items
      );
    }
    if (nextProps.sidebarStyle !== this.props.sidebarStyle) {
      if (nextProps.sidebarStyle !== SIDEBAR_STYLE_DEFAULT) {
        this.setState(Object.assign({}, this.state, { expandedNodes: [] }));
      } else {
        this.setSidebarNodesHighlights(
          this.props.currentUrl,
          this.props.sidebar.items
        );
      }
    }
  }

  render() {
    const { items } = this.props.sidebar;

    return <ul className="side-menu">{this.generateRootNodes(items)}</ul>;
  }
}

export default connect(
  (store) => ({
    sidebar: store.sidebar,
    user: store.user,
  }),
  {
    getCurrentSuit,
  }
)(Menu);
