import { Form, PrimaryButton, TextBox } from "components";
import { required } from "modules/validations";
import React from "react";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import { getNotifyConfig } from "reducers/config";
import { Field, reduxForm } from "redux-form";

export class Twilio extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      initialized: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    //
    const { notify } = nextProps.config;
    if (
      notify &&
      notify.channels.sms.connection_info.type === "SMS-TWILIO" &&
      !this.state.initialized
    ) {
      nextProps.initialize({
        vendor: notify.channels.sms.vendor || undefined,
        type: "SMS-TWILIO",
        account_sid:
          notify.channels.sms.connection_info.account_sid || undefined,
        sender: notify.channels.sms.connection_info.sender || undefined,
        auth_token: notify.channels.sms.connection_info.auth_token || undefined,
      });
      //
      this.setState({ initialized: true });
    }
  }
  render() {
    const { valid, handleSubmit, disabled } = this.props;

    return (
      <Form>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "18px",
          }}
        >
          <Field
            name="vendor"
            placeholder={I18n.t(
              "SettingsPage_CommunicationChannels_SMSConfig_option_Twilio_Name_placeholder"
            )}
            validate={required}
            label={I18n.t(
              "SettingsPage_CommunicationChannels_SMSConfig_option_Twilio_Name_label"
            )}
            component={TextBox}
            disabled={disabled}
          />

          <Field
            name="account_sid"
            placeholder={I18n.t(
              "SettingsPage_CommunicationChannels_SMSConfig_option_Twilio_AccountSid_placeholder"
            )}
            validate={required}
            label={I18n.t(
              "SettingsPage_CommunicationChannels_SMSConfig_option_Twilio_AccountSid_label"
            )}
            component={TextBox}
            disabled={disabled}
          />

          <Field
            name="sender"
            placeholder={I18n.t(
              "SettingsPage_CommunicationChannels_SMSConfig_option_Twilio_Sender_placeholder"
            )}
            validate={required}
            label={I18n.t(
              "SettingsPage_CommunicationChannels_SMSConfig_option_Twilio_Sender_label"
            )}
            component={TextBox}
            disabled={disabled}
          />

          <Field
            name="auth_token"
            placeholder={I18n.t(
              "SettingsPage_CommunicationChannels_SMSConfig_option_Twilio_Authtoken_placeholder"
            )}
            validate={required}
            label={I18n.t(
              "SettingsPage_CommunicationChannels_SMSConfig_option_Twilio_Authtoken_label"
            )}
            component={TextBox}
            disabled={disabled}
          />

          <PrimaryButton
            value={I18n.t(
              "SettingsPage_CommunicationChannels_SMSConfig_option_saveBtn"
            )}
            onClick={handleSubmit}
            disabled={!valid}
            style={{ marginTop: "20px" }}
          />
        </div>
      </Form>
    );
  }
}

export default reduxForm({
  form: "nconfig",
})(
  connect(
    (store) => ({
      user: store.user,
      config: store.config,
      form: store.form,
    }),
    {
      getNotifyConfig,
    }
  )(Twilio)
);
