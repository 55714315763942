import Products from "api/Products";
import {
  DATE_TYPE,
  IMAGE,
  NUMBER,
  OBJECT,
  STRING,
} from "consts/TableColumnTypes";
import _ from "lodash";
import moment from "moment";

export const GET_ALL_PRODUCTS = "GET_ALL_PRODUCTS";
export const CUSTOMER_PRODUCT_CHECK_DATATABLE_ROW =
  "CUSTOMER_PRODUCT_CHECK_DATATABLE_ROW";
export const CUSTOMER_PRODUCT_CHECK_ALL_DATATABLE_ROW =
  "CUSTOMER_PRODUCT_CHECK_ALL_DATATABLE_ROW";
export const CUSTOMER_PRODUCT_COLUMN_TOGGLE = "CUSTOMER_PRODUCT_COLUMN_TOGGLE";
export const CUSTOMER_PRODUCT_RESET_COLUMN = "CUSTOMER_PRODUCT_RESET_COLUMN";
export const PRODUCT_TOGGLE_ROW_ACTION_MENU = "PRODUCT_TOGGLE_ROW_ACTION_MENU";
export const CREATE_SMART_LINK_PRODUCT = "CREATE_SMART_LINK_PRODUCT";
export const CREATE_MULTI_SMART_LINK_PRODUCT =
  "CREATE_MULTI_SMART_LINK_PRODUCT";
export const RESET_PRODUCT_STORE = "RESET_PRODUCT_STORE";
export const GET_NEXT_PRODUCTS = "GET_NEXT_PRODUCTS";
export const GET_PREVIOUS_PRODUCTS = "GET_PREVIOUS_PRODUCTS";
export const GET_HOT_PRODUCTS = "GET_HOT_PRODUCTS";
export const GET_PRODUCTS_BY_NAME = "GET_PRODUCTS_BY_NAME";

export function getAllProducts(shopifySubdomain) {
  return {
    type: GET_ALL_PRODUCTS,
    payload: Products.getProducts(shopifySubdomain),
  };
}

export function getNextProducts(nextCursor, shopifySubdomain) {
  return {
    type: GET_NEXT_PRODUCTS,
    payload: Products.getNextProducts(nextCursor, shopifySubdomain),
  };
}

export function getProductsByName(name, shopifySubdomain) {
  return {
    type: GET_PRODUCTS_BY_NAME,
    payload: Products.getProductsByName(name, shopifySubdomain),
  };
}

// export const getProductsByName = (name, shopifySubdomain) => async (dispatch) => {
//   try {
//     let res = await Products.getProductsByName(name);
//     dispatch({
//       type: `${GET_PRODUCTS_BY_NAME}_FULFILLED`,
//       payload: res.body.data.products,
//     });
//   } catch (err) {
//     dispatch({ type: `${GET_PRODUCTS_BY_NAME}_ERROR` });
//   }
// };

export const resetProductStore = () => {
  return {
    type: RESET_PRODUCT_STORE,
  };
};

export const getPreviousProducts =
  (currentPage) => async (dispatch, getState) => {
    try {
      let first = currentPage * 50;
      let last = currentPage * 50 + 50;
      let products = getState().products.allProducts.slice(first, last);
      dispatch({
        type: `${GET_PREVIOUS_PRODUCTS}_FULFILLED`,
        payload: products,
      });
    } catch (err) {
      dispatch({ type: `${GET_PREVIOUS_PRODUCTS}_ERROR` });
    }
  };

export const createSDLProduct =
  (bulkOrSingle, listOfProducts) => async (dispatch, getState) => {
    let tempProducts = [...getState().products.allProducts];
    let products = [];
    if (bulkOrSingle === "single") {
      products = tempProducts
        .filter((row) => {
          if (row.node && listOfProducts.id === row.node.id) {
            return row.node;
          }
        })
        .map((row) => {
          return row.node;
        });
      try {
        let res = await Products.createSDLProduct(products);
        dispatch({
          type: `${CREATE_SMART_LINK_PRODUCT}_FULFILLED`,
          payload: res,
        });
      } catch (err) {
        dispatch({ type: `${CREATE_SMART_LINK_PRODUCT}_ERROR` });
      }
    } else if (bulkOrSingle === "bulk") {
      const listOfIDS = [];
      getState().products.products.rows.forEach((row) => {
        row.checked ? listOfIDS.push(row.id) : null;
      });
      products = getState()
        .products.allProducts.filter((row) => {
          return listOfIDS.includes(row.node.id);
        })
        .map((row) => {
          return row.node;
        });
      try {
        let res = await Products.createSDLProduct(products);
        dispatch({
          type: `${CREATE_MULTI_SMART_LINK_PRODUCT}_FULFILLED`,
          payload: res,
        });
      } catch (err) {
        dispatch({ type: `${CREATE_MULTI_SMART_LINK_PRODUCT}_ERROR` });
      }
    }
  };

export const checkDataTableRow = (id) => {
  return {
    type: CUSTOMER_PRODUCT_CHECK_DATATABLE_ROW,
    payload: id,
  };
};

export const checkAllTableRows = (newChecked) => {
  return {
    type: CUSTOMER_PRODUCT_CHECK_ALL_DATATABLE_ROW,
    payload: newChecked,
  };
};

export const onColumnToggle = (idx) => {
  return {
    type: CUSTOMER_PRODUCT_COLUMN_TOGGLE,
    payload: idx,
  };
};

export const toggleRowActionMenu = (idx) => {
  return {
    type: PRODUCT_TOGGLE_ROW_ACTION_MENU,
    payload: idx,
  };
};

export const onResetColumns = () => {
  return {
    type: CUSTOMER_PRODUCT_RESET_COLUMN,
  };
};

export const searchProduct = () => {
  //SearchProducts
};

export const updateProducts = (singleOrBulk, allProducts, smartLinks) => {
  const updatedProducts = _.map(allProducts, (row) => {
    _.forEach(smartLinks, (smartlink) => {
      if (smartlink.id === row.node.id) {
        Object.assign(row.node, smartlink);
      }
    });
    return row;
  });

  return updatedProducts;
};

// const appendToCursors = (arr, firstOrNext) => {
//   let tempArr = [];
//   const lastIndex = arr.length - 1;
//   if (firstOrNext === "first") {
//     tempArr.push(arr[0].cursor);
//     tempArr.push(arr[lastIndex].cursor);
//   } else {
//     tempArr.push(arr[lastIndex].cursor);
//   }
//   return tempArr;
// };

const initialState = {
  isLoading: false,
  error: false,

  tableData: null,
  pageInfo: null,
  nextCursor: null,
  previousCursor: null,
};

export default function productsReducer(state = initialState, action) {
  switch (action.type) {
    case `${GET_ALL_PRODUCTS}_PENDING`:
    case `${GET_NEXT_PRODUCTS}_PENDING`:
    case `${GET_PRODUCTS_BY_NAME}_PENDING`:
      return {
        ...state,
        isPending: true,
        error: false,
      };

    case `${GET_ALL_PRODUCTS}_REJECTED`:
    case `${GET_NEXT_PRODUCTS}_REJECTED`:
    case `${GET_PRODUCTS_BY_NAME}_REJECTED`:
      return {
        ...state,
        isPending: false,
        error: action.payload,
      };

    case `${GET_ALL_PRODUCTS}_FULFILLED`: {
      const products = action.payload.body.data.products;
      return {
        ...state,
        isPending: false,
        tableData: prepareTableData(products.edges),
        pageInfo: products.pageInfo,
        nextCursor: products.pageInfo.hasNextPage
          ? products.edges[products.edges.length - 1].cursor
          : null,
        previousCursor: products.pageInfo.hasPreviousPage
          ? products.edges[0].cursor
          : null,
      };
    }
    case `${GET_NEXT_PRODUCTS}_FULFILLED`: {
      const products = action.payload.body.data.products;
      return {
        ...state,
        isPending: false,
        tableData: prepareTableData(products.edges),
        pageInfo: products.pageInfo,
        // nextCursor: products.pageInfo.hasNextPage
        //   ? products.edges[products.edges.length - 1].cursor
        //   : null,
        nextCursor: products.pageInfo.endCursor,
        previousCursor: products.pageInfo.hasPreviousPage
          ? products.edges[0].cursor
          : null,
      };
    }
    case `${GET_PRODUCTS_BY_NAME}_FULFILLED`: {
      const products = action.payload.body.data.products;
      return {
        ...state,
        isPending: false,
        tableData: prepareTableData(products.edges),
        pageInfo: null,
        nextCursor: null,
        previousCursor: null,
      };
    }

    case `${GET_PREVIOUS_PRODUCTS}_FULFILLED`:
      return {
        ...state,
        products: Object.assign(
          state.products,
          prepareTableData(action.payload)
        ),
        isPending: false,
      };
    case `${CREATE_SMART_LINK_PRODUCT}_FULFILLED`:
      return {
        ...state,
        allProducts: updateProducts(
          "single",
          state.allProducts,
          action.payload.body.smartlinks
        ),

        products: prepareTableData(
          updateProducts(
            "single",
            state.allProducts,
            action.payload.body.smartlinks
          )
        ),
      };
    case `${CREATE_MULTI_SMART_LINK_PRODUCT}_FULFILLED`:
      return {
        ...state,
        allProducts: updateProducts(
          "bulk",
          state.allProducts,
          action.payload.body.smartlinks
        ),

        products: prepareTableData(
          updateProducts(
            "bulk",
            state.allProducts,
            action.payload.body.smartlinks
          )
        ),
      };

    // table data
    case CUSTOMER_PRODUCT_CHECK_DATATABLE_ROW:
      return {
        ...state,
        tableData: {
          ...state.tableData,
          rows: state.tableData.rows.map((row, rowIdx) => {
            return rowIdx === action.payload
              ? {
                  ...state.tableData.rows[rowIdx],
                  checked: !state.tableData.rows[rowIdx].checked,
                }
              : state.tableData.rows[rowIdx];
          }),
        },
      };
    case CUSTOMER_PRODUCT_CHECK_ALL_DATATABLE_ROW:
      return {
        ...state,
        tableData: {
          ...state.tableData,
          rows: state.tableData.rows.map((row) => ({
            ...row,
            checked: action.payload,
          })),
        },
      };
    case CUSTOMER_PRODUCT_COLUMN_TOGGLE:
      return {
        ...state,
        tableData: {
          ...state.tableData,
          labels: state.tableData.labels.map((label, idx) => {
            return idx === action.payload
              ? {
                  ...label,
                  selected: !label.selected,
                }
              : label;
          }),
        },
      };
    case RESET_PRODUCT_STORE:
      return {
        tableData: [],
        filteredtableData: null,
        alltableData: [],
        cursors: [],
      };
    case CUSTOMER_PRODUCT_RESET_COLUMN:
      return {
        ...state,
        tableData: {
          ...state.tableData,
          labels: state.tableData.labels.map((label) => ({
            ...label,
            selected: true,
          })),
        },
      };
    case PRODUCT_TOGGLE_ROW_ACTION_MENU:
      return {
        ...state,
        tableData: {
          ...state.tableData,
          rows: state.tableData.rows.map((row, rowIdx) => {
            return rowIdx === action.payload
              ? {
                  ...state.tableData.rows[rowIdx],
                  actionsMenu: !state.tableData.rows[rowIdx].actionsMenu,
                }
              : state.tableData.rows[rowIdx];
          }),
        },
      };
    default:
      return {
        ...state,
      };
  }
}

const prepareTableData = (tableData) => {
  const dateFormat = "DD MMM YY HH:mm:ss";

  const labels = [
    {
      name: "IMAGE",
      varName: "small_image.edges[0].node.src",
      selected: true,
      type: IMAGE,
      has_sort: false,
      defaultValue: "--",
    },
    {
      name: "Title",
      varName: ["title"],
      selected: true,
      type: STRING,
      has_sort: false,
      defaultValue: "--",
    },
    {
      name: "SKU",
      varName: "variants.edges[0].node.sku",
      selected: true,
      type: STRING,
      has_sort: false,
      defaultValue: "--",
    },
    {
      name: "PRICE",
      varName: "variants.edges[0].node.price",
      selected: true,
      type: NUMBER,
      has_sort: true,
      defaultValue: "--",
    },
  ];

  const rows = tableData.map(({ node: row }) => {
    return {
      checked: false,
      actionsMenu: false,
      id: row.id,
      handle: row.handle,

      values: labels.map((label) => {
        if (label.type === DATE_TYPE) {
          return moment(
            moment.utc(_.get(row, label.varName, label.defaultValue))
          )
            .local()
            .format(dateFormat);
        } else if (label.type === OBJECT) {
          return JSON.stringify(_.get(row, label.varName, label.defaultValue));
        } else if (label.type === NUMBER) {
          return parseInt(_.get(row, label.varName, label.defaultValue));
        }
        return _.get(row, label.varName, label.defaultValue);
      }),
    };
  });
  return { labels, rows };
};
