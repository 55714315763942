import amzonupload from "./amzonupload";

// const uploadToImgur = (e, prop, img, callback, errCallBack = () => {}) => {
//   const reader = new window.FileReader();
//   reader.readAsDataURL(img);
//   reader.onloadend = () =>
//     $.ajax({
//       url: "https://api.imgur.com/3/upload",
//       type: "POST",
//       datatype: "json",
//       data: {
//         image: reader.result.replace(/data:.*?;base64,/, ""),
//         type: "base64",
//       },
//       success: callback, // calling function which displays url
//       error: errCallBack,
//       beforeSend: (xhr) =>
//         xhr.setRequestHeader("Authorization", "Client-ID " + config.CLIENT_ID),
//     });
// };

//export default uploadToImgur
export default amzonupload;
