import { setLocale } from "react-redux-i18n";
import { getFromLocalStore } from "store/localStore";
import { store } from "../main";
import { setToLocalStore } from "../store/localStore";

/**
 * Retrieves the locale from the local store, or defaults to "en" if not found.
 * @returns {string} The locale value.
 */
export const locale = () => getFromLocalStore("locale") || "en";

export function setLocaleLang(locale) {
  store.dispatch(setLocale(locale));
  setToLocalStore("locale", locale);
}
