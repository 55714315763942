import {
  CURRENCY,
  DATE_TYPE,
  NUMBER,
  OBJECT,
  STRING,
} from "consts/TableColumnTypes";
import _ from "lodash";
import moment from "moment";
import { RESET_ERRORS } from "reducers/user";
import PushEndpoints from "../api/Push";
import { SET_ACTIVE_SUIT_ID } from "./user";

// ------------------------------------
// Constants
// ------------------------------------

const GET_WEBPUSH_ANALYTICS = "GET_WEBPUSH_ANALYTICS";
const GET_SUIT_APPPUSHS = " GET_SUIT_APPPUSHS";
const GET_SUIT_WEBPUSHS = "GET_SUIT_WEBPUSHS";
const GET_SUIT_APPPUSHS_SEARCH = " GET_SUIT_APPPUSHS_SEARCH";
const GET_SUIT_WEBPUSHS_SEARCH = "GET_SUIT_WEBPUSHS_SEARCH";
const GET_SUIT_SMS = "GET_SUIT_SMS";
const GET_SUIT_SMS_SEARCH = "GET_SUIT_SMS_SEARCH";
const CREATE_SMS = "CREATE_SMS";
const GET_SUIT_EMAIL = "GET_SUIT_EMAIL";
const GET_SUIT_EMAIL_SEARCH = "GET_SUIT_EMAIL_SEARCH";
const GET_CAMPAIGN_ANALYTICS = "GET_CAMPAIGN_ANALYTICS";
const GET_SUIT_PUSH_STATISTICS = "GET_SUIT_PUSH_STATISTICS";
const DeleteCAMPAINAN = "DeleteCAMPAINAN";
const REMOVEANALITICS = "REMOVEANALITICS";
const DOWNLOAD_TARGETLIST = "DOWNLOAD_TARGETLIST";
const CREATETARGETLIST = "CREATETARGETLIST";
const GET_SUIT_TARGETLIST = "GET_SUIT_TARGETLIST";
const DeleteTARGETLIST = "DeleteTARGETLIST";
const CUSTOMER_TARGETLIST_CHECK_DATATABLE_ROW =
  "CUSTOMER_TARGETLIST_CHECK_DATATABLE_ROW";
const SMS_TARGET_LIST_TOGGLE_ROW_ACTION_MENU =
  "SMS_TARGET_LIST_TOGGLE_ROW_ACTION_MENU";
const CUSTOMER_SMS_TARGET_LIST_CLEAR_ACTION_MENU =
  "CUSTOMER_SMS_TARGET_LIST_CLEAR_ACTION_MENU";
const CUSTOMER_SMS_TARGET_LIST_CHECK_ALL_TABLE_ROWS =
  "CUSTOMER_SMS_TARGET_LIST_CHECK_ALL_TABLE_ROWS";
const Push_CHECK_DATATABLE_ROW = "Push_CHECK_DATATABLE_ROW";
const SORT_Push = "SORT_Push";
const Push_TOGGLE_ROW_ACTION_MENU = "Push_TOGGLE_ROW_ACTION_MENU";
const Push_CLEAR_ACTION_MENU = "Push_CLEAR_ACTION_MENU";
const Push_COLUMN_TOGGLE = "Push_COLUMN_TOGGLE";
const Push_RESET_COLUMNS = "Push_RESET_COLUMNS";
const CLEAR_CAMPAIGN_ANALYTICS = "CLEAR_CAMPAIGN_ANALYTICS";
const Push_CHECK_ALL_TABLE_ROWS = "Push_CHECK_ALL_TABLE_ROWS";
const DELETE_TARGET_LIST_BULK = "DELETE_TARGET_LIST_BULK";
const DELETE_CAMPAIGN_BULK = "DELETE_CAMPAIGN_BULK";
const GET_SUIT_WEBPUSH_STATISTICS = "GET_SUIT_WEBPUSH_STATISTICS";

// ------------------------------------
// Actions
// ------------------------------------
export function checkTableRow(idx) {
  return {
    type: Push_CHECK_DATATABLE_ROW,
    payload: idx,
  };
}

export function clearCampaignAnalytics() {
  return {
    type: CLEAR_CAMPAIGN_ANALYTICS,
  };
}

export const sortTable = (name, type) => async (dispatch) => {
  dispatch({
    type: SORT_Push,
    payload: {
      type,
      name: name.split("_1")[0],
      descending: name.includes("_1"),
    },
  });
};
export function onColumnsToggle(idx) {
  return {
    type: Push_COLUMN_TOGGLE,
    payload: idx,
  };
}

export function onResetsColumns() {
  return {
    type: Push_RESET_COLUMNS,
  };
}
export function toggleRowMenu(idx) {
  return {
    type: Push_TOGGLE_ROW_ACTION_MENU,
    payload: idx,
  };
}
export function clearAction(idx) {
  return {
    type: Push_CLEAR_ACTION_MENU,
    payload: idx,
  };
}

// Web push
export function getSuitWebPushs(suitId, data) {
  return {
    type: GET_SUIT_WEBPUSHS,
    payload: PushEndpoints.getSuitWebPushs(suitId, data),
  };
}
// App push
export function getSuitAppPushs(suitId, apikey, data) {
  return {
    type: GET_SUIT_APPPUSHS,
    payload: PushEndpoints.getSuitAppPushs(suitId, "appPush", data),
  };
}
export function checkAllRows(newChecked) {
  return {
    type: Push_CHECK_ALL_TABLE_ROWS,
    payload: newChecked,
  };
}

// SMS
// data = { start: '2015-01-01', end: '2015-01-02'} or null
export function getSuitSMS(suitId, data) {
  return {
    type: GET_SUIT_SMS,
    payload: PushEndpoints.getSuitSMS(suitId, data),
  };
}
//Email
export function getSuitEmail(suitId, start, end, page) {
  return {
    type: GET_SUIT_EMAIL,
    payload: PushEndpoints.getSuitEmails(suitId, start, end, page),
  };
}
// search
export function getSuitWebPushsSearch(suitId, nameVal) {
  return {
    type: GET_SUIT_WEBPUSHS_SEARCH,
    payload: PushEndpoints.getSuitPushsSearch(
      suitId,
      nameVal,
      "getSuitWebPushs"
    ),
  };
}
// search
export function getSuitAppPushsSearch(suitId, nameVal) {
  return {
    type: GET_SUIT_APPPUSHS_SEARCH,
    payload: PushEndpoints.getSuitPushsSearch(suitId, nameVal, "appPush"),
  };
}

// search
export function getSuitSMSSearch(suitId, nameVal) {
  return {
    type: GET_SUIT_SMS_SEARCH,
    payload: PushEndpoints.getSuitPushsSearch(suitId, nameVal, "SMS"),
  };
}
export function CreateSMS(suitId) {
  return {
    type: CREATE_SMS,
    payload: PushEndpoints.getSuitPushs(suitId, "SMS"),
  };
}

// search
export function getSuitEmailSearch(suitId, nameVal) {
  return {
    type: GET_SUIT_EMAIL_SEARCH,
    payload: PushEndpoints.getSuitPushsSearch(suitId, nameVal, "email"),
  };
}

export function getWebPushAnalytics(suitId, data) {
  return {
    type: GET_WEBPUSH_ANALYTICS,
    payload: PushEndpoints.getWebPushAnalytics(suitId, data),
  };
}
export function getCampaignAnalytics(suitId, campaignId, data) {
  return {
    type: GET_CAMPAIGN_ANALYTICS,
    payload: PushEndpoints.getCampaignAnalytics(suitId, campaignId, data),
  };
}
export function getSuitPushStatistics(suitId, data) {
  return {
    type: GET_SUIT_PUSH_STATISTICS,
    payload: PushEndpoints.getSuitPushStatistics(suitId, data),
  };
}

export function getSuitWebPushStatistics(suitId, data) {
  return {
    type: GET_SUIT_WEBPUSH_STATISTICS,
    payload: PushEndpoints.getSuitWebPushStatistics(suitId, data),
  };
}

export function deleteCampain(suitId, campaignId) {
  return {
    type: DeleteCAMPAINAN,
    payload: PushEndpoints.deleteCampaign(suitId, campaignId),
  };
}

// campaignIds = {"campaign_ids": ["test", "test"]}
export function deleteCampaignBulk(suitId, campaignIds) {
  return {
    type: DELETE_CAMPAIGN_BULK,
    payload: PushEndpoints.deleteCampaignBulk(suitId, campaignIds),
  };
}

export function removeCampaignAnalytics() {
  return {
    type: REMOVEANALITICS,
  };
}
export function createTargetList(suitId, data) {
  return {
    type: CREATETARGETLIST,
    payload: PushEndpoints.createTargetList(suitId, data),
  };
}
export function listTargetList(suitId) {
  return {
    type: GET_SUIT_TARGETLIST,
    payload: PushEndpoints.listTargetList(suitId),
  };
}
export function deleteTargetList(appId, targetListName) {
  return {
    type: DeleteTARGETLIST,
    payload: PushEndpoints.deleteTargetList(appId, targetListName),
  };
}
export function deleteTargetListBulk(appId, targetListNames) {
  return {
    type: DELETE_TARGET_LIST_BULK,
    payload: PushEndpoints.deleteTargetListBulk(appId, targetListNames),
  };
}
export function downloadTargetList(appId, targetListName) {
  return {
    type: DOWNLOAD_TARGETLIST,
    payload: PushEndpoints.downloadTargetList(appId, targetListName),
  };
}

function preparePush(tableData) {
  const dateFormat = "DD MMM YY HH:mm:ss";

  const labels = [
    {
      name: "campaigns_campaignName",
      has_sort: false,
      type: STRING,
      selected: true,
      varName: "name",
      defaultValue: "",
    },
    {
      name: "campaigns_campaignTitle",
      has_sort: false,
      type: STRING,
      selected: false,
      varName: "title",
      defaultValue: "NA",
    },
    {
      name: "campaigns_campaignType",
      has_sort: false,
      type: STRING,
      selected: true,
      varName: "sending_type",
      defaultValue: 0,
    },
    {
      name: "campaigns_campaignSuccessRatio",
      has_sort: false,
      type: STRING,
      selected: true,
      varName: "success_ratio",
    },
    {
      name: "campaigns_campaignCreatedAt",
      has_sort: true,
      type: DATE_TYPE,
      selected: true,
      varName: "created_at",
    },
    {
      name: "campaigns_campaignSentOn",
      has_sort: true,
      type: DATE_TYPE,
      selected: true,
      hasTooltip: true,
      tooltipBehind: "campaigns_campaignSentOn_tooltip",
      varName: "occurrences[0].sent_at",
      defaultValue: "Scheduled",
    },
    {
      name: "campaigns_campaignScheduledAt",
      has_sort: true,
      type: DATE_TYPE,
      selected: true,
      hasTooltip: true,
      tooltipBehind: "campaigns_campaignScheduledAt_tooltip",
      varName: "occurrences[0].scheduled_at",
    },
    {
      name: "campaigns_campaignMessage",
      has_sort: false,
      type: STRING,
      selected: false,
      varName: "message",
    },
    {
      name: "URL",
      has_sort: false,
      type: STRING,
      selected: false,
      varName: "url",
    },
    {
      name: "campaigns_campaignSent",
      has_sort: true,
      type: NUMBER,
      selected: false,
      varName: "sent_data.sent",
    },
    {
      name: "campaigns_campaignFailed",
      has_sort: true,
      type: STRING,
      selected: false,
      varName: "sent_data.failed",
    },
  ];

  const rows = tableData.map((row) => {
    let sent = _.get(row, ["sent_data", "sent"], 0);
    let failed = _.get(row, ["sent_data", "failed"], 0);

    return {
      checked: false,
      actionsMenu: false,
      campaignID: row.campaign_id,
      suitID: row.suit_id,
      values: labels.map((label) => {
        if (label.varName === "occurrences[0].sent_at") {
          const value = _.get(row, label.varName, null);
          return value
            ? moment(value).utc().local().format(dateFormat)
            : label.defaultValue;
        } else if (
          label.type === DATE_TYPE &&
          label.varName !== "occurrences[0].sent_at"
        ) {
          const date = _.get(row, label.varName, null);
          return date ? moment.utc(date).local().format(dateFormat) : "";
        } else if (label.type === OBJECT) {
          return JSON.stringify(_.get(row, label.varName, label.defaultValue));
        } else if (label.varName === "success_ratio") {
          return sent === 0 && failed === 0
            ? 0 + "%"
            : ((sent / (failed + sent)) * 100).toFixed(1) + "%";
        }
        return _.get(row, label.varName, label.defaultValue);
      }),
    };
  });

  return { labels, rows, actions: [{ type: "delete" }] };
}

export function toggleRowActionMenu(index) {
  return {
    type: SMS_TARGET_LIST_TOGGLE_ROW_ACTION_MENU,
    payload: index,
  };
}

export function checkDataTableRow(idx) {
  return {
    type: CUSTOMER_TARGETLIST_CHECK_DATATABLE_ROW,
    payload: idx,
  };
}

export function clearActionMenu(idx) {
  return {
    type: CUSTOMER_SMS_TARGET_LIST_CLEAR_ACTION_MENU,
    payload: idx,
  };
}

export function checkAllTableRows(newChecked) {
  return {
    type: CUSTOMER_SMS_TARGET_LIST_CHECK_ALL_TABLE_ROWS,
    payload: newChecked,
  };
}

function prepareTableDate(tableData) {
  const labels = [
    {
      name: "listSms_Name",
      has_sort: false,
      type: STRING,
      selected: true,
      varName: ["_id", "target_list_name"],
      defaultValue: "N/A",
    },
    {
      name: "listSms_Count",
      has_sort: false,
      type: NUMBER,
      selected: true,
      varName: ["count"],
      defaultValue: "N/A",
    },
  ];
  const rows = tableData.map((row) => ({
    checked: false,
    actionsMenu: false,
    name: _.get(row, labels[0].varName, labels[0].defaultValue),
    values: [
      _.get(row, labels[0].varName, labels[0].defaultValue),
      _.get(row, labels[1].varName, labels[1].defaultValue),
    ],
  }));

  return { labels, rows, actions: true };
}

// ------------------------------------
// Initial State
// ------------------------------------
const initialState = {
  webPushAnalytics: null,
  campaignAnalytics: null,
  summary: null,
  webPush: [],
  smsPushs: [],
  targetLists: null,
  targetListsObjects: null,
  Email: [],
  webPushs: [],
  appPushs: [],
  isPending: false,
  duplicate: null,
  error: false,
  pushs: null,
  allPushs: [],
  count: null,

  webPushStatistics: null,
};
// ------------------------------------
// Reducer
// ------------------------------------
export default function pushReducer(state = initialState, action) {
  switch (action.type) {
    case RESET_ERRORS:
      return {
        ...state,
        error: false,
      };
    case `${GET_SUIT_WEBPUSHS}_PENDING`:
    case `${GET_SUIT_APPPUSHS}_PENDING`:
    case `${GET_SUIT_WEBPUSHS_SEARCH}_PENDING`:
    case `${GET_SUIT_APPPUSHS_SEARCH}_PENDING`:
    case `${GET_SUIT_SMS}_PENDING`:
    case `${GET_SUIT_SMS_SEARCH}_PENDING`:
    case `${GET_SUIT_EMAIL}_PENDING`:
    case `${GET_SUIT_EMAIL_SEARCH}_PENDING`:
    case `${GET_WEBPUSH_ANALYTICS}_PENDING`:
    case `${DeleteCAMPAINAN}_PENDING`:
    case `${DELETE_CAMPAIGN_BULK}_PENDING`:
    case `${GET_SUIT_PUSH_STATISTICS}_PENDING`:
    case `${GET_CAMPAIGN_ANALYTICS}_PENDING`:
    case `${CREATETARGETLIST}_PENDING`:
    case `${GET_SUIT_TARGETLIST}_PENDING`:
    case `${DeleteTARGETLIST}_PENDING`:
    case `${SORT_Push}_PENDING`:
    case `${GET_SUIT_WEBPUSH_STATISTICS}_PENDING`:
      return { ...state, isPending: true, error: false };

    case `${GET_WEBPUSH_ANALYTICS}_REJECTED`:
    case `${GET_SUIT_WEBPUSHS}_REJECTED`:
    case `${GET_SUIT_APPPUSHS}_REJECTED`:
    case `${GET_SUIT_WEBPUSHS_SEARCH}_REJECTED`:
    case `${GET_SUIT_APPPUSHS_SEARCH}_REJECTED`:
    case `${GET_SUIT_SMS}_REJECTED`:
    case `${GET_SUIT_SMS_SEARCH}_REJECTED`:
    case `${GET_SUIT_EMAIL}_REJECTED`:
    case `${GET_SUIT_EMAIL_SEARCH}_REJECTED`:
    case `${DeleteCAMPAINAN}_REJECTED`:
    case `${DELETE_CAMPAIGN_BULK}_REJECTED`:
    case `${GET_SUIT_PUSH_STATISTICS}_REJECTED`:
    case `${GET_SUIT_TARGETLIST}_REJECTED`:
    case `${DeleteTARGETLIST}_REJECTED`:
    case `${GET_SUIT_WEBPUSH_STATISTICS}_REJECTED`:
    case `${CREATETARGETLIST}_REJECTED`:
      return { ...state, isPending: false, error: action.payload };
    case CLEAR_CAMPAIGN_ANALYTICS:
      return {
        ...initialState,
      };
    case `${GET_CAMPAIGN_ANALYTICS}_REJECTED`:
      return { ...state, isPending: false, error: true };

    // Push Data Table Functions
    case Push_CHECK_DATATABLE_ROW:
      return {
        ...state,
        pushs: {
          ...state.pushs,
          rows: state.pushs.rows.map((row, rowIdx) => {
            return rowIdx === action.payload
              ? {
                  ...state.pushs.rows[rowIdx],
                  checked: !state.pushs.rows[rowIdx].checked,
                }
              : state.pushs.rows[rowIdx];
          }),
        },
      };
    case Push_RESET_COLUMNS:
      return {
        ...state,
        pushs: {
          ...state.pushs,
          labels: state.pushs.labels.map((label) => ({
            ...label,
            selected: true,
          })),
        },
      };
    case Push_TOGGLE_ROW_ACTION_MENU:
      return {
        ...state,
        pushs: {
          ...state.pushs,
          rows: state.pushs.rows.map((row, rowIdx) => {
            return rowIdx === action.payload
              ? {
                  ...state.pushs.rows[rowIdx],
                  actionsMenu: !state.pushs.rows[rowIdx].actionsMenu,
                }
              : state.pushs.rows[rowIdx];
          }),
        },
      };

    case Push_COLUMN_TOGGLE:
      return {
        ...state,
        pushs: {
          ...state.pushs,
          labels: state.pushs.labels.map((label, idx) => {
            return idx === action.payload
              ? {
                  ...label,
                  selected: !label.selected,
                }
              : label;
          }),
        },
      };
    case Push_CHECK_ALL_TABLE_ROWS:
      return {
        ...state,
        pushs: {
          ...state.pushs,
          rows: state.pushs.rows.map((row) => ({
            ...row,
            checked: action.payload,
          })),
        },
      };

    case SORT_Push: {
      const indexOfKey = state.pushs.labels
        .map((label) => label.name)
        .indexOf(action.payload.name);

      let tableRows = null;

      switch (action.payload.type) {
        case NUMBER:
          tableRows = _.sortBy(
            state.pushs.rows,
            (row) => row.values[indexOfKey]
          );
          break;
        case STRING:
          tableRows = _.sortBy(state.pushs.rows, (row) =>
            row.values[indexOfKey].toLowerCase()
          );
          break;
        case CURRENCY:
          tableRows = _.sortBy(state.pushs.rows, (row) =>
            parseFloat(row.values[indexOfKey].split(" ")[0])
          );
          break;
        case DATE_TYPE:
          tableRows = _.sortBy(state.pushs.rows, (row) =>
            new Date(row.values[indexOfKey]).getTime()
          );
          break;
      }
      if (action.payload.descending) {
        _.reverse(tableRows);
      }
      return {
        ...state,
        pushs: {
          ...state.pushs,
          rows: tableRows,
        },
      };
    }
    case Push_CLEAR_ACTION_MENU:
      return {
        ...state,
        pushs: {
          ...state.pushs,
          rows: state.pushs.rows.map((row, idx) => {
            return idx === action.payload
              ? { ...row, actionsMenu: false }
              : row;
          }),
        },
      };

    case `${GET_SUIT_WEBPUSH_STATISTICS}_FULFILLED`: {
      const subscribers =
        action.payload.body.webPush_subscribers.charts.subscribers;
      return {
        ...state,
        isPending: false,
        webPushStatistics: {
          optIn: subscribers?.find((s) => s?.status === "optin")?.count,
          optOut: subscribers?.find((s) => s?.status === "optout")?.count,
          total: subscribers?.reduce((acc, s) => acc + s.count, 0),
        },
      };
    }

    case `${GET_WEBPUSH_ANALYTICS}_FULFILLED`:
      return {
        ...state,
        webPushAnalytics: action.payload.body,
        isPending: false,
      };
    case `${GET_SUIT_WEBPUSHS}_FULFILLED`:
      return {
        ...state,
        isPending: false,
        webPushs: action.payload.body.campaigns,
        pushs: preparePush(action.payload.body.campaigns),
        allPushs: _.uniqBy(
          [...state.allPushs, ...action.payload.body.campaigns],
          "campaign_id"
        ),
        duplicate: null,
      };

    case `${GET_SUIT_APPPUSHS}_FULFILLED`:
      return {
        ...state,
        isPending: false,
        appPushs: action.payload.body.campaigns,
        pushs: preparePush(action.payload.body.campaigns),
        count: action.payload.body.count,

        allPushs: _.uniqBy(
          [...state.allPushs, ...action.payload.body.campaigns],
          "campaign_id"
        ),

        duplicate: null,
      };
    //searchsummary
    case `${GET_SUIT_WEBPUSHS_SEARCH}_FULFILLED`:
      return {
        ...state,
        isPending: false,
        webPushs: action.payload.body.campaigns,
      };

    case `${GET_SUIT_APPPUSHS_SEARCH}_FULFILLED`:
      return {
        ...state,
        isPending: false,
        appPushs: action.payload.body.campaigns,
      };
    case `${GET_SUIT_SMS}_FULFILLED`:
      return {
        ...state,
        isPending: false,
        smsPushs: action.payload.body.campaigns,
        pushs: preparePush(action.payload.body.campaigns),
        allPushs: _.uniqBy(
          [...state.allPushs, ...action.payload.body.campaigns],
          "campaign_id"
        ),

        duplicate: null,
      };
    // search
    case `${GET_SUIT_SMS_SEARCH}_FULFILLED`:
      return {
        ...state,
        isPending: false,
        smsPushs: action.payload.body.campaigns,
      };
    case `${GET_SUIT_EMAIL}_FULFILLED`:
      return {
        ...state,
        isPending: false,
        Email: action.payload.body.campaigns,
        pushs: preparePush(action.payload.body.campaigns),
        allPushs: _.uniq([...state.allPushs, ...action.payload.body.campaigns]),
        duplicate: null,
      };
    // search
    case `${GET_SUIT_EMAIL_SEARCH}_FULFILLED`:
      return {
        ...state,
        isPending: false,
        Email: action.payload.body.campaigns,
      };

    case `${GET_SUIT_PUSH_STATISTICS}_FULFILLED`:
      return { ...state, isPending: false, summary: action.payload.body };
    case `${GET_CAMPAIGN_ANALYTICS}_FULFILLED`:
      return {
        ...state,
        campaignAnalytics: action.payload.body,
        isPending: false,
      };
    case `${DeleteCAMPAINAN}_FULFILLED`:
    case `${DELETE_CAMPAIGN_BULK}_FULFILLED`:
    case `${CREATETARGETLIST}_FULFILLED`:
      return {
        ...state,
        isPending: false,
      };

    case `${GET_SUIT_TARGETLIST}_FULFILLED`:
      return {
        ...state,
        isPending: false,
        targetLists: prepareTableDate(action.payload.body.targetLists),
        targetListsObjects: action.payload.body.targetLists,
      };

    case `${DeleteTARGETLIST}_FULFILLED`:
      return {
        ...state,
        isPending: false,
      };

    case REMOVEANALITICS:
      return {
        ...state,
        campaignAnalytics: null,
        webPushAnalytics: null,
      };
    case SET_ACTIVE_SUIT_ID:
      return { ...state, summary: null };

    case SMS_TARGET_LIST_TOGGLE_ROW_ACTION_MENU:
      return {
        ...state,
        targetLists: {
          ...state.targetLists,
          rows: state.targetLists.rows.map((row, rowIdx) => {
            return rowIdx === action.payload
              ? {
                  ...state.targetLists.rows[rowIdx],
                  actionsMenu: !state.targetLists.rows[rowIdx].actionsMenu,
                }
              : state.targetLists.rows[rowIdx];
          }),
        },
      };

    case CUSTOMER_TARGETLIST_CHECK_DATATABLE_ROW:
      return {
        ...state,
        targetLists: {
          ...state.targetLists,
          rows: state.targetLists.rows.map((row, rowIdx) => {
            return rowIdx === action.payload
              ? {
                  ...state.targetLists.rows[rowIdx],
                  checked: !state.targetLists.rows[rowIdx].checked,
                }
              : state.targetLists.rows[rowIdx];
          }),
        },
      };

    case CUSTOMER_SMS_TARGET_LIST_CLEAR_ACTION_MENU:
      return {
        ...state,
        targetLists: {
          ...state.targetLists,
          rows: state.targetLists.rows.map((row, idx) => {
            return idx === action.payload
              ? { ...row, actionsMenu: false }
              : row;
          }),
        },
      };

    case CUSTOMER_SMS_TARGET_LIST_CHECK_ALL_TABLE_ROWS:
      return {
        ...state,
        targetLists: {
          ...state.targetLists,
          rows: state.targetLists.rows.map((row) => ({
            ...row,
            checked: action.payload,
          })),
        },
      };

    default:
      return state;
  }
}
