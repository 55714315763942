import EP from "consts/api";
import { shopifyApi } from "modules/api";

//EP Stand for END Point
export default class Products {
  static getProducts = (shopifySubdomain) =>
    shopifyApi("get", EP.PRODUCTS.getProducts()).set(
      "x-shopify-shop-domain",
      shopifySubdomain
    );

  static createSDLProduct = (data) =>
    shopifyApi("post", EP.PRODUCTS.createSDLProduct(), { products: data });

  static getNextProducts = (cursor, shopifySubdomain) =>
    shopifyApi("get", EP.PRODUCTS.getProductsAfterCursor(cursor)).set(
      "x-shopify-shop-domain",
      shopifySubdomain
    );

  static getProductsByName = (name, shopifySubdomain) =>
    shopifyApi("get", EP.PRODUCTS.getProductsByName(name)).set(
      "x-shopify-shop-domain",
      shopifySubdomain
    );
}
