import WhatsApp from "api/WhatsApp";
import { RESET_ERRORS } from "reducers/user";

const GET_WHATS_APP_TEMPLATES = "GET_WHATS_APP_TEMPLATES";
const GET_WHATS_APP_PHONE_NUMBERS = "GET_WHATS_APP_PHONE_NUMBERS";
const GET_WHATS_APP_BUSSINESS_ACCOUNT_IDS =
  "GET_WHATS_APP_BUSSINESS_ACCOUNT_IDS";

//  ------------------------------------
// Actions
// ------------------------------------

export function getWhatsAppTemplates(suitId) {
  return {
    type: GET_WHATS_APP_TEMPLATES,
    payload: WhatsApp.getWhatsAppTemplates(suitId),
  };
}

export function getWhatsAppPhoneNumbers(suitId, wabaId) {
  return {
    type: GET_WHATS_APP_PHONE_NUMBERS,
    payload: WhatsApp.getWhatsAppPhoneNumbers(suitId, wabaId),
  };
}

export function getWhatsAppBussinessAccountIds(suitId, businessId) {
  return {
    type: GET_WHATS_APP_BUSSINESS_ACCOUNT_IDS,
    payload: WhatsApp.getWhatsAppBussinessAccountIds(suitId, businessId),
  };
}

// ------------------------------------
// Initial State
// ------------------------------------

const initialState = {
  isPending: false,
  error: false,

  whatsAppTemplates: [], // the approved templates only
  whatsAppPhoneNumbers: [],
  whatsAppBussinessAccountIds: [],
};

// ------------------------------------
// Reducer
// ------------------------------------

export default function WhatsAppReducer(state = initialState, action) {
  switch (action.type) {
    case RESET_ERRORS:
      return {
        ...state,
        error: false,
      };
    case `${GET_WHATS_APP_TEMPLATES}_PENDING`:
    case `${GET_WHATS_APP_PHONE_NUMBERS}_PENDING`:
    case `${GET_WHATS_APP_BUSSINESS_ACCOUNT_IDS}_PENDING`:
      return {
        ...state,
        isPending: true,
      };

    case `${GET_WHATS_APP_TEMPLATES}_REJECTED`:
    case `${GET_WHATS_APP_PHONE_NUMBERS}_REJECTED`:
    case `${GET_WHATS_APP_BUSSINESS_ACCOUNT_IDS}_REJECTED`:
      return {
        ...state,
        isPending: false,
        error: action.payload,
      };

    case `${GET_WHATS_APP_TEMPLATES}_FULFILLED`:
      return {
        ...state,
        isPending: false,
        whatsAppTemplates: action.payload.body.templates.filter(
          (template) => template.status === "approved"
        ),
      };
    case `${GET_WHATS_APP_PHONE_NUMBERS}_FULFILLED`:
      return {
        ...state,
        isPending: false,
        whatsAppPhoneNumbers: action.payload.body.Numbers,
      };
    case `${GET_WHATS_APP_BUSSINESS_ACCOUNT_IDS}_FULFILLED`:
      return {
        ...state,
        isPending: false,
        whatsAppBussinessAccountIds: action.payload.body.waba,
      };
    default:
      return state;
  }
}
