import {
  Checkbox,
  ControlLabel,
  FormControl,
  FormGroup,
  Modal,
  PrimaryButton,
  SecondaryButton,
} from "components";
import { deepLinkConfig } from "consts/deeplinkBuilder";
import validConsts from "consts/validations";
import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import { getCurrentSuit } from "reducers/user";

const validationDeeplinkEmail = validConsts.regex.validationDeeplinkEmail;
let flag = false;
var counter = 0;
let numval;
let messaval;

class DeepLinkBuilder extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      selected: "select",
      selectedDeepLink: undefined,
      fields: {},
      phone: false,
      message: false,
      noStripeKey: false,
      condition: false,
    };
  }

  componentDidMount() {
    const {
      getCurrentSuit,
      user: { activeSuitId },
    } = this.props;

    if (_.get(this.props, "user.suit")) {
      return;
    }
    getCurrentSuit(activeSuitId);
  }

  handleHide = () => {
    this.setState({ showModal: false });
    flag = false;
  };

  handleShow = () => {
    this.setState({ showModal: true });
    flag = false;
  };

  handleSelect = (e) => {
    this.setState({
      selected: e.target.value,
      fields: {},
      phone: false,
      message: false,
      noStripeKey: false,
    });
    numval = false;
    messaval = false;
    flag = false;
  };

  handleFieldChange = (val, name, select) => {
    this.setState((prevState) => ({
      fields: { ...prevState.fields, [name]: val },
    }));

    if (counter != 1) {
      flag = false;
    } else {
      if (val == null) {
        this.setState({ noStripeKey: true });
        flag = false;
      }
    }

    switch (select) {
      case "sms": {
        if (name === "phone") {
          numval = !isNaN(val);
        }
        if (name === "message") {
          messaval = val;
        }
        flag = numval && messaval;
        break;
      }
      case "email": {
        if (validationDeeplinkEmail.test(val)) {
          flag = true;
        } else {
          flag = false;
        }
        break;
      }
      case "whatsApp": {
        if (name === "phone" && val) {
          flag = !isNaN(val);
        }
        break;
      }
      case "phone_call": {
        if (name === "dial" && val) {
          flag = !isNaN(val);
        }
        break;
      }
      default: {
        if (val) {
          flag = true;
        }
      }
    }
  };

  validate = () => {
    const { fields, selected } = this.state;
    return (
      selected !== "select" &&
      deepLinkConfig
        .find((option) => option.name === selected)
        .fields.every((field) => fields[field.name])
    );
  };

  handleSubmit = () => {
    const { callBack, type } = this.props;
    const { fields, selected } = this.state;
    const { handler, customHandlers } = deepLinkConfig.find(
      (option) => option.name === selected
    );

    callBack(
      (type &&
        customHandlers &&
        customHandlers[type] &&
        customHandlers[type](fields)) ||
        handler(fields)
    );

    this.handleHide();
  };

  stipepayfunc(selected, suit) {
    if (selected === "Stripe Pay") {
      this.handleFieldChange(suit.stripe_key, "stipekey", selected);
      this.handleFieldChange(suit.name, "projectname", selected);
      this.handleFieldChange(suit.id, "suitid", selected);
      this.handleFieldChange(suit.subdomain, "subdomain", selected);
    }
  }

  render() {
    const { showModal, selected, noStripeKey } = this.state;
    const {
      type,
      notIn,
      user: { activeSuitId, suit },
      nomail,
      noDeeplink,
      onlymdp,
    } = this.props;

    const subdomain = suit?.subdomain;

    if (suit && !suit.stripe_key && !noStripeKey) {
      this.setState({ noStripeKey: true });
    }

    if (suit.type === "mobile_marketing") {
      for (let i = 0; i < deepLinkConfig.length; i++) {
        if (
          deepLinkConfig[i].name === "google app store" ||
          deepLinkConfig[i].name === "apple app store"
        ) {
          deepLinkConfig.splice(i, 1);
        }
      }
    }

    return (
      <div>
        <PrimaryButton
          onClick={this.handleShow}
          value={I18n.t("Action Selector")}
        />
        <Modal show={showModal} onHide={this.handleHide}>
          <Modal.Body>
            <h4>{I18n.t("Action Selector")}</h4>
            <FormGroup>
              <ControlLabel>{I18n.t("Select Action")}</ControlLabel>
              <FormControl componentClass="select" onChange={this.handleSelect}>
                <option value="select">{I18n.t("select")}</option>
                {deepLinkConfig.map((option) =>
                  type === "web" && option.mobileOnly ? null : (notIn ===
                      true &&
                      option.smartLink) ||
                    (nomail === true && option.nomail) ||
                    (noDeeplink === true &&
                      option.noDeeplink) ? null : suit.type ===
                      "mobile_marketing" &&
                    option.name === "DeepLinks" ? null : onlymdp &&
                    option.mdponly ? null : (
                    <option key={option.name} value={option.name}>
                      {option.label}
                    </option>
                  )
                )}
              </FormControl>
            </FormGroup>
            <div>
              {selected !== "select" &&
                selected !== "apple app store" &&
                selected !== "google app store" &&
                deepLinkConfig
                  .find((option) => option.name === selected)
                  .fields.map((field, index) =>
                    selected !== "Lead Form" ? (
                      <FormGroup
                        key={index}
                        onChange={() => {
                          this.stipepayfunc(selected, suit);
                        }}
                      >
                        <ControlLabel> {field.label}</ControlLabel>
                        {selected === "Stripe Pay" ? (
                          <FormControl
                            min="1"
                            type="number"
                            onChange={(e) => {
                              this.handleFieldChange(
                                e.target.value,
                                field.name,
                                selected
                              );
                            }}
                            placeholder={field.label}
                          />
                        ) : (
                          <FormControl
                            onChange={(e) => {
                              this.handleFieldChange(
                                e.target.value,
                                field.name,
                                selected
                              );
                            }}
                            placeholder={field.label}
                          />
                        )}
                        {selected === "Stripe Pay" && (
                          <div>
                            <br />
                            <strong>Currency</strong>
                            <FormControl
                              componentClass="select"
                              onChange={(e) => {
                                this.handleFieldChange(
                                  e.target.value,
                                  "currency",
                                  selected
                                );
                              }}
                            >
                              <option key={1} value={"USD"}>
                                {I18n.t("USD")}
                              </option>
                              <option key={2} value={"AED"}>
                                {I18n.t("AED")}
                              </option>
                            </FormControl>
                          </div>
                        )}
                      </FormGroup>
                    ) : (
                      <FormGroup
                        key={index}
                        onChange={() => {
                          this.handleFieldChange(
                            activeSuitId,
                            "suitid",
                            selected
                          );
                          this.handleFieldChange(
                            subdomain,
                            "subdomain",
                            selected
                          );
                        }}
                      >
                        {index === 0 && (
                          <ControlLabel>Select Lead form Item</ControlLabel>
                        )}
                        {field.name === "name" && (
                          <Checkbox
                            value="name"
                            onChange={(e) => {
                              e.target.checked
                                ? this.handleFieldChange(
                                    e.target.value,
                                    field.name,
                                    selected
                                  )
                                : this.handleFieldChange(
                                    false,
                                    field.name,
                                    selected
                                  );
                            }}
                          >
                            {I18n.t("Name")}
                          </Checkbox>
                        )}
                        {field.name === "email" && (
                          <Checkbox
                            value="email"
                            onChange={(e) => {
                              e.target.checked
                                ? this.handleFieldChange(
                                    e.target.value,
                                    field.name,
                                    selected
                                  )
                                : this.handleFieldChange(
                                    false,
                                    field.name,
                                    selected
                                  );
                            }}
                          >
                            {I18n.t("Email")}
                          </Checkbox>
                        )}
                        {field.name === "address" && (
                          <Checkbox
                            value="address"
                            onChange={(e) => {
                              e.target.checked
                                ? this.handleFieldChange(
                                    e.target.value,
                                    field.name,
                                    selected
                                  )
                                : this.handleFieldChange(
                                    false,
                                    field.name,
                                    selected
                                  );
                            }}
                          >
                            {I18n.t("Address")}
                          </Checkbox>
                        )}
                        {field.name === "age" && (
                          <Checkbox
                            value="age"
                            onChange={(e) => {
                              e.target.checked
                                ? this.handleFieldChange(
                                    e.target.value,
                                    field.name,
                                    selected
                                  )
                                : this.handleFieldChange(
                                    false,
                                    field.name,
                                    selected
                                  );
                            }}
                          >
                            {I18n.t("Age")}
                          </Checkbox>
                        )}
                      </FormGroup>
                    )
                  )}
              {selected !== "select" &&
                selected !== "apple app store" &&
                selected == "google app store" &&
                deepLinkConfig
                  .find((option) => option.name === selected)
                  .fields.map((field, index) => (
                    <FormGroup key={index}>
                      <FormControl
                        componentClass="select"
                        onChange={(e) => {
                          this.handleFieldChange(
                            e.target.value || false,
                            field.name,
                            selected
                          );
                        }}
                      >
                        <option value={false}>{I18n.t("Select")}</option>
                        {suit.google_play_id && (
                          <option value={suit.google_play_id || false}>
                            {suit.google_play_id}
                          </option>
                        )}
                      </FormControl>
                    </FormGroup>
                  ))}

              {selected !== "select" &&
                selected !== "google app store" &&
                selected == "apple app store" &&
                deepLinkConfig
                  .find((option) => option.name === selected)
                  .fields.map((field, index) => (
                    <FormGroup key={index}>
                      <FormControl
                        componentClass="select"
                        onChange={(e) => {
                          this.handleFieldChange(
                            e.target.value || false,
                            field.name,
                            selected
                          );
                        }}
                      >
                        <option value={false}>{I18n.t("Select")}</option>
                        {suit.apple_app_id && (
                          <option value={suit.apple_app_id || false}>
                            {suit.apple_app_id}
                          </option>
                        )}
                      </FormControl>
                    </FormGroup>
                  ))}
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                gap: "12px",
              }}
            >
              <SecondaryButton
                onClick={this.handleHide}
                value={I18n.t("Cancel")}
              />
              <PrimaryButton
                disabled={!flag}
                onClick={this.handleSubmit}
                value={I18n.t("Submit")}
              />
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
export default connect(
  (store) => ({
    user: store.user,
    smartlink: store.smartlink,
  }),
  {
    getCurrentSuit,
  }
)(DeepLinkBuilder);
