import Templates from "api/Templates";
import {
  CURRENCY,
  DATE_TYPE,
  NUMBER,
  STRING,
  TEMPLATE_MOBILE,
} from "consts/TableColumnTypes";
import _ from "lodash";
import moment from "moment";
import { RESET_ERRORS } from "reducers/user";

// ------------------------------------
// Constants
// ------------------------------------
export const GET_ALL_TEMPLATES = "GET_ALL_TEMPLATES";
export const DELETE_TEMPLATE = "DELETE_TEMPLATE";
export const EDIT_TEMPLATE = "EDIT_TEMPLATE";
export const CREATE_TEMPLATE = "CREATE_TEMPLATE";
export const GET_CURRENT_TEMPLATE = "GET_CURRENT_TEMPLATE";
export const REMOVE_CURRENT_TEMPLATE = "REMOVE_CURRENT_TEMPLATE";
//
export const TEMPLATE_TOGGLE_ROW_ACTION_MENU =
  "TEMPLATE_TOGGLE_ROW_ACTION_MENU";
export const CUSTOMER_TEMPLATE_CHECK_DATATABLE_ROW =
  "CUSTOMER_TEMPLATE_CHECK_DATATABLE_ROW";
export const CUSTOMER_TEMPLATE_COLUMN_TOGGLE =
  "CUSTOMER_TEMPLATE_COLUMN_TOGGLE";
export const CUSTOMER_TEMPLATE_RESET_COLUMNS =
  "CUSTOMER_TEMPLATE_RESET_COLUMNS";
export const SORT_CUSTOMER_TEMPLATE = "SORT_CUSTOMER_TEMPLATE";
export const CUSTOMER_TEMPLATE_CHECK_ALL_TABLE_ROWS =
  "CUSTOMER_TEMPLATE_CHECK_ALL_TABLE_ROWS";
export const CUSTOMER_TEMPLATE_CLEAR_ACTION_MENU =
  "CUSTOMER_TEMPLATE_CLEAR_ACTION_MENU";

// ------------------------------------
// Actions
// ------------------------------------

export function getSuitTemplates(suitId, type) {
  return {
    type: GET_ALL_TEMPLATES,
    payload: Templates.getSuitTemplates(suitId, type),
  };
}

export function deleteTemplate(suitId, templateId) {
  return {
    type: DELETE_TEMPLATE,
    payload: Templates.deleteTemplate(suitId, templateId),
  };
}
export function getCurrentTemplate(suitId, templateId) {
  return {
    type: GET_CURRENT_TEMPLATE,
    payload: Templates.getCurrentTemplate(suitId, templateId),
  };
}
export function editTemplate(suitId, templateId, data) {
  return {
    type: EDIT_TEMPLATE,
    payload: Templates.editTemplate(suitId, templateId, data),
  };
}
export function createTemplate(suitId, templateId, data) {
  return {
    type: CREATE_TEMPLATE,
    payload: Templates.createTemplate(suitId, templateId, data),
  };
}
export function removeCurrentTemplate() {
  return {
    type: REMOVE_CURRENT_TEMPLATE,
  };
}
//
// /////////////////

export function toggleRowActionMenu(index) {
  return {
    type: TEMPLATE_TOGGLE_ROW_ACTION_MENU,
    payload: index,
  };
}

export function checkDataTableRow(idx) {
  return {
    type: CUSTOMER_TEMPLATE_CHECK_DATATABLE_ROW,
    payload: idx,
  };
}

export function onColumnToggle(idx) {
  return {
    type: CUSTOMER_TEMPLATE_COLUMN_TOGGLE,
    payload: idx,
  };
}

export function onResetColumns() {
  return {
    type: CUSTOMER_TEMPLATE_RESET_COLUMNS,
  };
}

export const sortTable = (name, type) => async (dispatch) => {
  dispatch({
    type: SORT_CUSTOMER_TEMPLATE,
    payload: {
      type,
      name: name.split("_1")[0],
      descending: name.includes("_1"),
    },
  });
};

export function checkAllTableRows(newChecked) {
  return {
    type: CUSTOMER_TEMPLATE_CHECK_ALL_TABLE_ROWS,
    payload: newChecked,
  };
}
export function clearActionMenu(idx) {
  return {
    type: CUSTOMER_TEMPLATE_CLEAR_ACTION_MENU,
    payload: idx,
  };
}

function prepareTableDate(tableData) {
  const dateFormat = "DD MMM YY HH:mm:ss";

  const labels = [
    {
      name: "Name",
      has_sort: false,
      type: STRING,
      selected: true,
      varName: ["template_name"],
    },
    {
      name: "CONTENT",
      has_sort: false,
      type: TEMPLATE_MOBILE,
      selected: true,
      varName: ["content"],
    },
    {
      name: "DATE CREATED",
      has_sort: true,
      type: DATE_TYPE,
      selected: true,
      varName: ["create_at"],
    },
    {
      name: "CHANNELS",
      has_sort: false,
      type: STRING,
      selected: true,
      varName: ["type"],
    },
  ];

  const rows = tableData.map((row) => ({
    checked: false,
    actionsMenu: false,
    id: row.template_id,

    values: [
      _.get(row, labels[0].varName, labels[0].defaultValue),
      _.get(row, labels[1].varName, labels[1].defaultValue),
      moment(moment.utc(_.get(row, labels[2].varName, labels[2].defaultValue)))
        .local()
        .format(dateFormat),
      _.get(row, labels[3].varName, labels[3].defaultValue),
    ],
  }));

  let byDefaultSortedRows = _.sortBy(rows, (row) =>
    new Date(row.values[2]).getTime()
  );
  _.reverse(byDefaultSortedRows);

  return { labels, rows: byDefaultSortedRows, actions: true };
}

// ------------------------------------
// Initial State
// ------------------------------------
const initialState = {
  Templates: null,
  TemplatesObjects: null,
  template: null,
  statistics: null,
  analytics: null,
  isPending: false,
  error: false,
};
// ------------------------------------
// Reducer
// ------------------------------------
export default function templatesReducer(state = initialState, action) {
  switch (action.type) {
    case RESET_ERRORS:
      return {
        ...state,
        error: false,
      };

    case `${GET_ALL_TEMPLATES}_PENDING`:
    case `${DELETE_TEMPLATE}_PENDING`:
    case `${EDIT_TEMPLATE}_PENDING`:
    case `${CREATE_TEMPLATE}_PENDING`:
    case `${GET_CURRENT_TEMPLATE}_PENDING`:
      return {
        ...state,
        isPending: true,
        error: false,
      };
    case `${GET_ALL_TEMPLATES}_REJECTED`:
    case `${DELETE_TEMPLATE}_REJECTED`:
    case `${EDIT_TEMPLATE}_REJECTED`:
    case `${CREATE_TEMPLATE}_REJECTED`:
    case `${GET_CURRENT_TEMPLATE}_REJECTED`:
      return {
        ...state,
        isPending: false,
        error: action.payload,
      };
    case `${GET_ALL_TEMPLATES}_FULFILLED`:
      return {
        ...state,
        isPending: false,
        Templates: prepareTableDate(action.payload.body.Templates),
        TemplatesObjects: action.payload.body.Templates,
      };
    case `${GET_CURRENT_TEMPLATE}_FULFILLED`:
      return {
        ...state,
        isPending: false,
        template: action.payload.body,
      };
    case `${DELETE_TEMPLATE}_FULFILLED`:
    case `${EDIT_TEMPLATE}_FULFILLED`:
    case `${CREATE_TEMPLATE}_FULFILLED`:
      return {
        ...state,
        isPending: false,
      };
    case REMOVE_CURRENT_TEMPLATE:
      return {
        ...state,
        template: null,
      };
    //
    case TEMPLATE_TOGGLE_ROW_ACTION_MENU:
      return {
        ...state,
        Templates: {
          ...state.Templates,
          rows: state.Templates.rows.map((row, rowIdx) => {
            return rowIdx === action.payload
              ? {
                  ...state.Templates.rows[rowIdx],
                  actionsMenu: !state.Templates.rows[rowIdx].actionsMenu,
                }
              : state.Templates.rows[rowIdx];
          }),
        },
      };

    case CUSTOMER_TEMPLATE_CHECK_DATATABLE_ROW:
      return {
        ...state,
        Templates: {
          ...state.Templates,
          rows: state.Templates.rows.map((row, rowIdx) => {
            return rowIdx === action.payload
              ? {
                  ...state.Templates.rows[rowIdx],
                  checked: !state.Templates.rows[rowIdx].checked,
                }
              : state.Templates.rows[rowIdx];
          }),
        },
      };

    case CUSTOMER_TEMPLATE_COLUMN_TOGGLE:
      return {
        ...state,
        Templates: {
          ...state.Templates,
          labels: state.Templates.labels.map((label, idx) => {
            return idx === action.payload
              ? {
                  ...label,
                  selected: !label.selected,
                }
              : label;
          }),
        },
      };

    case CUSTOMER_TEMPLATE_RESET_COLUMNS:
      return {
        ...state,
        Templates: {
          ...state.Templates,
          labels: state.Templates.labels.map((label) => ({
            ...label,
            selected: true,
          })),
        },
      };

    case SORT_CUSTOMER_TEMPLATE: {
      const indexOfKey = state.Templates.labels
        .map((label) => label.name)
        .indexOf(action.payload.name);

      let tableRows = null;

      switch (action.payload.type) {
        case NUMBER:
          tableRows = _.sortBy(
            state.Templates.rows,
            (row) => row.values[indexOfKey]
          );
          break;
        case STRING:
          tableRows = _.sortBy(state.Templates.rows, (row) =>
            row.values[indexOfKey].toLowerCase()
          );
          break;
        case CURRENCY:
          tableRows = _.sortBy(state.Templates.rows, (row) =>
            parseFloat(row.values[indexOfKey].split(" ")[0])
          );
          break;
        case DATE_TYPE:
          tableRows = _.sortBy(state.Templates.rows, (row) =>
            new Date(row.values[indexOfKey]).getTime()
          );
          break;
      }
      if (action.payload.descending) {
        _.reverse(tableRows);
      }
      return {
        ...state,
        Templates: {
          ...state.Templates,
          rows: tableRows,
        },
      };
    }

    case CUSTOMER_TEMPLATE_CHECK_ALL_TABLE_ROWS:
      return {
        ...state,
        Templates: {
          ...state.Templates,
          rows: state.Templates.rows.map((row) => ({
            ...row,
            checked: action.payload,
          })),
        },
      };

    case CUSTOMER_TEMPLATE_CLEAR_ACTION_MENU:
      return {
        ...state,
        Templates: {
          ...state.Templates,
          rows: state.Templates.rows.map((row, idx) => {
            return idx === action.payload
              ? { ...row, actionsMenu: false }
              : row;
          }),
        },
      };

    default:
      return state;
  }
}
