import _ from "underscore";
import { getSubdomainFromId } from "modules/user";

export function getSuitFromPath(path, suits) {
  const pathArr = path.split("/");
  const appIndex = pathArr.indexOf("app") + 1;
  if (appIndex && suits) {
    const currSuit = suits.find((suit) => suit.subdomain === pathArr[appIndex]);
    return currSuit ? currSuit.id : false;
  } else {
    return false;
  }
}

export function formatUrl(path) {
  const pathArr = path.split("/");
  const appIndex = pathArr.indexOf("app") + 1;
  if (appIndex) {
    pathArr.splice(appIndex, 1);
  }
  return pathArr.join("/");
}

export function addAppToUrl(path, suits, activeSuitId) {
  const pathArr = path.split("/");
  const appIndex = pathArr.indexOf("app") + 1;
  if (activeSuitId && suits && appIndex) {
    const subdomain = getSubdomainFromId(activeSuitId, suits);
    if (subdomain) {
      pathArr.splice(appIndex, 0, subdomain);
      return pathArr.join("/");
    } else {
      return path;
    }
  } else {
    return path;
  }
}

export function urlMatcher(node, url) {
  if (node.matcher && !!url.match(node.matcher)) {
    return true;
  }

  return node.url === url;
}

export function findActiveNodes(nodes, url) {
  const activeNodes = [];

  const nodeIterator = (nodes) => {
    let found = false;

    nodes.forEach((node) => {
      if (node.children && nodeIterator(node.children)) {
        activeNodes.push(node);
        found = true;
      } else if (node.url && urlMatcher(node, formatUrl(url))) {
        activeNodes.push(node);
        found = true;
      }
    });

    return found;
  };
  nodeIterator(nodes);
  return activeNodes;
}

export function findSectionBySlug(nodes, slugName) {
  // Returns flatten sections
  const getSections = function* (nodesTree) {
    for (let node of nodesTree) {
      yield node;

      if (node.children) {
        for (let section of getSections(node.children)) {
          yield section;
        }
      }
    }
  };

  const sections = Array.from(getSections(nodes));

  return _.findWhere(sections, { slug: slugName });
}
