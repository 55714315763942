import dayjs from "dayjs";
import "dayjs/locale/ar";
import { locale } from "./i18n";

const formatDate = (date) => {
  const currentLocale = locale();
  const isArabic = currentLocale === "ar";
  return isArabic
    ? formatDateToArabicDate(date)
    : dayjs(date).format("DD/MM/YYYY - h:mm:ss");
};

const formatDateToArabicDate = (date) => {
  const englishDate = dayjs(date).locale("ar").format("DD/MM/YYYY - h:mm:ss");
  const arabicNumbers = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
  const arabicDate = englishDate.replace(
    /\d/g,
    (d) => arabicNumbers[parseInt(d)] + "\u200E"
  );
  return arabicDate;
};

const convertNumbersToArabic = (str) => {
  const arabicNumbers = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
  return str.replace(/[0-9]/g, (w) => arabicNumbers[+w]);
};

export { convertNumbersToArabic, formatDate, formatDateToArabicDate };
