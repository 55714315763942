import EP, { config } from "consts/api";
import _ from "lodash";
import api from "modules/api";
import { userData } from "modules/user";

export default class Select {
  static getPlans = () => (userType) => {
    const user = userData();
    // MODIFICATION HERE TO REPLACE PROJECT_TYPE TO OFFER_VALUES BECAUSE OF APPSUMO
    return api(
      "get",
      EP.PLANS.ALL(),
      false,
      _.pickBy({
        status: "active",
        offers_values: "Self Register",
        user_type: userType,
        project_type: /*type === 'mobile_app' ? null :*/ "mobile_marketing",
      })
    ).set("authToken", user.auth_token);
  };

  static getCreaditCard = (stripeCustomerId) => {
    return api("get", EP.STRIPE.StripeInfo(stripeCustomerId)).set(
      "Authorization",
      config.StripSecretKey
    );
  };

  static getMobileMarketingPlans = () =>
    api("get", EP.PLANS.ALL(), false, {
      status: "active",
      offers_values: "mobile_marketing",
      user_type: "self",
      project_type: "mobile_marketing",
    });
}
