import EP from "consts/api";
import api from "modules/api";

export default class SmartLinks {
  static getSuitSmartLinks = (suitId, data, page) =>
    api("get", EP.SMARTLINKS.getSuitSmartlinks(suitId, data, page));
  static topShortLinks = (suitId) =>
    api("get", EP.SMARTLINKS.topShortLinks(suitId));

  // search
  static getSuitSmartLinksBySearch = (suitId, nameVal) =>
    api("get", EP.SMARTLINKS.SEARCHSMARTLINKS(suitId, nameVal));

  static getCurrentSmartLink = (suitId, smartLinkId) =>
    api("get", EP.SMARTLINKS.SINGLE(suitId, smartLinkId));

  static createSmartLink = (suitId, data) =>
    api("post", EP.SMARTLINKS.ALL(suitId), data);

  static updateSmartLink = (suitId, smartLinkId, data) =>
    api("put", EP.SMARTLINKS.SINGLE(suitId, smartLinkId), data);

  static deleteSmartLink = (suitId, smartLinkId) =>
    api("delete", EP.SMARTLINKS.SINGLE(suitId, smartLinkId));

  static getSuitSmartLinksStatistics = (suitId, data) =>
    api("get", EP.SMARTLINKS.STASTICS(suitId), false, data);

  static getSmartLinkAnalytics = (suitId, smartLinkId, data) =>
    api("get", EP.SMARTLINKS.ANALYTICS(suitId, smartLinkId), false, data);

  static getSmartLinkChartData = (suitId, smartLinkId, filter) =>
    api("get", EP.SMARTLINKS.CHART_DATA(suitId, smartLinkId, filter));

  static getSmartLinkHitsLogs = (suitId, smartLinkId) =>
    api("get", EP.SMARTLINKS.HITSLOGS(suitId, smartLinkId));
  static uploadBulkSDL = (suitId, data, email) =>
    api("post", EP.SMARTLINKS.UPLOAD(suitId, email), data);

  static deleteSmartLinkBulk = (suitId, smartLinksIds) =>
    api("delete", EP.SMARTLINKS.BULK(suitId), smartLinksIds);
}
