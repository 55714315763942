import { Icon } from "antd";
import {
  AvatarImage,
  Grid,
  Header,
  LanguageSwitcher,
  Layout,
  MenuItem,
  Nav,
  NavDropdown,
  NavItem,
  Navbar,
  OutsideClick,
  Sidebar,
} from "components";
import PrimaryButton from "components/Form/Buttons/PrimaryButton/PrimaryButton";
import { config } from "consts/api";
import { locale } from "modules/i18n.js";
import { findActiveNodes, getSuitFromPath } from "modules/router";
import {
  getSuitBySubdomain,
  isSubUser,
  showNotAuthorizedMsg,
} from "modules/user";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import { Link } from "react-router";
import { LinkContainer } from "react-router-bootstrap";
import { refreshSidebar } from "reducers/sidebar";
import {
  generateAPIKey,
  getCurrentSuit,
  getUserSuits,
  handleLogout,
  removeCurrentSuit,
  resetErrors,
  setActiveSuitId,
} from "reducers/user";
import appgain from "static/appgain.svg";
import logoMain from "static/logos/shrink-it-logo.png";
import userIcon from "static/user.svg";
import { clearLocalStore } from "store/localStore";
import _ from "underscore";
import HeadwayWidget from "../../components/Headway/HeadwayWidget.js";
import { Toast } from "../../modules/toast.js";
import { upgrade } from "../../modules/upgrade.js";
import OnBoardingModal from "../../routes/Home/OnBoardingModal/OnBoardingModal";
import classes from "./DefaultLayout.scss";
import Footer from "./Footer.js";
import {
  CONTENT_VIEW_STATIC,
  SCREEN_SIZE_XS,
  SIDEBAR_STYLE_DEFAULT,
  SIDEBAR_STYLE_SLIM,
  changeSidebarAddOn,
  setCurrentScreenSize,
  setSidebarStyle,
  toggleNavbarExpanded,
  toggleOverlaySidebarOpen,
  toggleRightSidebar,
} from "./modules/layout.js";

const titleBase = "SHRINKIT - ";
let sidebarTriggerRef, navbarTriggerRef;
let expired = true;

class DefaultLayout extends React.Component {
  static propTypes = {
    sidebarAddon: PropTypes.string,
  };

  constructor(props) {
    super(props);

    const {
      user: { activeSuitId },
      getCurrentSuit,
      params: { subdomain },
    } = this.props;

    if (subdomain) {
      if (activeSuitId) getCurrentSuit(activeSuitId);
    }

    this.state = {
      show: false,
      displayDelete: false,
      displayCreate: false,
      logoVisible: "none",
      customClasses: "",
    };

    this.beforeSlimSidebarStyle = SIDEBAR_STYLE_DEFAULT;
    this.bodyElement = document.querySelector("body");
  }

  componentWillMount() {
    const { setSidebarStyle } = this.props;

    this.refreshSidebarMenu(this.props);
    setSidebarStyle(SIDEBAR_STYLE_DEFAULT);
  }

  toggleSidebarSlim() {
    if (this.state.logoVisible === "initial") {
      this.setState({ logoVisible: "none" });
    } else {
      this.setState({ logoVisible: "initial" });
    }
    const { sidebarStyle, setSidebarStyle } = this.props;
    if (sidebarStyle === SIDEBAR_STYLE_SLIM) {
      setSidebarStyle(this.beforeSlimSidebarStyle);
    } else {
      this.beforeSlimSidebarStyle = sidebarStyle;
      setSidebarStyle(SIDEBAR_STYLE_SLIM);
    }
  }

  async componentDidMount() {
    const { setSidebarStyle, getUserSuits } = this.props;
    await getUserSuits();
    setSidebarStyle(SIDEBAR_STYLE_DEFAULT);
  }

  static contextTypes = {
    store: PropTypes.object,
    router: PropTypes.object.isRequired,
  };

  shouldStaticLogoAppear = () => {
    switch (window.location.pathname.toLowerCase()) {
      case "/user/sub-users":
      case "/stores":
      case "/":
        return true;
      default:
        return false;
    }
  };

  componentWillReceiveProps(nextProps) {
    const {
      user: { shouldLogOut },
      error,
    } = nextProps;
    if (
      error &&
      error.response &&
      error.response.body &&
      error.response.body.message === "Session expired. Please log in again."
    ) {
      this.handleError(error);
      expired = false;
    } else if (
      error &&
      error.response &&
      error.response.body &&
      error.response.body.message !== "Session expired. Please log in again."
    ) {
      expired = true;
      this.handleError(error);
    }
    if (shouldLogOut) {
      this.handleLogout();
    }

    this.refreshSidebarMenu(nextProps);
    const activeRoute = _.first(
      findActiveNodes(nextProps.sidebar.items, nextProps.location.pathname)
    );
    if (activeRoute) {
      switch (activeRoute.dataSent) {
        case "top-logo": {
          this.setState({ customClasses: "onlyNavBar" });
          break;
        }
        default: {
          this.setState({ customClasses: "" });
        }
      }
    } else {
      this.setState({ customClasses: "" });
    }
  }

  handleError(error) {
    const { resetErrors } = this.props;
    if (
      error &&
      error.response &&
      error.response.body &&
      error.response.body.message &&
      expired
    ) {
      Toast.error(error.response.body.message);
      resetErrors();
    }
  }

  refreshSidebarMenu(nextProps) {
    const {
      sidebar: { suitId },
      params,
      user: { suits, activeSuitId },
    } = nextProps;
    const idFromPath = getSuitFromPath(nextProps.location.pathname, suits);
    const currentId = idFromPath || activeSuitId;
    if (idFromPath && idFromPath !== activeSuitId) {
      this.props.setActiveSuitId(idFromPath);
    }
    if (
      params &&
      params.subdomain &&
      currentId &&
      (!suitId || currentId !== suitId)
    ) {
      this.props.refreshSidebar({ suits, currentId });
    }
  }

  handleLogout = () => {
    this.props.handleLogout();
    this.props.removeCurrentSuit();
    this.context.router.push("/auth/login");
    document.title = "Shrinkit-Login";
    clearLocalStore();
  };

  componentDidUpdate() {
    // Set Overflow: Hidden on body when overlay mode is enabled

    this.bodyElement.style.overflow =
      this.props.currentScreenSize === SCREEN_SIZE_XS &&
      (this.props.navbarExpanded ||
        this.props.overlaySidebarOpen ||
        this.props.rightSidebarEnabled)
        ? "hidden"
        : "auto";

    // Update page title
    const activeRoute = _.first(
      findActiveNodes(this.props.sidebar.items, this.props.location.pathname)
    );
    if (activeRoute) {
      document.title = titleBase + activeRoute.title;
    }
  }

  generatAPI() {
    const {
      generateAPIKey,
      user: { suit, activeSuitId },
      getCurrentSuit,
    } = this.props;
    let data = {
      suit_name: suit.name,
      reset_fields: ["appApiKey"],
    };
    generateAPIKey(data, suit.id).then(() => {
      if (activeSuitId) getCurrentSuit(activeSuitId);
    });
  }

  renderUpgradeButton = () => {
    const {
      user: { suits, activeSuitId },
      params: { subdomain },
    } = this.props;

    const { router } = this.context;

    const currentSuit = suits?.find((suit) => suit.id === activeSuitId);
    const source = currentSuit?.source;

    const currentLocale = locale();

    const handleUpdateClick = () => {
      if (isSubUser()) return showNotAuthorizedMsg();

      upgrade(undefined, source, subdomain, router);
    };

    if (currentSuit?.upgradable) {
      return (
        <Nav
          pullRight={currentLocale !== "ar"}
          pullLeft={currentLocale === "ar"}
        >
          <NavItem className={classes.upgradeButtonContainer}>
            <PrimaryButton
              value={I18n.t("UpgradeBtn")}
              onClick={handleUpdateClick}
            />
          </NavItem>
        </Nav>
      );
    }
    return null;
  };

  renderTrialEnds = () => {
    const {
      user: { suit, suits, activeSuitId },
    } = this.props;

    const currentLocale = locale();

    const currentSuit = suit || suits?.find((suit) => suit.id === activeSuitId);
    const remainingDays = currentSuit?.subscription_remaining_days;
    const status = currentSuit?.subscription?.status?.toLowerCase();

    if (remainingDays && remainingDays !== "NA" && status === "trialing") {
      return (
        <Nav
          pullRight={currentLocale !== "ar"}
          pullLeft={currentLocale === "ar"}
          bsClass={classes.trailEndsContainer}
        >
          <NavItem>
            <p className={classes.trialEnds}>
              {I18n.t("trialHint")} {remainingDays}
            </p>
          </NavItem>
        </Nav>
      );
    }
    return null;
  };

  render() {
    const {
      user: { data, suit, suits, activeSuitId },
    } = this.props;
    if (config.PROD_MOD && data && data.user_id) {
      var dataLayer = (window.dataLayer = window.dataLayer || []);
      dataLayer.push({ userID: data.user_id });
    }

    const staticFootNavContainer =
      !this.props.sidebarEnabled &&
      this.props.contentView === CONTENT_VIEW_STATIC;

    const projectName =
      window.location.pathname.split("/").length > 2 &&
      window.location.pathname.split("/")[1].toLowerCase() === "app" &&
      getSuitBySubdomain(window.location.pathname.split("/")[2]) &&
      getSuitBySubdomain(window.location.pathname.split("/")[2]).name;

    const currentSuit = suit || suits?.find((suit) => suit.id === activeSuitId);
    const currentLocale = locale();
    const isArabic = currentLocale === "ar";
    const sources = ["Zid", "Shopify", "Salla"];
    const normalSuit = currentSuit
      ? !sources.includes(currentSuit.source)
      : false;

    const subdomainExist = Boolean(this.props?.params?.subdomain);

    return (
      <div className={this.state.customClasses}>
        <Layout
          className={currentLocale === "ar" ? "rtl" : null}
          {...this.props}
          screenSizeChanged={(newScreenSize) =>
            this.props.setCurrentScreenSize(newScreenSize)
          }
        >
          <Layout.Navigation className={currentLocale === "ar" ? "rtl" : null}>
            {window.location.pathname && this.shouldStaticLogoAppear() ? (
              <img
                className={classes["logo-main"]}
                onClick={() => this.context.router.push("/")}
                alt="logo-main"
                src="https://dypwje07rapgd.cloudfront.net/Shrinkit/img/logo-loader.gif"
              />
            ) : (
              <div />
            )}

            <Navbar
              fluid={!staticFootNavContainer}
              inverse
              componentClass="div"
              expanded={this.props.navbarExpanded}
              onToggle={() => {}}
            >
              <Navbar.Header>
                <Navbar.Brand>
                  <Link to="/">
                    <img src={logoMain} />
                  </Link>
                </Navbar.Brand>
                {/* Mobile Actions */}
                {this.props.currentScreenSize === SCREEN_SIZE_XS && (
                  <div className="pull-right">
                    {this.props.sidebarEnabled && (
                      <button
                        className="btn btn-outline navbar-toggle"
                        onClick={() =>
                          this.props.toggleOverlaySidebarOpen(
                            this.props.sidebarEnabled
                          )
                        }
                        ref={(ref) => {
                          sidebarTriggerRef = ref;
                        }}
                      >
                        <i className="fa fa-fw fa-bars text-white" />
                      </button>
                    )}
                  </div>
                )}
              </Navbar.Header>

              <OutsideClick
                onClickOutside={() => {
                  this.props.toggleNavbarExpanded(false);
                }}
                excludedElements={[navbarTriggerRef]}
              >
                <Navbar.Collapse>
                  {/* ============= Left Nav ============== */}
                  <Nav></Nav>
                  {/* ============= Right Nav ============== */}
                  <Navbar.Text className="visible-xs text-uppercase m-y-0">
                    {I18n.t("YourProfile")}
                  </Navbar.Text>
                  <div className={`user-nav-menu ${classes.account_btn_sty}`}>
                    <Nav
                      pullRight={currentLocale !== "ar"}
                      pullLeft={currentLocale === "ar"}
                    >
                      <NavDropdown
                        title={
                          <div className={classes.buttonUser}>
                            <span className="m-r-1 v-a-m">
                              {data && data.name}
                            </span>
                            <AvatarImage size="small" src={userIcon} />
                          </div>
                        }
                        className={classes.mobDropDown}
                        id="user-profile-dropdown"
                        eventKey={3}
                        noCaret
                      >
                        <LinkContainer to={"/user/profile"}>
                          <MenuItem eventKey={3.1}>
                            {I18n.t("Profile")}
                          </MenuItem>
                        </LinkContainer>
                        {!isSubUser() && (
                          <LinkContainer to={"/user/sub-users"}>
                            <MenuItem eventKey={3.5}>
                              {I18n.t("SubuserManagement")}
                            </MenuItem>
                          </LinkContainer>
                        )}

                        <LinkContainer to={"/user/authentication"}>
                          <MenuItem eventKey={3.2}>
                            {I18n.t("Authentication")}
                          </MenuItem>
                        </LinkContainer>
                        {data &&
                          data.user_type !== "enterprise" &&
                          normalSuit && (
                            <LinkContainer to={"/user/paymentmethods"}>
                              <MenuItem eventKey={3.3}>
                                {I18n.t("PaymentMethods")}
                              </MenuItem>
                            </LinkContainer>
                          )}
                        {data &&
                          data.user_type !== "enterprise" &&
                          normalSuit && (
                            <LinkContainer to={"/user/invoices"}>
                              <MenuItem eventKey={3.3}>
                                {I18n.t("Invoices")}
                              </MenuItem>
                            </LinkContainer>
                          )}
                        <MenuItem divider className={classes.dividerSty} />
                        <MenuItem eventKey={3.4} onClick={this.handleLogout}>
                          {I18n.t("Log Out")}
                        </MenuItem>
                      </NavDropdown>
                    </Nav>

                    <Nav
                      pullRight={currentLocale !== "ar"}
                      pullLeft={currentLocale === "ar"}
                    >
                      <NavItem>
                        <HeadwayWidget />
                      </NavItem>
                    </Nav>
                    <Nav
                      pullRight={currentLocale !== "ar"}
                      pullLeft={currentLocale === "ar"}
                    >
                      <NavItem>
                        <LanguageSwitcher />
                      </NavItem>
                    </Nav>

                    {activeSuitId && this.renderUpgradeButton()}
                    {activeSuitId && this.renderTrialEnds()}
                  </div>
                </Navbar.Collapse>
              </OutsideClick>
            </Navbar>
            <OutsideClick
              active={this.props.currentScreenSize === SCREEN_SIZE_XS}
              onClickOutside={() => {
                this.props.toggleOverlaySidebarOpen(false);
              }}
              excludedElements={[sidebarTriggerRef]}
            >
              <Sidebar
                className="p-b-3"
                affixOffset={0}
                overlay={this.props.currentScreenSize === SCREEN_SIZE_XS}
                overlayVisible={this.props.overlaySidebarOpen}
                header={
                  <div>
                    <a
                      href="javascript:void(0)"
                      className="sidebar-switch"
                      onClick={() => this.props.toggleOverlaySidebarOpen(false)}
                    >
                      <Icon type="close-circle" />
                    </a>
                  </div>
                }
              >
                <div className="sidebar-logo">
                  <i
                    onClick={() => this.toggleSidebarSlim()}
                    style={{ fontSize: "27px", cursor: "pointer" }}
                    className="fa fa-lg fa-bars"
                  />
                  <Link to="/">
                    <img
                      className="logo-default"
                      style={{
                        position: "relative",
                        top: "17px",
                        cursor: "pointer",
                      }}
                      width={140}
                      src="https://dypwje07rapgd.cloudfront.net/Shrinkit/img/logo-loader.gif"
                    />
                  </Link>

                  <h3
                    style={{
                      top: "26px",
                      fontSize: "20px",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                      position: "relative",
                      textAlign: "center",
                      borderColor: "#630bde",
                      borderRadius: "20px",
                      borderStyle: "solid",
                      borderWidth: "1px",
                      width: "90%",
                      marginLeft: "auto",
                      marginRight: "auto",
                      fontWeight: "700",
                    }}
                  >
                    {projectName}
                  </h3>
                </div>
                <button
                  style={{
                    direction: isArabic ? "rtl" : "ltr",
                  }}
                  onClick={() =>
                    this.context.router.push(
                      `/app/${this.props.params.subdomain}/newcampaigns`
                    )
                  }
                  className={classes.addCampaignBtn}
                >
                  {this.state.logoVisible === "initial"
                    ? ""
                    : I18n.t("newCampaignsBtn")}
                  <i className="fa fa-plus" />
                </button>
                <Sidebar.Menu currentUrl={this.props.location.pathname} />

                {!normalSuit && subdomainExist && (
                  <OnBoardingModal
                    logo={this.state.logoVisible}
                    isArabic={isArabic}
                  />
                )}

                {this.state.logoVisible === "initial" ? (
                  ""
                ) : (
                  <div className={classes.poweredby}>
                    <p>powered by</p>
                    <img src={appgain} alt="appgain" />
                  </div>
                )}
              </Sidebar>
            </OutsideClick>
          </Layout.Navigation>

          {
            // RawContent - displays the content directly without the header nor container
            this.props.rawContent ? (
              <Layout.Content>{this.props.children}</Layout.Content>
            ) : (
              <Layout.Content
                className={currentLocale === "ar" ? "rtl" : null}
                style={{
                  paddingTop: !this.props.headerEnabled ? "0px" : "0",
                }}
              >
                <Header
                  style={this.props.headerStyle}
                  fluid={this.props.contentView !== CONTENT_VIEW_STATIC}
                  currentUrl={this.props.location.pathname}
                />
                <Grid fluid={this.props.contentView !== CONTENT_VIEW_STATIC}>
                  {this.props.children}
                </Grid>
              </Layout.Content>
            )
          }
          {window.location.pathname.includes("auth") ? <div></div> : <Footer />}
        </Layout>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  navbarEnabled: state.layout.navbarEnabled,
  navbarFixed: state.layout.navbarFixed,
  navbarExpanded: state.layout.navbarExpanded,
  navbarSkin: state.layout.navbarSkin,
  sidebarEnabled: state.layout.sidebarEnabled,
  sidebarStyle: state.layout.sidebarStyle,
  sidebarFixed: state.layout.sidebarFixed,
  sidebarAside: state.layout.sidebarAside,
  sidebarAddon: state.layout.sidebarAddon,
  sidebarSkin: state.layout.sidebarSkin,
  overlaySidebarOpen: state.layout.overlaySidebarOpen,
  contentView: state.layout.contentView,
  footerEnabled: state.layout.footerEnabled,
  footerFixed: state.layout.footerFixed,
  rightSidebarEnabled: state.layout.rightSidebarEnabled,
  headerStyle: state.layout.headerStyle,
  headerEnabled: state.layout.headerEnabled,
  currentScreenSize: state.layout.currentScreenSize,
  skinColor: state.layout.skinColor,
  rawContent: state.layout.rawContent,

  sidebar: state.sidebar,
  user: state.user,
  events: state.events,
  error: [
    "user",
    "smartlink",
    "products",
    "MicroPages",
    "select",
    "config",
    "push",
    "appPush",
    "WhatsApp",
  ]
    .map((key) => state[key].error)
    .find((error) => error),
});
const mapActionCreators = {
  setSidebarStyle,
  toggleRightSidebar,
  toggleOverlaySidebarOpen,
  toggleNavbarExpanded,
  setCurrentScreenSize,
  changeSidebarAddOn,
  refreshSidebar,
  setActiveSuitId,
  handleLogout,
  resetErrors,
  removeCurrentSuit,
  generateAPIKey,
  getCurrentSuit,
  getUserSuits,
};

export default connect(mapStateToProps, mapActionCreators)(DefaultLayout);
