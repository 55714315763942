import createBrowserHistory from "history/lib/createBrowserHistory";
import pace from "pace-js";
import React from "react";
import ReactDOM from "react-dom";
import { useRouterHistory } from "react-router";
import AppContainer from "./containers/AppContainer";
import routes from "./routes/index";
import createStore from "./store/createStore";

export const browserHistory = useRouterHistory(createBrowserHistory)({
  // eslint-disable-next-line no-undef
  basename: __BASENAME__,
});

export const store = createStore();

const Routes = routes(store);

ReactDOM.render(
  <AppContainer store={store} history={browserHistory} routes={Routes} />,
  document.getElementById("root")
);

pace.start();
