import API_ENDPOINTS, { config } from "../consts/api";
import superAgentInstance from "../modules/api";
import { stripDashes } from "../modules/helpers";

export default class CRMEndpoints {
  static getCrmUsers(subdomain, page, pageSize) {
    return superAgentInstance("POST", API_ENDPOINTS.CRM.getAllCrmUsers(), {
      subdomain: stripDashes(subdomain),
      page,
      pageSize,
    }).set("x-parse-application-id", config.coreAppId);
  }

  static getCrmUsersByFilter(subdomain, page, pageSize, match) {
    return superAgentInstance(
      "POST",
      API_ENDPOINTS.CRM.getAllCrmUsersByFilter(),
      {
        subdomain: stripDashes(subdomain),
        page,
        pageSize,
        match,
      }
    ).set("x-parse-application-id", config.coreAppId);
  }
}
