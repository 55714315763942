import PropTypes from "prop-types";
import React from "react";
import { I18n } from "react-redux-i18n";
import classes from "./OnBoardingModal.scss";

export default class Charged extends React.Component {
  render() {
    const { handleCancel, isSalla, handleRestart } = this.props;
    return (
      <div className={classes.ShortLinks}>
        {!isSalla && <p>{I18n.t("onBoarding_complete_package_desc")}</p>}
        <div className={classes.buttons}>
          <button
            onClick={() => {
              handleCancel();
            }}
            className="confirm"
          >
            {I18n.t("doneBtn")}
          </button>

          <button
            onClick={() => {
              handleRestart();
            }}
          >
            {I18n.t("restart")}
          </button>
        </div>
      </div>
    );
  }
}

Charged.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  isSalla: PropTypes.bool,
  handleRestart: PropTypes.func.isRequired,
};
