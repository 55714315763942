const productsNames = {
  mobileLandingPages: "Mobile Landing Pages",
  sms: "SMS",
  smartDeepLink: "Smart deep link",
  webPushNotification: "Web Push Notification",
  email: "EMAIL",
  socialMediaRetargeting: "socialmediaretargeting",
  appBackend: "App Backend",
  appPushNotification: "App Push Notification",
  automator: "Automator",
  customDomain: "Custom Domain",
  dedicatedAccountManager: "Dedicated Account Manager",
  funnels: "Funnels",
  richUserProfile: "Rich User Profile",
  segments: "Segments",
  serviceLevelAgreement: "Service Level Agreement",
  infra: "infra",
  mobileAttribution: "mobileattribution",
  parseDashboard: "parse_dashboard",
  uninstallMonitor: "uninstallmonitor",
  digitalInvoices: "DigitalInvoices",
  mobileUserFunnel: "Mobile User Funnel",
  events: "Events",
  whatsApp: "WhatsApp",
  loyalty: "Loyalty",
  OTP: "OTP",
};

export default productsNames;
