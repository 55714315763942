import PropTypes from "prop-types";
import React from "react";
import _ from "underscore";
import { Link } from "react-router";

import { findSectionBySlug, urlMatcher } from "modules/router";

import classes from "./MenuSection.scss";
import { connect } from "react-redux";

const MenuSection = (props) => {
  const {
    sidebar: { items },
    slug,
    currentPath,
    onNavigation,
    ...otherProps
  } = props;

  const section = findSectionBySlug(items, slug);

  return (
    <div className={classes.menuSection} {...otherProps}>
      <h5 className={classes.header}>{section.title}</h5>
      {section.children ? (
        <ul className={classes.list}>
          {_.map(
            section.children,
            (item, index) =>
              item.url && (
                <li
                  key={index}
                  className={
                    urlMatcher(item, currentPath) ? classes.active : ""
                  }
                >
                  <Link to={item.url} onClick={() => onNavigation()}>
                    {item.title}
                  </Link>
                </li>
              )
          )}
        </ul>
      ) : (
        <ul className={classes.list}>
          <li className={`${urlMatcher(section, currentPath) && "active"}`}>
            <Link to={section.url} onClick={() => onNavigation()}>
              {section.title}
            </Link>
          </li>
        </ul>
      )}
    </div>
  );
};

MenuSection.propTypes = {
  slug: PropTypes.string.isRequired,
  currentPath: PropTypes.string.isRequired,
  onNavigation: PropTypes.func,
};

MenuSection.defaultProps = {
  onNavigation: () => {},
};

export default connect((store) => ({ sidebar: store.sidebar }))(MenuSection);
