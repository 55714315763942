import React from "react";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { I18n } from "react-redux-i18n";
import classes from "./SMSConfig.scss";

const HelpButton = () => (
  <div className={classes.helpButton}>
    <AiOutlineQuestionCircle size={32} color="#A18436" />
    <p>{I18n.t("Forhelppress")}</p>
    <button
      type="button"
      onClick={() =>
        window.open(
          "https://wa.me/966549740945",
          "_blank",
          "noopener,noreferrer"
        )
      }
    >
      {I18n.t("Here")}
    </button>
  </div>
);
export default HelpButton;
