import { config } from "consts/api";
import { userData } from "modules/user";
import superagent from "superagent";
import { getFromLocalStore } from "../store/localStore";
import { logoutUser } from "./user";
/**
 * @description prepares request
 *
 * @param method : method
 * @param link : url
 * @param data : body of the request
 * @param query : params of the request
 * @param auth : boolean
 */
export const shopifyApi = (
  method,
  link,
  data = false,
  query = false,
  auth = true
) => {
  const request = superagent(method, link);
  request.set("Accept", "application/json");
  const user = userData();
  var authToken = getFromLocalStore("authToken");
  var shopName = getFromLocalStore("shop");
  if (auth && user) {
    if (config.AUTH_TOKEN) {
      request.set("authToken", user.auth_token);
    } else {
      request.auth(user.email, user.password);
    }
  } else if (authToken) {
    request.set("authToken", authToken);
  }
  // send data if attached
  if (data) {
    request.send(data);
  }
  // query parameters
  if (query) {
    request.query(query);
  }
  if (shopName) {
    request.set("x-shopify-shop-domain", shopName);
  }
  return request;
};

const superAgentInstance = (
  method,
  link,
  data = null,
  query = null,
  auth = true
) => {
  const request = superagent(method, link).set("Accept", "application/json");

  const user = userData();
  const authToken = getFromLocalStore("authToken");

  if (auth && user) {
    if (config.AUTH_TOKEN) {
      request.set("authToken", user.auth_token);
    } else {
      request.auth(user.email, user.password);
    }
  } else if (authToken) {
    request.set("authToken", authToken);
  }

  if (data) request.send(data);
  if (query) request.query(query);

  request.on("response", (response) => {
    if (response.status === 401) {
      request.abort();
      logoutUser();
    }
  });

  return request;
};

export default superAgentInstance;

/**
 * generates token header
 * @return {string}
 */
export const generateToken = () => {
  const user = userData();
  if (config.AUTH_TOKEN) {
    return user.auth_token;
  } else {
    const base64 = btoa(`${user.email}:${user.password}`);
    return `Basic ${base64}`;
  }
};
