import { CustomSelector, SpinLoader } from "components";
import { CONTENT_VIEW_FLUID } from "layouts/DefaultLayout/modules/layout";
import React from "react";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import { getNotifyConfig, setNotifyConfig } from "reducers/config";
import { Toast } from "../../../../../modules/toast";
import AWS_SMS from "./components/AWS_SMS";
import Alfa from "./components/Alfa";
import CEQUENS from "./components/CEQUENS";
import SMS_Country from "./components/SMS_Country";
import SMS_Global from "./components/SMS_Global";
import SMS_Jormail from "./components/SMS_Jormail";
import SmsMisr from "./components/SmsMisr";
import Twilio from "./components/Twilio";
import UNIFONIC from "./components/UNIFONIC";
import VictoryLink from "./components/VictoryLink";

export const SMSOptions = [
  {
    value: "AWS-SMS",
    label: "AWS-SMS",
  },
  {
    value: "SMSGlobal",
    label: "SMSGlobal",
  },
  {
    value: "Unifonic",
    label: "Unifonic",
  },
  {
    value: "VictoryLink",
    label: "VictoryLink",
  },
  {
    value: "Twilio",
    label: "Twilio",
  },
  {
    value: "Alfa",
    label: "Alfa",
  },
  {
    value: "CEQUENS",
    label: "CEQUENS",
  },
  {
    value: "SMS-JORMAIL",
    label: "Jormail",
  },
  {
    value: "SMS_Country",
    label: "SMS Country",
  },
  {
    value: "SMS-MISR",
    label: "SMS-MISR",
  },
];

class SMSConfig extends React.Component {
  getLayoutOptions() {
    return {
      contentView: CONTENT_VIEW_FLUID,
      sidebarEnabled: true,
      navbarEnabled: true,
      footerEnabled: true,
      headerEnabled: false,
    };
  }
  constructor(props) {
    super(props);
    this.state = {
      initialized: false,
      requires_auth: false,
      requires_authVendors: false,
      disabled: true,
      TypeSMS: "AWS-SMS",
      validateSelect: false,
    };
  }

  async componentWillMount() {
    const {
      config: { notify },
      user: { activeSuitId },
      getNotifyConfig,
    } = this.props;

    if (notify && notify.channels && notify.channels.sms) return;

    await getNotifyConfig(activeSuitId);
  }

  handleSave = () => {
    this.setState({
      disabled: true,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const {
      setNotifyConfig,
      getNotifyConfig,
      form,
      user: { activeSuitId },
    } = this.props;
    const { TypeSMS } = this.state;
    const values = form.nconfig.values;
    let connectionInfo;

    switch (TypeSMS) {
      case "VictoryLink":
        connectionInfo = {
          connection_info: {
            type: "SMS-VICTORYLINK",
            UserName: values.UserName,
            Password: values.Password,
            SMSLang: values.SMSLang,
            SMSSender: values.SMSSender,
          },
        };
        break;
      case "Twilio":
        connectionInfo = {
          connection_info: {
            type: "SMS-TWILIO",
            account_sid: values.account_sid,
            sender: values.sender,
            auth_token: values.auth_token,
          },
        };
        break;

      case "SMS-MISR":
        connectionInfo = {
          connection_info: {
            type: "SMS-MISR",
            username: values.username,
            password: values.password,
            sender: values.sender,
          },
        };
        break;

      case "Alfa":
        connectionInfo = {
          connection_info: {
            type: "SMS-ALFA",
            apiKey: values.apiKey,
            sender: values.sender,
          },
        };
        break;

      case "CEQUENS":
        connectionInfo = {
          connection_info: {
            type: "SMS-CEQUENS",
            accesstoken: values.accesstoken,
            senderName: values.senderName,
          },
        };
        break;

      case "UNIFONIC":
        connectionInfo = {
          connection_info: {
            type: "SMS-UREST",
            AppSid: values.AppSid,
          },
        };
        break;

      case "AWS-SMS":
        connectionInfo = {
          connection_info: {
            type: "AWS-SNS",
            access_key_ID: values.sms_access_key_ID,
            aws_region: values.sms_aws_region,
            secret_access_key: values.sms_secret_access_key,
          },
        };
        break;

      case "SMSGlobal":
        connectionInfo = {
          connection_info: {
            type: "HTTP_GET",
            url: values.url,
          },
        };
        break;

      case "Unifonic":
        connectionInfo = {
          connection_info: {
            type: "UREST",
            AppSid: values.AppSid,
          },
        };
        break;

      case "SMS_Country":
        connectionInfo = {
          connection_info: {
            type: "SMS-COUNRTY",
            User: values.User,
            passwd: values.passwd,
          },
        };
        break;

      case "SMS-JORMAIL":
        connectionInfo = {
          connection_info: {
            type: "SMS-JORMAIL",
            UserName: values.UserName,
            Password: values.Password,
            SenderID: values.SenderID,
          },
        };
        break;

      default:
        break;
    }

    let conInfoClone = { ...connectionInfo.connection_info };

    for (let key in conInfoClone) {
      if (
        typeof conInfoClone[key] === "string" &&
        conInfoClone[key].includes("**")
      ) {
        delete conInfoClone[key];
      }
    }

    connectionInfo.connection_info = conInfoClone;

    let notifyConfigData = {
      channels: {
        sms: {
          ...connectionInfo,
          vendor: values.vendor,
        },
      },
    };

    setNotifyConfig(activeSuitId, notifyConfigData)
      .then(() => {
        Toast.success(
          I18n.t("SettingsPage_CommunicationChannels_SMSConfig_updateMsg")
        );
        getNotifyConfig(activeSuitId);
      })
      .then(() => {
        this.handleSave();
      });
  };

  handleModify = (type, value = false) => {
    if (value) {
      this.setState({ requires_auth: true });

      this.props.change("requires_authVendors", true);
    }
    if (!value) {
      this.setState({ requires_auth: false });
      this.props.change("requires_authVendors", false);
    }
  };

  handleEdit = () => {
    this.setState({
      disabled: false,
    });
  };

  handleSelectSMS = (newValue) => {
    this.setState({ TypeSMS: newValue.value });
  };

  render() {
    const { TypeSMS, validateSelect } = this.state;

    const {
      config: { notify, isPending },
    } = this.props;

    if (notify?.channels?.sms && !validateSelect) {
      const smstype = notify.channels.sms.connection_info?.type;
      const smstypeMap = {
        HTTP_GET: "SMSGlobal",
        "AWS-SNS": "AWS-SMS",
        UREST: "Unifonic",
        "SMS-CEQUENS": "CEQUENS",
        "SMS-VICTORYLINK": "VictoryLink",
        "SMS-TWILIO": "Twilio",
        "SMS-ALFA": "Alfa",
        "SMS-JORMAIL": "SMS-JORMAIL",
        "SMS-MISR": "SMS-MISR",
      };

      const TypeSMS = smstypeMap[smstype] || "SMS_Country";

      this.setState({ TypeSMS, validateSelect: true });
    }

    return (
      <div style={{ marginTop: "20px" }}>
        {isPending && <SpinLoader />}

        <div>
          <label>
            {I18n.t("SettingsPage_CommunicationChannels_SMSConfig")}
          </label>
          <CustomSelector
            options={SMSOptions}
            value={SMSOptions.find((option) => option.value === TypeSMS)}
            onChange={this.handleSelectSMS}
          />
        </div>

        {TypeSMS === "VictoryLink" && (
          <VictoryLink
            handleModify={this.handleModify}
            handleSubmit={this.handleSubmit}
            handleEdit={this.handleEdit}
          />
        )}
        {TypeSMS === "SMS-MISR" && (
          <SmsMisr
            handleModify={this.handleModify}
            handleSubmit={this.handleSubmit}
            handleEdit={this.handleEdit}
          />
        )}
        {TypeSMS === "Alfa" && (
          <Alfa
            handleModify={this.handleModify}
            handleSubmit={this.handleSubmit}
            handleEdit={this.handleEdit}
          />
        )}
        {TypeSMS === "Twilio" && (
          <Twilio
            handleModify={this.handleModify}
            handleSubmit={this.handleSubmit}
            handleEdit={this.handleEdit}
          />
        )}
        {TypeSMS === "CEQUENS" && (
          <CEQUENS
            handleModify={this.handleModify}
            handleSubmit={this.handleSubmit}
            handleEdit={this.handleEdit}
          />
        )}
        {TypeSMS === "AWS-SMS" && (
          <AWS_SMS
            handleModify={this.handleModify}
            handleSubmit={this.handleSubmit}
            handleEdit={this.handleEdit}
          />
        )}

        {TypeSMS === "SMSGlobal" && (
          <SMS_Global
            handleModify={this.handleModify}
            handleSubmit={this.handleSubmit}
            handleEdit={this.handleEdit}
          />
        )}
        {TypeSMS === "Unifonic" && (
          <UNIFONIC
            handleModify={this.handleModify}
            handleSubmit={this.handleSubmit}
            handleEdit={this.handleEdit}
          />
        )}
        {TypeSMS === "SMS_Country" && (
          <SMS_Country
            handleModify={this.handleModify}
            handleSubmit={this.handleSubmit}
            handleEdit={this.handleEdit}
          />
        )}

        {TypeSMS === "SMS-JORMAIL" && (
          <SMS_Jormail
            handleModify={this.handleModify}
            handleSubmit={this.handleSubmit}
            handleEdit={this.handleEdit}
          />
        )}
      </div>
    );
  }
}
export default connect(
  (store) => ({
    user: store.user,
    config: store.config,
    form: store.form,
  }),
  {
    setNotifyConfig,
    getNotifyConfig,
  }
)(SMSConfig);
