/* eslint-disable no-undef */
import { composeWithDevTools } from "@redux-devtools/extension";
import translations from "i18n";
import {
  loadTranslations,
  setLocale,
  syncTranslationWithStore,
} from "react-redux-i18n";
import { applyMiddleware, legacy_createStore as createStore } from "redux";
import promiseMiddleware from "redux-promise-middleware";
import thunk from "redux-thunk";
import { getFromLocalStore } from "./localStore";
import makeRootReducer from "./reducers";

export default (initialState = {}) => {
  // ======================================================
  // Middleware Configuration
  // ======================================================
  const middleware = [thunk, promiseMiddleware()];

  // ======================================================
  // Store Enhancers
  // ======================================================
  const enhancers = [];
  if (__DEBUG__) {
    const devToolsExtension = window.devToolsExtension;
    if (typeof devToolsExtension === "function") {
      enhancers.push(devToolsExtension());
    }
  }

  // ======================================================
  // Store Instantiation and HMR Setup
  // ======================================================
  const store = createStore(
    makeRootReducer(),
    initialState,
    composeWithDevTools(applyMiddleware(...middleware), ...enhancers)
  );
  store.asyncReducers = {};

  if (module.hot) {
    module.hot.accept("./reducers", () => {
      const reducers = require("./reducers").default;
      store.replaceReducer(reducers());
    });
  }

  // translations
  let locale = getFromLocalStore("locale") || "en";
  syncTranslationWithStore(store);
  store.dispatch(loadTranslations(translations));
  store.dispatch(setLocale(locale));

  return store;
};
