import classNames from "classnames";
import { Navbar, OutsideClick, Sidebar } from "components";
import {
  CONTENT_VIEW_BOXED,
  CONTENT_VIEW_STATIC,
  HEADER_STYLE_BREADCRUMBS,
  HEADER_STYLE_SIMPLE,
  SCREEN_SIZE_LG,
  SCREEN_SIZE_MD,
  SCREEN_SIZE_SM,
  SCREEN_SIZE_XS,
  SIDEBAR_STYLE_BIGICONS,
  SIDEBAR_STYLE_DEFAULT,
  SIDEBAR_STYLE_SLIM,
  SKIN_COLOR,
  SKIN_COLOR_PRIMARY,
  SKIN_DARK,
  SKIN_LIGHT,
} from "layouts/DefaultLayout/modules/layout";
import PropTypes from "prop-types";
import React from "react";
import updateChildElementOfType from "./../utils/updateChildElementOfType";

class Layout extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,

    screenSizeChanged: PropTypes.func,

    navbarEnabled: PropTypes.bool,
    navbarFixed: PropTypes.bool,
    sidebarEnabled: PropTypes.bool,
    sidebarStyle: PropTypes.string,
    sidebarFixed: PropTypes.bool,
    sidebarAside: PropTypes.bool,
    contentView: PropTypes.string,
    footerEnabled: PropTypes.bool,
    footerFixed: PropTypes.bool,
    rightSidebarEnabled: PropTypes.bool,
    headerStyle: PropTypes.string,
    headerEnabled: PropTypes.bool,

    sidebarSkin: PropTypes.string,
    navbarSkin: PropTypes.string,
    skinColor: PropTypes.string,
  };

  static defaultProps = {
    screenSizeChanged: () => {},

    navbarEnabled: true,
    navbarFixed: false,
    sidebarEnabled: true,
    sidebarStyle: SIDEBAR_STYLE_DEFAULT,
    sidebarFixed: true,
    sidebarAside: false,
    contentView: CONTENT_VIEW_STATIC,
    footerEnabled: true,
    footerFixed: false,
    rightSidebarEnabled: false,
    headerStyle: HEADER_STYLE_BREADCRUMBS,
    headerEnabled: true,

    sidebarSkin: SKIN_DARK,
    navbarSkin: SKIN_DARK,
    skinColor: SKIN_COLOR_PRIMARY,
  };

  constructor(props, context) {
    super(props, context);

    this.currentScreenSize = SCREEN_SIZE_LG;
  }

  buildChildren(children) {
    return updateChildElementOfType(
      children,
      [Layout.Navigation, OutsideClick],
      [
        {
          targetType: Sidebar,
          props: {
            fixed:
              this.props.sidebarFixed &&
              this.props.sidebarStyle === SIDEBAR_STYLE_DEFAULT,
            fullHeight: this.props.sidebarAside,
            style: this.props.sidebarStyle,
          },
        },
        {
          targetType: Navbar,
          props: (prevProps) => ({
            // Force Inverse Disable and adjust the color with skin props
            className: `${prevProps.className || ""} ${this.getNavbarSkinClass(
              this.props.navbarSkin,
              this.props.skinColor
            )}`,
            inverse: false,
          }),
        },
      ]
    );
  }

  findNodes(parent) {
    if (parent) {
      this.contentElement = parent.querySelector(".main-wrap > .content");
      this.sidebarElement = parent.querySelector(
        ".main-wrap > .navigation > .sidebar"
      );
      this.navbarElement = parent.querySelector(
        ".main-wrap > .navigation .navbar"
      );
      this.footerElement = parent.querySelector(".main-wrap > footer");

      this.windowElement = window;
    }
  }

  responsiveHandler() {
    let newScreenSize = "";

    if (window.matchMedia("(max-width: 767px)").matches) {
      newScreenSize = SCREEN_SIZE_XS;
    } else if (
      window.matchMedia("(min-width: 768px) and (max-width: 991px)").matches
    ) {
      newScreenSize = SCREEN_SIZE_SM;
    } else if (
      window.matchMedia("(min-width: 992px) and (max-width: 1500px)").matches
    ) {
      newScreenSize = SCREEN_SIZE_MD;
    } else if (window.matchMedia("(min-width: 1200px)").matches) {
      newScreenSize = SCREEN_SIZE_LG;
    }

    if (newScreenSize !== this.currentScreenSize) {
      this.currentScreenSize = newScreenSize;
      this.props.screenSizeChanged(newScreenSize);
    }
  }

  getSidebarSkinClass(sidebarSkin, skinColor) {
    switch (sidebarSkin) {
      case SKIN_DARK:
        return `sidebar-dark-${skinColor}`;
      case SKIN_LIGHT:
        return `sidebar-light-${skinColor}`;
      case SKIN_COLOR:
        return `sidebar-${skinColor}`;
    }
  }

  getNavbarSkinClass(navbarSkin, skinColor) {
    switch (navbarSkin) {
      case SKIN_DARK:
        return "navbar-inverse";
      case SKIN_LIGHT:
        return "navbar-default";
      case SKIN_COLOR:
        return `navbar-${skinColor}`;
    }
  }

  getConfigurationClasses() {
    const skinClasses = this.getSidebarSkinClass(
      this.props.sidebarSkin,
      this.props.skinColor
    );

    return classNames(
      {
        "sidebar-disabled": !this.props.sidebarEnabled,
        "sidebar-fixed": this.props.sidebarFixed,
        "sidebar-full-height": this.props.sidebarAside,
        "navbar-disabled": false || !this.props.navbarEnabled,
        "navbar-fixed": this.props.navbarFixed,
        "footer-disabled": true || !this.props.footerEnabled,
        "footer-fixed": this.props.footerFixed,
        "sidebar-slim":
          this.props.sidebarEnabled &&
          this.props.sidebarStyle === SIDEBAR_STYLE_SLIM,
        "sidebar-big-icons":
          this.props.sidebarEnabled &&
          this.props.sidebarStyle === SIDEBAR_STYLE_BIGICONS,
        "boxed-layout": this.props.contentView === CONTENT_VIEW_BOXED,
        "sub-navbar-disabled": !this.props.headerEnabled,
        "sub-navbar-header-only":
          this.props.headerStyle === HEADER_STYLE_SIMPLE,
        "sidebar-overlay": this.props.currentScreenSize === SCREEN_SIZE_XS,
        "sidebar-overlay__open": this.props.overlaySidebarOpen,
      },
      skinClasses
    );
  }

  componentDidMount() {
    window.addEventListener("resize", () => {
      this.responsiveHandler();
    });

    this.responsiveHandler();
  }

  render() {
    const { children, className } = this.props;

    const adjustedChildren = this.buildChildren(children);

    const appClasses = classNames(this.getConfigurationClasses(), className);

    return (
      <div
        id="application"
        className={appClasses}
        ref={(parent) => this.findNodes(parent)}
      >
        <div className="main-wrap">{adjustedChildren}</div>
      </div>
    );
  }
}

export default Layout;
