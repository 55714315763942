import { Dropdown, Icon, Menu } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { setLocale } from "react-redux-i18n";
import { getFromLocalStore, setToLocalStore } from "../../../store/localStore";
import classes from "./LanguageSwitcher.scss";

const maneuKeys = {
  0: "en",
  1: "ar",
  // 2: "tr",
};

class LanguageSwitcher extends Component {
  constructor(props) {
    super(props);

    // get url params
    const urlParams = new URLSearchParams(window.location.search);

    // get lang from url params
    const lang = urlParams.get("lang");

    // if lang exist in url params set it to local store
    if (lang) {
      setToLocalStore("locale", lang);
    }

    this.state = {
      // read value from url if exist or from local store or set default value
      value: getFromLocalStore("locale") || "en",
    };
  }

  handleChange = (value) => {
    if (value === this.state.value) return;

    this.setState({ value: value });
    this.props.dispatch(setLocale(value));
    setToLocalStore("locale", value);
    location.reload();
  };

  render() {
    const { value } = this.state;

    const menu = (
      <Menu defaultSelectedKeys={[maneuKeys[value]]}>
        <Menu.Item key="0">
          <span
            onClick={() => {
              this.handleChange("en");
            }}
          >
            English
          </span>
        </Menu.Item>
        <Menu.Item key="1">
          <span
            onClick={() => {
              this.handleChange("ar");
            }}
          >
            العربية
          </span>
        </Menu.Item>
        {/* <Menu.Item key="3">
          <span
            onClick={() => {
              this.handleChange("tr");
            }}
          >
            Türkçe
          </span>
        </Menu.Item> */}
      </Menu>
    );

    return (
      <Dropdown overlay={menu} trigger={["click"]}>
        <span className={classes.languageSwitcher}>
          <Icon style={{ color: "#212121", fontSize: "20px" }} type="global" />
          {value} <Icon type="down" />
        </span>
      </Dropdown>
    );
  }
}
export default connect()(LanguageSwitcher);
