import { Button } from "antd";
import PropTypes from "prop-types";
import React from "react";
import { I18n } from "react-redux-i18n";
import classes from "./PrimaryButton.scss";

/**
 * Primary button component
 * @param {Object} props - Component props
 * @param {boolean} props.pending - Whether the button is in a pending state
 * @param {string|ReactNode} props.value - The text or ReactNode to display inside the button
 * @param {ReactNode} props.icon - The icon to display inside the button
 * @param {Object} props.style - The inline style object to apply to the button
 * @param {function} props.onClick - The function to call when the button is clicked
 * @param {boolean} props.disabled - Whether the button is disabled
 * @param {boolean} props.block - Whether the button should be displayed as a block element
 * @param {string} props.className - The CSS class name(s) to apply to the button
 * @param {string} props.type - The type of the button (e.g. "submit", "reset", "button")
 * @param {string} props.size - The size of the button (e.g. "small", "large")
 * @returns {ReactNode} - The rendered button component
 */
export default function PrimaryButton({
  pending,
  value,
  icon,
  style,
  onClick,
  disabled,
  block,
  className,
  type,
  size,
  children,
  danger,
  icon2,
  ...rest
}) {
  return (
    <Button
      htmlType={type}
      style={style}
      className={`${className} ${classes.primaryButton} ${
        danger ? classes.danger : ""
      }`}
      onClick={onClick}
      disabled={disabled}
      size={size}
      block={block}
      loading={pending}
      type={danger ? "danger" : "primary"}
      {...rest}
    >
      {icon}
      {typeof value === "string" ? I18n.t(value) : value}
      {children}
      {icon2}
    </Button>
  );
}

PrimaryButton.propTypes = {
  pending: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  icon: PropTypes.node,
  style: PropTypes.object,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  block: PropTypes.bool,
  className: PropTypes.string,
  type: PropTypes.string,
  size: PropTypes.string,
  children: PropTypes.node,
  danger: PropTypes.bool,
  icon2: PropTypes.node,
};

PrimaryButton.defaultProps = {
  type: "button",
  size: "large",
  danger: false,
};
