import PropTypes from "prop-types";
import React from "react";
import Select from "react-select";
import Label from "../Label";
import classes from "./CustomSelector.module.scss";

const CustomSelector = ({
  key,
  value,
  onChange,
  options,
  className,
  required,
  height = "48px",
  loading,
  dataAttribute,
  name,
  disabled,
  disabledMessage,
  style,
  label,
  placeholder,
  ...rest
}) => {
  const handleChange = (selectedOption) =>
    onChange(selectedOption, dataAttribute, name);

  return (
    <div
      className={`${className} ${classes.selectContainer}`}
      style={{
        ...style,
        width: "100%",
      }}
    >
      {label && <Label>{label}</Label>}
      <Select
        key={key}
        styles={{
          control: (baseStyles, state) => {
            return state.isDisabled
              ? {
                  ...baseStyles,
                  opacity: "0.3",
                  height,
                  border: "1px solid #B9B9C7",
                }
              : {
                  ...baseStyles,
                  borderColor: state.isFocused ? "#630bde" : "#bbb",
                  height,
                  border: "1px solid #bbb",
                  backgroundColor: "#FFFFFF",
                  borderRadius: "4px",
                  boxShadow: "none",
                  "&:focus, &:focus-within, &:focus:active": {
                    border: "1px solid #630bde",
                    backgroundColor: "#3f02a119",
                  },
                  "&:hover": {
                    border: "1px solid #bbb",
                  },
                  transition: "all 500ms cubic-bezier(0.4, 0, 0.2, 1)",
                };
          },
          input: (baseStyles) => {
            return {
              ...baseStyles,
              fontFamily: "Lato",
              fontWeight: "700",
              fontSize: "15px",
              color: "#1f2f3f",
              padding: "0 15px",
              transition: "all 500ms cubic-bezier(0.4, 0, 0.2, 1)",
            };
          },
          singleValue: (baseStyles) => {
            return {
              ...baseStyles,
              fontFamily: "Lato",
              fontWeight: "700",
              fontSize: "15px",
              color: "#1f2f3f",
              "&:focus, &:active, &:focus-withing": {
                color: "#1f2f3f",
              },
            };
          },
          menuList: (baseStyles) => {
            return {
              ...baseStyles,
              fontFamily: "Lato",
              fontWeight: "700",
              fontSize: "15px",
            };
          },
        }}
        className="react-select-container"
        classNamePrefix="react-select"
        options={options}
        onChange={handleChange}
        value={value}
        isClearable={false}
        required={required}
        placeholder={placeholder}
        data-my-attribute={dataAttribute}
        menuPlacement="auto" // Set the menuPlacement prop to 'auto'
        isLoading={loading}
        isDisabled={disabled}
        data-testid="custom-selector"
        {...rest}
      />
      {disabled && (
        <span className={classes.disabledMessage}>{disabledMessage}</span>
      )}
    </div>
  );
};

export default CustomSelector;

CustomSelector.propTypes = {
  key: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  onChange: PropTypes.func,
  options: PropTypes.array,
  className: PropTypes.string,
  required: PropTypes.bool,
  height: PropTypes.string,
  loading: PropTypes.bool,
  style: PropTypes.object,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  dataAttribute: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  disabledMessage: PropTypes.string,
};
