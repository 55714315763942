import generateRoutes from "route-items";

// ------------------------------------
// Constants
// ------------------------------------
export const REFRESH_SIDEBAR = "REFRESH_SIDEBAR";

export const CONST_SIDER = "CONST_SIDER";
// ------------------------------------
// Actions
// ------------------------------------
export function refreshSidebar(suitsData) {
  if (suitsData === false) {
    return {
      type: CONST_SIDER,
    };
  } else {
    return {
      type: REFRESH_SIDEBAR,
      payload: suitsData,
    };
  }
}

// ------------------------------------
// Initial State
// ------------------------------------
const initialState = {
  items: generateRoutes(),
  suitId: false,
};

// ------------------------------------
// Reducer
// ------------------------------------
export default function sidebarReducer(state = initialState, action) {
  switch (action.type) {
    case REFRESH_SIDEBAR:
      return {
        ...state,
        items: generateRoutes(
          action.payload.suits.find(
            (suit) => suit.id === action.payload.currentId
          )
        ),
        suitId: action.payload.currentId,
      };
    case CONST_SIDER:
      return {
        items: generateRoutes("demo"),
        suitId: false,
      };
    default:
      return state;
  }
}
