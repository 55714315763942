import classNames from "classnames";
import { SIDEBAR_STYLE_DEFAULT } from "layouts/DefaultLayout/modules/layout";
import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import classes from "./AddOnContent.scss";

const AddOnContent = (props) => {
  const { supportedStyle, children, className } = props;

  const stylesNormalized = _.isArray(supportedStyle)
    ? props.supportedStyle
    : [supportedStyle];

  const addOnClass = classNames(
    {
      [`${classes.defaultAvatarContainer} sidebar-container-default`]:
        _.includes(stylesNormalized, "default"),
      [`${classes.bigIconsAvatarContainer} sidebar-container-big-icons`]:
        _.includes(stylesNormalized, "big-icons"),
      [`${classes.slimAvatarContainer} sidebar-container-slim`]: _.includes(
        stylesNormalized,
        "slim"
      ),
    },
    "sidebar-section",
    className
  );

  return <div className={addOnClass}>{children}</div>;
};

AddOnContent.propTypes = {
  supportedStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  children: PropTypes.node.isRequired,
};

AddOnContent.defaultProps = {
  supportedStyle: SIDEBAR_STYLE_DEFAULT,
};

export default AddOnContent;
