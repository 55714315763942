import PropTypes from "prop-types";
import React, { Component } from "react";
import classes from "./Label.module.scss";

class Label extends Component {
  render() {
    const { children, id, style } = this.props;

    return (
      <label className={classes.label} htmlFor={id} style={style}>
        {children}
      </label>
    );
  }
}

export default Label;

Label.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string,
  style: PropTypes.object,
};
