import { Checkbox } from "antd";
import $ from "jquery";
import React, { Component } from "react";
import ReactDOM from "react-dom";
import { I18n } from "react-redux-i18n";
import classes from "./Menu.scss";

class Menu extends Component {
  componentWillReceiveProps(nextProps) {
    if (nextProps.display) {
      document.addEventListener("click", this.handleClick, false);
    } else {
      document.removeEventListener("click", this.handleClick, false);
    }
  }

  handleClick = (e) => {
    // check if the click is outside the parent container which includes the btn and menu
    if (!$.contains($(ReactDOM.findDOMNode(this)).parent().get(0), e.target)) {
      this.props.clearMenu();
      return;
    }
  };

  render() {
    return (
      <div
        style={{ display: this.props.display ? "block" : "none" }}
        ref={this.props.setRef}
        className={classes.menuContainer}
      >
        <p className={classes.menuHeader}>
          {I18n.t("DataTableComponent_tableSettingsMenuHeader")}
        </p>
        <div>
          {this.props.labels &&
            this.props.labels.map((label, idx) => (
              <div key={idx} className={classes.menuRow}>
                <Checkbox
                  checked={label.selected}
                  onClick={() => {
                    this.props.onColumnToggle(idx);
                  }}
                />
                {I18n.t(label.name)}
              </div>
            ))}
        </div>
        <p onClick={this.props.onResetColumns}>
          {I18n.t("DataTableComponent_tableSettingsMenuFooterBtn")}
        </p>
      </div>
    );
  }
}

export default Menu;
