import Select from "api/Select";
import { mapPlansToFeatures, mapPlansToProducts } from "modules/select";
import { RESET_ERRORS } from "reducers/user";

// ------------------------------------
// Constants
// ------------------------------------
const GET_ALL_MOBILE_MARKETING_PLANS = "GET_ALL_MOBILE_MARKETING_PLANS";
const GET_ALL_MOBILE_APP_PLANS = "GET_ALL_MOBILE_APP_PLANS";
const GET_CREADIT_CARD = "GET_CREADIT_CARD";
const GET_ALL_MOBILE_MARKETING_PLANS_FOR_SHOPIFY =
  "GET_ALL_MOBILE_MARKETING_PLANS_FOR_SHOPIFY";

// ------------------------------------
// Actions
// ------------------------------------
export function getMobileMarketingPlans(userType) {
  return {
    type: GET_ALL_MOBILE_MARKETING_PLANS,
    payload: Select.getPlans("mobile_marketing")(userType),
  };
}

export function getMobileAppPlans(userType) {
  return {
    type: GET_ALL_MOBILE_APP_PLANS,
    payload: Select.getPlans("mobile_app")(userType),
  };
}

export function getCreaditCard(stripeCustomerId) {
  return {
    type: GET_CREADIT_CARD,
    payload: Select.getCreaditCard(stripeCustomerId),
  };
}

export function getMobileMarketingPlansForShopify() {
  return {
    type: GET_ALL_MOBILE_MARKETING_PLANS_FOR_SHOPIFY,
    payload: Select.getMobileMarketingPlans(),
  };
}

// ------------------------------------
// Initial State
// ------------------------------------
const initialState = {
  mobileMarketingPlans: [],
  mobileMarketingProducts: [],
  mobileAppPlans: [],
  mobileAppProducts: [],
  creaditCard: null,
  isPending: false,
  error: false,

  mobileMarketingPlansForShopify: [],
};
// ------------------------------------
// Reducer
// ------------------------------------
export default function selectReducer(state = initialState, action) {
  switch (action.type) {
    case RESET_ERRORS:
      return {
        ...state,
        error: false,
      };
    case `${GET_ALL_MOBILE_MARKETING_PLANS}_PENDING`:
    case `${GET_ALL_MOBILE_APP_PLANS}_PENDING`:
    case `${GET_CREADIT_CARD}_PENDING`:
    case `${GET_ALL_MOBILE_MARKETING_PLANS_FOR_SHOPIFY}_PENDING`:
      return {
        ...state,
        isPending: true,
        error: false,
      };
    case `${GET_ALL_MOBILE_MARKETING_PLANS}_REJECTED`:
    case `${GET_ALL_MOBILE_APP_PLANS}_REJECTED`:
    case `${GET_CREADIT_CARD}_REJECTED`:
    case `${GET_ALL_MOBILE_MARKETING_PLANS_FOR_SHOPIFY}_REJECTED`:
      return {
        ...state,
        isPending: false,
        error: action.payload,
      };
    case `${GET_ALL_MOBILE_MARKETING_PLANS}_FULFILLED`:
      return {
        ...state,
        isPending: false,
        mobileMarketingPlans: action.payload.body.plans,
        mobileMarketingProducts: mapPlansToProducts(action.payload.body.plans),
        mobileMarketingFeatures: mapPlansToFeatures(action.payload.body.plans),
      };
    case `${GET_ALL_MOBILE_APP_PLANS}_FULFILLED`:
      return {
        ...state,
        isPending: false,
        mobileAppPlans: action.payload.body.plans,
        mobileAppProducts: mapPlansToProducts(action.payload.body.plans),
        mobileAppFeatures: mapPlansToFeatures(action.payload.body.plans),
      };
    case `${GET_CREADIT_CARD}_FULFILLED`:
      return {
        ...state,
        isPending: false,
        creaditCard: action.payload.body.data,
      };
    case `${GET_ALL_MOBILE_MARKETING_PLANS_FOR_SHOPIFY}_FULFILLED`:
      return {
        ...state,
        isPending: false,
        mobileMarketingPlansForShopify: action.payload.body.plans,
      };
    default:
      return state;
  }
}
