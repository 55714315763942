import { Confirm, DetailsList, SpinLoader } from "components";
import { CONTENT_VIEW_FLUID } from "layouts/DefaultLayout/modules/layout";
import Moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { I18n, Translate } from "react-redux-i18n";
import { getMobileAppPlans, getMobileMarketingPlans } from "reducers/Select";
import {
  clearDemoData,
  fillDemoData,
  generateAPIKey,
  getCurrentSuit,
  upgradePlan,
} from "reducers/user";
import config from "../../../../consts/api";
import { Toast } from "../../../../modules/toast";
import { upgrade } from "../../../../modules/upgrade";
import classes from "./../Setting.scss";
import ViewPlanFeatures from "./ViewPlanFeatures";

class Subscription extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      viewPlan: false,
    };
  }

  getLayoutOptions() {
    return {
      contentView: CONTENT_VIEW_FLUID,
      sidebarEnabled: true,
      navbarEnabled: true,
      footerEnabled: true,
      headerEnabled: false,
      exelload: false,
    };
  }

  handleDismiss = () => {
    this.setState({ show: false });
  };

  handleShow = () => {
    this.setState({ show: true });
  };

  handleViewPlan = () => {
    const { currentSuit, router, subdomain } = this.props;
    upgrade(undefined, currentSuit.source, subdomain, router);
  };

  showViewPlan = () => {
    this.setState({ viewPlan: true });
  };

  hideViewPlan = () => {
    this.setState({ viewPlan: false });
  };

  handleDeleteButton = () => {
    const {
      user: { activeSuitId },
      clearDemoData,
    } = this.props;

    clearDemoData(activeSuitId);

    this.handleDismiss();
  };

  handleCreateButton = () => {
    const {
      user: { activeSuitId },
      fillDemoData,
    } = this.props;

    fillDemoData(activeSuitId);

    this.handleCreateDismiss();
  };

  generatAPI() {
    const {
      generateAPIKey,
      user: { activeSuitId },
      getCurrentSuit,
      currentSuit,
    } = this.props;

    let data = {
      suit_name: currentSuit.name,
      reset_fields: ["appApiKey"],
    };

    generateAPIKey(data, currentSuit.id).then(() => {
      getCurrentSuit(activeSuitId);
    });
  }

  onCancelPlan = () => {
    const {
      select: { mobileAppPlans },
      currentSuit,
      upgradePlan,
    } = this.props;

    let freePlans = mobileAppPlans.filter(
      (plan) => plan.price === 0 && plan.interval === "month"
    );

    if (freePlans && freePlans.length > 0) {
      upgradePlan(
        currentSuit.id,
        freePlans[0].id,
        config.INFRA_TYPE,
        null,
        "paid"
      ).then(() => {
        const {
          getCurrentSuit,
          user: { activeSuitId },
        } = this.props;

        getCurrentSuit(activeSuitId).then(() => {
          Toast.success(I18n.t("SettingsPage_subscriptions_cancelMsg"));
        });
      });
    }
  };

  cancelConfirm = () => {
    this.setState({ displayConfirm: false });
  };

  onModalConfirm = () => {
    this.onCancelPlan();
    this.setState({ displayConfirm: false });
  };

  render() {
    const { userIsLoading, select, currentSuit } = this.props;

    const { displayConfirm, viewPlan } = this.state;

    return (
      <div>
        <ViewPlanFeatures
          onConfirm={this.handleViewPlan}
          onClose={this.hideViewPlan}
          display={currentSuit && viewPlan}
          suit={currentSuit}
          disabled={userIsLoading || !currentSuit.upgradable}
        />

        {userIsLoading && <SpinLoader />}

        <div className={classes.new}>
          <h2 className={classes.secondaryTitle}>
            {I18n.t("SettingsPage_subscriptions_title")}
          </h2>
          <div>
            <DetailsList
              data={[
                {
                  key: "SettingsPage_subscriptions_SubscriptionPlan_title",
                  value: currentSuit.plan_summary.plan_name || "NA",
                  description:
                    "SettingsPage_subscriptions_SubscriptionPlan_desc",
                  canCopy: false,
                },
                {
                  key: "SettingsPage_subscriptions_SubcriptionStartdate_title",
                  value:
                    Moment.utc(currentSuit.subscription.subscription_start_date)
                      .local()
                      .format("Do MMMM YYYY, h:mm:ss a") || "NA",
                  description:
                    "SettingsPage_subscriptions_SubcriptionStartdate_desc",
                  canCopy: false,
                },
                {
                  key: "SettingsPage_subscriptions_SubscriptionNextRenewalDate_title",
                  value: currentSuit.plan_summary.plan_name
                    .toLocaleLowerCase()
                    .includes("ltd")
                    ? "LTD"
                    : Moment.utc(currentSuit.subscription.subscription_end_date)
                        .local()
                        .format("Do MMMM YYYY, h:mm:ss a") || "NA",
                  description:
                    "SettingsPage_subscriptions_SubscriptionNextRenewalDate_desc",
                  canCopy: false,
                },
                {
                  key: "SettingsPage_subscriptions_Subscriptionstatus_title",
                  value: currentSuit.subscription.status,
                  description:
                    "SettingsPage_subscriptions_Subscriptionstatus_desc",
                  canCopy: false,
                },
              ]}
            />
            <div className={classes.buttonsContainer}>
              {select.mobileAppPlans && select.mobileAppPlans.length > 0 && (
                <button
                  onClick={() => this.setState({ displayConfirm: true })}
                  className={classes.cancelButton}
                >
                  {<Translate value="cancel" />}
                </button>
              )}

              {currentSuit.upgradable && (
                <button
                  onClick={this.handleViewPlan}
                  className={classes.saveButton}
                >
                  {<Translate value="upgrade" />}
                </button>
              )}

              {currentSuit && (
                <button
                  className={classes.saveButton}
                  onClick={this.showViewPlan}
                  disabled={!currentSuit}
                >
                  {
                    <Translate value="SettingsPage_subscriptions_ViewPlanFeaturesBtn" />
                  }
                </button>
              )}
            </div>
          </div>
        </div>

        <Confirm
          onConfirm={this.onModalConfirm}
          onClose={this.cancelConfirm}
          display={displayConfirm}
          title="SettingsPage_subscriptions_cancelConfirm_title"
          description="SettingsPage_subscriptions_cancelConfirm_desc"
          btnText="SettingsPage_subscriptions_cancelConfirm_title"
          btnType="critical"
          isPending={userIsLoading}
        />
      </div>
    );
  }
}
export default connect(
  (store) => ({
    user: store.user,
    select: store.select,
    userIsLoading: store.user.isPending,
  }),
  {
    generateAPIKey,
    getCurrentSuit,
    getMobileMarketingPlans,
    getMobileAppPlans,
    fillDemoData,
    clearDemoData,
    upgradePlan,
  }
)(Subscription);

Subscription.propTypes = {
  currentSuit: PropTypes.shape({
    name: PropTypes.string.isRequired,
    subdomain: PropTypes.string.isRequired,
    web_url: PropTypes.string,
    app_icon_url: PropTypes.string.isRequired,
    plan_summary: PropTypes.object.isRequired,
    domain: PropTypes.any,
    id: PropTypes.string,
    upgradable: PropTypes.bool,
    subscription: PropTypes.object,
    source: PropTypes.string,
  }),
  userIsLoading: PropTypes.bool,
  fillDemoData: PropTypes.func,
  generateAPIKey: PropTypes.func,
  getCurrentSuit: PropTypes.func,
  upgradePlan: PropTypes.func,
  clearDemoData: PropTypes.func,
  user: PropTypes.shape({
    activeSuitId: PropTypes.string,
  }),
  select: PropTypes.shape({
    mobileAppPlans: PropTypes.array,
  }),
  subdomain: PropTypes.string,
  router: PropTypes.object,
};
