import {
  IS_WELCOME_SKIPPED,
  USER_DATA,
  USER_IS_LOGGED_IN,
  USER_SUITS,
} from "consts/local";
import _ from "lodash";
import { I18n } from "react-redux-i18n";
import {
  deleteFromLocalStore,
  getFromLocalStore,
  setToLocalStore,
} from "store/localStore";
import { browserHistory } from "../main";
import { Toast } from "./toast";

export const updateUser = (data) => setToLocalStore(USER_DATA, data);

/**
 * @description checks if user is logged in or no
 *
 * @return boolean
 */
export const isLoggedIn = () =>
  getFromLocalStore(USER_IS_LOGGED_IN) && getFromLocalStore(USER_DATA);
/**
 * @description gets user data
 *
 * @return {}
 */
export const userData = () => getFromLocalStore(USER_DATA);
/**
 * @description login user
 *
 * @param data
 * @return void
 */
export const login = (data) => {
  setToLocalStore(USER_IS_LOGGED_IN, true);
  setToLocalStore(USER_DATA, {
    ...userData(),
    ...data,
  });
  setToLocalStore(IS_WELCOME_SKIPPED, {});
};

/**
 * @description logout user
 *
 * @return void
 */
export const logout = () =>
  deleteFromLocalStore([USER_IS_LOGGED_IN, USER_DATA, USER_SUITS]);

export const logoutUser = () => {
  logout();

  // redirect to login page with a reload to clear the redux store and reset the app
  browserHistory.push("/auth/login");
};

/**
 * @description set initial data to use them in login request
 *
 * @param data
 * @return void
 */
export const setInitData = ({ email, password }) => {
  setToLocalStore(USER_DATA, {
    email,
    password,
  });
};
/**
 * @description get prop data from user data
 *
 * @param prop
 * @return user prop
 */
export const getUserProp = (prop) => {
  const userData = getFromLocalStore(USER_DATA);
  return userData[prop];
};
/**
 * @description set user suits  to localstorage
 *
 * @param suits
 * @return void
 */
export const setUserSuits = (suits) => {
  setToLocalStore(USER_SUITS, suits);
};
/**
 * @description gets user data
 *
 * @return []
 */
// export const userSuits = () => getFromLocalStore(USER_SUITS) || []
export const userSuits = () => getFromLocalStore(USER_SUITS);
/**
 * @description gets Id of the suit's subdomain
 *
 * @param suitId
 * @param suits
 * @return string | boolean
 */
export const getSubdomainFromId = (suitId, suits) => {
  const suit = suits.find((suit) => suit.id === suitId);
  return suit ? suit.subdomain : false;
};
/**
 * @description gets Id of the suit's subdomain
 *
 * @param suitSubdomain
 * @param suits
 * @return string | boolean
 */
export const getIdFromSubdomain = (suitSubdomain, suits) => {
  const suit = suits.find((suit) => suit.subdomain === suitSubdomain);
  return suit ? suit.id : false;
};
/**
 * @description gets Id of the suit's subdomain
 *
 * @param suitId
 * @param suits
 * @return {object} active suit
 */
export const getActiveSuit = (suitId, suits) =>
  suits.find((suit) => suit.id === suitId) || {};

/**
 * @description gets current app using subdomain
 *
 * @param subdomain
 * @return {object} current suit
 */
export const getSuitBySubdomain = (subdomain) => {
  const apps = getFromLocalStore(USER_SUITS);

  return apps
    ? apps.filter(
        (app) => app.subdomain.toLowerCase() === subdomain.toLowerCase()
      )[0]
    : null;
};

/**
 * @description gets current app using suitId
 *
 * @param suitId
 * @return {object} current suit
 */
export const getSuitById = (suitId) => {
  const apps = getFromLocalStore(USER_SUITS);
  return apps.filter((app) => app.id === suitId)[0];
};

/**
 * @description gets subdomain using suit id
 *
 * @return subdomain
 */

export const getSubdomainFromSuitID = (suitId) => {
  const suits = getFromLocalStore(USER_SUITS);
  const suit = suits.find((suit) => suit.id === suitId);
  return suit ? suit.subdomain : false;
};

/**
 * @description gets all user suits
 *
 * @return [{}] suits
 */
export const getUserSuits = () => {
  return getFromLocalStore(USER_SUITS);
};

export const showNotAuthorizedMsg = () => {
  Toast.error(I18n.t("notAuthorized"));
};

/**
 * @description check if user is a subuser
 *
 * @return boolean
 */
export const isSubUser = () => {
  return userData() && !!userData().admin_id;
};

/**
 * @description check if subuser has access to product
 *
 * @return boolean
 */
export const hasSubUserAccessToProduct = (suitId, productName) => {
  if (!userData().projects_products) return false;
  const suitProds = _.find(
    userData().projects_products,
    ({ suit_id }) => suit_id === suitId
  );
  if (!suitProds) return false;
  const productObj = _.find(
    suitProds.products,
    ({ name }) => name === productName
  );
  return _.get(productObj, ["permission"], false) === "read/write";
};

export const hasProduct = (suitId, productName, props = null) => {
  let suits = JSON.parse(localStorage.getItem("user.suits"));
  if (!suits) {
    if (props) showNotAuthorizedMsg();

    return false;
  }
  let suit = suits.filter((suit) => suit.id === suitId)[0];
  if (!suit) {
    if (props) showNotAuthorizedMsg();
    return false;
  }
  let planProducts = suit.plan_summary.plan_product;
  for (let product of planProducts) {
    if (product.name == productName) return true;
  }
  if (props) showNotAuthorizedMsg();
  return false;
};

/**
 * @description check if user has access to product
 *
 * @return boolean
 */
export const hasAccessToProduct = (suitId, productName) => {
  if (!hasProduct(suitId, productName)) {
    return false;
  }

  let hasAccess =
    !isSubUser() ||
    (isSubUser() && hasSubUserAccessToProduct(suitId, productName));

  return hasAccess;
};
