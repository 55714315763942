import { Form, PrimaryButton, TextBox } from "components";
import { required } from "modules/validations";
import React from "react";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import { getNotifyConfig } from "reducers/config";
import { Field, reduxForm } from "redux-form";

export class UNIFONIC extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      initialized: false,
    };
  }
  componentWillReceiveProps(nextProps) {
    const { notify } = nextProps.config;

    if (
      notify &&
      notify.channels.sms.connection_info.type === "UREST" &&
      !this.state.initialized
    ) {
      nextProps.initialize({
        vendor: notify.channels.sms.vendor || undefined,
        type: "UREST" || undefined,
        AppSid: notify.channels.sms.connection_info.AppSid || undefined,
      });
      this.setState({ initialized: true });
    }
  }

  render() {
    const { handleSubmit, valid, disabled } = this.props;
    return (
      <Form>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "18px",
          }}
        >
          <Field
            name="vendor"
            placeholder={I18n.t(
              "SettingsPage_CommunicationChannels_SMSConfig_option_unifonic_name_placeholder"
            )}
            validate={required}
            label={I18n.t(
              "SettingsPage_CommunicationChannels_SMSConfig_option_unifonic_name_label"
            )}
            component={TextBox}
            disabled={disabled}
          />

          <Field
            name="AppSid"
            placeholder={I18n.t(
              "SettingsPage_CommunicationChannels_SMSConfig_option_unifonic_APPSID_placeholder"
            )}
            validate={[required]}
            label={I18n.t(
              "SettingsPage_CommunicationChannels_SMSConfig_option_unifonic_APPSID_label"
            )}
            component={TextBox}
            disabled={disabled}
          />

          <PrimaryButton
            value={I18n.t(
              "SettingsPage_CommunicationChannels_SMSConfig_option_saveBtn"
            )}
            onClick={handleSubmit}
            disabled={!valid}
            style={{ marginTop: "20px" }}
          />
        </div>
      </Form>
    );
  }
}

export default reduxForm({
  form: "nconfig",
})(
  connect(
    (store) => ({
      user: store.user,
      config: store.config,
      form: store.form,
    }),
    {
      getNotifyConfig,
    }
  )(UNIFONIC)
);
