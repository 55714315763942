import { Confirm, DetailsList, SpinLoader } from "components";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { I18n, Translate } from "react-redux-i18n";
import { getMobileAppPlans, getMobileMarketingPlans } from "reducers/Select";
import {
  clearDemoData,
  fillDemoData,
  generateAPIKey,
  getCurrentSuit,
} from "reducers/user";
import { Toast } from "../../../../modules/toast";
import classes from "./ApiAndSdk.scss";

class ApiAndSdk extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      displayConfirm: false,
    };
  }

  generatAPI() {
    const {
      generateAPIKey,
      user: { activeSuitId },
      currentSuit,
    } = this.props;

    let data = {
      suit_name: currentSuit.name,
      reset_fields: ["appApiKey"],
    };

    generateAPIKey(data, currentSuit.id).then(() => {
      Toast.success(I18n.t("SettingsPage_ApiAndSdk_regeneratedMsg"));

      getCurrentSuit(activeSuitId);
    });
  }

  cancelConfirm = () => {
    this.setState({ displayConfirm: false });
  };

  onModalConfirm = () => {
    this.generatAPI();
    this.setState({ displayConfirm: false });
  };

  render() {
    const { userIsLoading, currentSuit } = this.props;

    const { displayConfirm } = this.state;

    return (
      <div className={classes.apiAndSdk}>
        {userIsLoading && <SpinLoader />}

        <div>
          <h2 className={classes.secondaryTitle}>
            {I18n.t("SettingsPage_ApiAndSdk_title")}
          </h2>
          <DetailsList
            data={[
              {
                key: "SettingsPage_ApiAndSdk_StoreID_title",
                value: currentSuit.id || "NA",
                description: I18n.t("SettingsPage_ApiAndSdk_StoreID_desc"),
                canCopy: true,
                info: true,
              },
              {
                key: "SettingsPage_ApiAndSdk_ApiKey_title",
                value: currentSuit.api_key || "NA",
                description: I18n.t("SettingsPage_ApiAndSdk_ApiKey_desc"),
                canCopy: true,
                info: true,
              },
            ]}
          />
          {currentSuit.source !== "Shopify" && (
            <button
              className={classes.regenerateButton}
              onClick={() => this.setState({ displayConfirm: true })}
            >
              <Translate value="SettingsPage_ApiAndSdk_regenerateBtn" />
            </button>
          )}
        </div>

        <Confirm
          onConfirm={this.onModalConfirm}
          onClose={this.cancelConfirm}
          display={displayConfirm}
          title="SettingsPage_ApiAndSdk_deleteConfirm_title"
          description="SettingsPage_ApiAndSdk_deleteConfirm_desc"
          btnText="SettingsPage_ApiAndSdk_deleteConfirm_title"
          btnType="primary"
          isPending={userIsLoading}
        />
      </div>
    );
  }
}
export default connect(
  (store) => ({
    user: store.user,
    userIsLoading: store.user.isPending,
  }),
  {
    generateAPIKey,
    getCurrentSuit,
    getMobileMarketingPlans,
    getMobileAppPlans,
    fillDemoData,
    clearDemoData,
  }
)(ApiAndSdk);

ApiAndSdk.propTypes = {
  currentSuit: PropTypes.shape({
    name: PropTypes.string.isRequired,
    subdomain: PropTypes.string.isRequired,
    web_url: PropTypes.string,
    app_icon_url: PropTypes.string.isRequired,
    plan_summary: PropTypes.object.isRequired,
    domain: PropTypes.any,
    id: PropTypes.string,
  }),
  userIsLoading: PropTypes.bool,
  generateAPIKey: PropTypes.func,
  user: PropTypes.shape({
    activeSuitId: PropTypes.string,
  }),
};
