import PropTypes from "prop-types";
import React, { Component } from "react";
import { IoMdCheckmark } from "react-icons/io";
import { I18n } from "react-redux-i18n";
import classes from "./CampaignWizard.scss";

export default class CampaignWizard extends Component {
  renderRadioBox = (checked) => {
    return (
      <div className={`${checked ? classes["active-radio"] : ""}`}>
        {checked && <IoMdCheckmark size={22} color="#fff" />}
      </div>
    );
  };

  render() {
    const { steps, currentStep, changeCurrentStep, children } = this.props;

    return (
      <div className={classes["camapign-wizard"]}>
        <div className={classes["camapign-wizard-steps"]}>
          {steps.map((step, idx) => (
            <div
              key={idx}
              role="button"
              onClick={() => changeCurrentStep(step.value)}
              className={classes["wizard-step"]}
              style={{
                minWidth: step.width + "%",
              }}
            >
              {this.renderRadioBox(idx < currentStep)}
              <p>{I18n.t(step.label)}</p>
            </div>
          ))}
        </div>
        {children}
      </div>
    );
  }
}

CampaignWizard.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      width: PropTypes.number.isRequired,
    })
  ).isRequired,
  currentStep: PropTypes.number.isRequired,
  changeCurrentStep: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};
