import React from "react";
import classes from "./AnalyticsBlock.scss";
import { Link } from "react-router";
import { I18n } from "react-redux-i18n";
const AnalyticsBlock = ({
  title,
  icon,
  detailsLink,
  isBlocked,
  blockedMsg,
  createNew,
  children,
  pushsdk,
  inerText,
  isPending,
  style,
}) => (
  <div className={classes.container}>
    {title && (
      <h3 className={classes.title}>
        {icon && <i className={icon} />}
        {title}
      </h3>
    )}
    <div className={classes.block} style={style}>
      {children}
    </div>
    {detailsLink && (
      <Link to={detailsLink} className={classes.details}>
        {I18n.t("show details")}
      </Link>
    )}
    {createNew && (
      <Link to={createNew} className={classes.details}>
        {I18n.t("Create New")}
      </Link>
    )}
    {!isPending
      ? isBlocked && (
          <div className={classes.blocked}>
            {!blockedMsg && (
              <span>{blockedMsg || I18n.t("no data available to show")}</span>
            )}
            {blockedMsg && !pushsdk && (
              <span>
                {I18n.t(blockedMsg)}{" "}
                <a className={classes.link}>
                  <Link to={createNew}>
                    <br />
                    {inerText}
                  </Link>
                </a>
              </span>
            )}
            {blockedMsg && pushsdk && (
              <span>
                {I18n.t(blockedMsg)}{" "}
                <a
                  className={classes.link}
                  href={pushsdk}
                  target="_blank"
                  rel="noreferrer"
                >
                  <br />
                  {inerText}
                </a>
              </span>
            )}
          </div>
        )
      : null}
  </div>
);

export default AnalyticsBlock;
