import { SpinLoader } from "components";
import React from "react";
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import { getCurrentSuit, updateSuit } from "reducers/user";
import { Toast } from "../../../modules/toast";
import classes from "./OnBoardingModal.scss";
import {
  importAllProducts,
  importAllSallaProducts,
  importAllZidProducts,
} from "./ShortLinkLogic";

class ShortLinks extends React.Component {
  state = {
    isPending: false,
    createLink: false,
  };

  handleImport = async () => {
    const {
      user: { activeSuitId },
      currentSuit: { source, web_url },
    } = this.props;

    this.setState({ isPending: true });

    if (source == "Shopify") {
      try {
        const shopDomain = web_url?.startsWith("https://")
          ? web_url.split("https://")[1]
          : web_url;

        await importAllProducts(shopDomain, activeSuitId);
        Toast.success(I18n.t("onBoarding_links_successMsg"));

        this.props.handleOnboardingSteps("smartLinks", "", "Shopify");

        this.props.handleSteps();
        Toast.error(I18n.t("onBoarding_links_errorMsg"));
      } finally {
        this.setState({ isPending: false });
      }
    } else if (source == "Zid") {
      try {
        await importAllZidProducts(activeSuitId);
        Toast.success(I18n.t("onBoarding_links_successMsg"));
        this.props.handleOnboardingSteps("smartLinks", "", "Zid");

        this.props.handleSteps();
      } catch (error) {
        console.error(error);
        Toast.error(I18n.t("onBoarding_links_errorMsg"));
      } finally {
        this.setState({ isPending: false });
      }
    } else if (source == "Salla") {
      try {
        await importAllSallaProducts(activeSuitId);

        this.props.handleOnboardingSteps("smartLinks", "", "Salla");
        Toast.success(I18n.t("onBoarding_links_successMsg"));
        this.props.handleSteps();
      } catch (error) {
        Toast.error(I18n.t("onBoarding_links_errorMsg"));
      } finally {
        this.setState({ isPending: false });
      }
    }
  };
  render() {
    const { handleSteps, handleBack, isArabic } = this.props;
    const { isPending } = this.state;

    return (
      <div className={classes.ShortLinks}>
        {isPending && <SpinLoader />}

        <p>{I18n.t("onBoarding_links_desc")}</p>
        <button
          onClick={() => {
            this.handleImport();
          }}
        >
          {I18n.t("onBoarding_links_Btn")}
        </button>
        <div className="buttonsContainer">
          <button
            onClick={() => {
              handleBack();
            }}
            className="confirm cancel"
          >
            {isArabic ? <BsArrowRightShort /> : <BsArrowLeftShort />}
            <span>{I18n.t("backBtn")}</span>
          </button>
          <button
            onClick={() => {
              handleSteps();
            }}
            className="confirm"
          >
            <span>{I18n.t("continueBtn")}</span>
            {isArabic ? <BsArrowLeftShort /> : <BsArrowRightShort />}
          </button>
        </div>
      </div>
    );
  }
}

export default connect(
  (store) => ({
    user: store.user,
  }),
  {
    updateSuit,
    getCurrentSuit,
  }
)(ShortLinks);
