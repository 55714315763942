import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { Breadcrumb as ReactBootstrapBreadcrumb } from "react-bootstrap";
import classes from "./Breadcrumb.scss";

/*
    Extended ReactBootstrap Breadcrumb. Adds noBackground prop.
*/
const Breadcrumb = (props) => {
  const { children, className, ...otherProps } = props;

  const breadCrumbClass = classNames(
    className,
    classes.breadCrumbMob,
    classes.breadCrumb,
    {
      [`${classes.noBackground}`]: props.noBackground,
    }
  );

  return (
    <ReactBootstrapBreadcrumb {...otherProps} className={breadCrumbClass}>
      {children}
    </ReactBootstrapBreadcrumb>
  );
};

Breadcrumb.propTypes = {
  noBackground: PropTypes.bool,
};

Breadcrumb.defaultProps = {
  noBackground: false,
};

Breadcrumb.Item = ReactBootstrapBreadcrumb.Item;

export default Breadcrumb;
