import { Radio } from "antd";
import React from "react";
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import SMSvendor from "static/SMS Vendor.svg";
import SMSpackage from "static/SMS package .svg";
import classes from "../OnBoardingModal.scss";
import SMSPackage from "./SMSPackage";
import SMSVendor from "./SMSVendor";

const smsCards = [
  {
    image: SMSpackage,
    title: "onBoarding_SMS_card1_title",
    description: "onBoarding_SMS_card1_desc",
  },
  {
    image: SMSvendor,
    title: "onBoarding_SMS_card2_title",
    description: "onBoarding_SMS_card2_desc",
  },
];

class SMS extends React.Component {
  state = {
    TypeSMS: "",
  };

  handleChange = (e) => {
    this.setState({ TypeSMS: e.target.value });
  };

  handleSMSClick = () => {
    this.props.handleChosenSMSType(this.state.TypeSMS);
    this.props.handleSMSType(this.state.TypeSMS);
    this.props.handleProgress();
    this.props.handleSteps();
  };

  handleBackSMS = () => {
    this.props.handleChosenSMSType("");
    this.props.handleBack();
  };

  render() {
    const { TypeSMS } = this.state;
    const { handleSteps, handleBack, handleOnboardingSteps, isArabic } =
      this.props;
    const smsCardClass = `${classes.smsCard} smsCard ${
      TypeSMS == I18n.t("onBoarding_SMS_card1_title") ? classes.selected : ""
    }`;
    return (
      <div className={classes.SMSPackage}>
        {/* cards */}
        {TypeSMS == "" && (
          <div className={classes.SMSContainer}>
            <Radio.Group
              onChange={this.handleChange}
              value={TypeSMS}
              className={classes.smsCards}
            >
              {smsCards.map((sms, index) => (
                <Radio
                  key={index}
                  value={I18n.t(sms.title)}
                  className={smsCardClass}
                >
                  <div className={classes.smsContent}>
                    <div>
                      <img
                        src={sms.image}
                        alt={I18n.t(sms.title)}
                        className=""
                      />
                    </div>
                    <div>
                      <h5>{I18n.t(sms.title)}</h5>
                      <p>{I18n.t(sms.description)}</p>
                    </div>
                  </div>
                </Radio>
              ))}
            </Radio.Group>
            <div className="buttonsContainer">
              <button onClick={handleBack} className="confirm cancel">
                {isArabic ? <BsArrowRightShort /> : <BsArrowLeftShort />}
                {I18n.t("backBtn")}
              </button>

              <button onClick={this.handleSMSClick} className="confirm">
                {I18n.t("finishBtn")}
                {isArabic ? <BsArrowLeftShort /> : <BsArrowRightShort />}
              </button>
            </div>
          </div>
        )}

        {/* SMS Package Purchase */}

        {TypeSMS === I18n.t("onBoarding_SMS_card1_title") && (
          <SMSPackage
            isArabic={isArabic}
            handleOnboardingSteps={handleOnboardingSteps}
            handleSteps={handleSteps}
            handleBack={this.handleBackSMS}
          />
        )}

        {TypeSMS === I18n.t("onBoarding_SMS_card2_title") && (
          <SMSVendor
            isArabic={isArabic}
            handleOnboardingSteps={handleOnboardingSteps}
            handleSteps={handleSteps}
            handleBack={this.handleBackSMS}
          />
        )}
      </div>
    );
  }
}

export default connect(
  (store) => ({
    user: store.user,
  }),
  {}
)(SMS);
