import { ControlLabel, OutsideClick } from "components";
import React from "react";
import { ChromePicker } from "react-color";
import classes from "./ColorPicker.scss";

class ColorPicker extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
    this.handleColorChange = this.handleColorChange.bind(this);
    this.toggleOpen = this.toggleOpen.bind(this);
    this.close = this.close.bind(this);
  }

  toggleOpen() {
    this.setState((prevState) => ({ open: !prevState.open }));
  }

  close() {
    this.setState({ open: false });
  }

  handleColorChange(color) {
    this.props.handleChange(color.hex);
  }

  render() {
    const { value, label, size = "large" } = this.props;
    const { open } = this.state;

    const width = size === "large" ? "344px" : "166px";

    return (
      <div
        style={{
          width,
        }}
      >
        {label && (
          <ControlLabel className={classes.label}>{label}</ControlLabel>
        )}
        <OutsideClick onClickOutside={this.close}>
          <div className={classes.contain}>
            <input
              value={value}
              className={classes.input}
              onClick={this.toggleOpen}
              readOnly
            />
            <span
              className={classes.colorButton}
              style={{ backgroundColor: value }}
              onClick={this.toggleOpen}
            />
            {open && (
              <div className={classes.popOver}>
                <ChromePicker
                  color={value}
                  onChangeComplete={this.handleColorChange}
                />
              </div>
            )}
          </div>
        </OutsideClick>
      </div>
    );
  }
}
export default ColorPicker;
