import React from "react";
import { Carousel } from "react-bootstrap";
import login_content_1 from "static/login-content/Login 1.svg";
import login_content_2 from "static/login-content/Login 2.svg";
import login_content_3 from "static/login-content/Login 3.svg";
import login_content_4 from "static/login-content/Login 4.svg";
import login_content_5 from "static/login-content/Login 5.svg";
import classes from "./Slider.scss";

export default class ControlledCarousel extends React.Component {
  element = null;
  setElementRef = (element) => {
    this.element = element;
  };

  render() {
    return (
      <div ref={this.setElementRef} className={classes["slider__auth"]}>
        <Carousel
          interval={2000}
          controls={false}
          indicators={true}
          onSelect={this.handleSelect}
        >
          <Carousel.Item>
            <img alt="900x500" src={login_content_1} />
            {/* <Carousel.Caption>
              <p>
                Shorten, and Track your links to boost social media engagement
              </p>
            </Carousel.Caption> */}
          </Carousel.Item>
          <Carousel.Item>
            <img alt="900x500" src={login_content_2} />
            {/* <Carousel.Caption>
              <p>Link Retargeting</p>
            </Carousel.Caption> */}
          </Carousel.Item>
          <Carousel.Item>
            <img alt="900x500" src={login_content_3} />
            {/* <Carousel.Caption>
              <p>Link In Bio</p>
            </Carousel.Caption> */}
          </Carousel.Item>
          <Carousel.Item>
            <img alt="900x500" src={login_content_4} />
            {/* <Carousel.Caption>
              <p>Branded Links</p>
            </Carousel.Caption> */}
          </Carousel.Item>
          <Carousel.Item>
            <img alt="900x500" src={login_content_5} />
            {/* <Carousel.Caption>
              <p>Branded Links</p>
            </Carousel.Caption> */}
          </Carousel.Item>
        </Carousel>
      </div>
    );
  }
}
