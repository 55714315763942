import { Modal } from "antd";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { I18n } from "react-redux-i18n";
import { getMobileAppPlans } from "reducers/Select";
import { getCurrentSuit, getUserSuits, updateSuit } from "reducers/user";
import { connect } from "routes/routedComponent";
import BuySMSPackageorUseYourVendor from "static/Buy SMS Package or Use Your Vendor.svg";
import createِAutomatorsProduct from "static/automator-onboarding.svg";
import configureretargetingchannels from "static/configure retargeting channels.svg";
import createshortlinksforallproducts from "static/create short links for all products.svg";
import progress from "static/progress.svg";
import success from "static/success.svg";
import Charged from "./Charged";
import Complete from "./Complete";
import ConfigureAutomators from "./ConfigureAutomators";
import classes from "./OnBoardingModal.scss";
import Retargeting from "./Retargeting";
import SMS from "./SMS/SMS";
import ShortLinks from "./ShortLinks";

const onboardingModalData = (smsType, onboarding, isSalla) => [
  {
    image: configureretargetingchannels,
    title: I18n.t("onBoarding_integration_title"),
    step: "step1",
    body: Retargeting,
  },
  {
    image: createshortlinksforallproducts,
    title: I18n.t("onBoarding_links_title"),
    step: "step2",
    body: ShortLinks,
  },
  {
    image: createِAutomatorsProduct,
    title: I18n.t("onBoarding_automators_title"),
    step: "step3",
    body: ConfigureAutomators,
  },
  {
    image: BuySMSPackageorUseYourVendor,
    title: smsType ? (
      <div>
        {smsType === "Use Your SMS Vendor"
          ? I18n.t("onBoarding_SMS_vendor_title")
          : I18n.t("onBoarding_SMS_package_title")}
      </div>
    ) : (
      I18n.t("onBoarding_SMS_title")
    ),

    step: "step4",
    body: SMS,
  },
  {
    image: success,
    title: isSalla
      ? I18n.t("onBoarding_complete_vendor_desc")
      : onboarding?.sms?.vendor === true
      ? I18n.t("onBoarding_complete_vendor_title")
      : I18n.t("onBoarding_complete_package_title"),
    body: onboarding?.sms?.vendor === true ? Complete : Charged,
  },
];

class OnBoardingModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stepNum: 0,
      visible: false,
      smsType: "",
      progress: true,
      chosenSMSType: "",
      onboarding: {
        retargeting: false,
        smartLinks: false,
        automators: false,
        sms: {
          package: false,
          vendor: false,
        },
        automations: [],
      },
      metaStatus: {},
    };

    const {
      user: { suits, activeSuitId },
    } = props;

    this.currentSuit = suits?.find((suit) => suit.id === activeSuitId);
    this.bodyElement = document.querySelector("body");
  }

  async componentDidMount() {
    const {
      user: { activeSuitId },
      getCurrentSuit,
    } = this.props;

    if (!activeSuitId) return console.log("no active suit id");
    const currentSuit = (await getCurrentSuit(activeSuitId)).value.body;
    this.currentSuit = currentSuit;

    if (!currentSuit?.meta || isEmpty(currentSuit?.meta)) {
      this.showModal();
    } else {
      const { meta, source } = currentSuit;
      const { onboarding, sms } = meta;

      let stepNum = 0;

      if (onboarding?.retargeting) stepNum = 1;
      if (onboarding?.smartLinks) {
        stepNum = 2;
        if (source === "Zid") {
          this.setState({ smsType: "Use Your SMS Vendor" });
          this.handleChosenSMSType(I18n.t("onBoarding_SMS_card2_title"));
        }
        if (source === "Salla") stepNum = 3;
      }
      if (onboarding?.automators) stepNum = 3;
      if (sms?.package || sms?.vendor) stepNum = 4;

      this.setState({ stepNum });
    }
  }

  handleSMSType = (value) => {
    this.setState({ smsType: value });
  };

  showModal = () => {
    this.setState({ visible: true });
  };

  handleProgress = () => {
    this.setState({ progress: false });
  };

  handleCancel = () => {
    this.bodyElement.style.overflow = "auto";
    this.setState({ visible: false });
  };

  handleBack = () => {
    if (this.state.stepNum != 0) {
      this.setState((prevState) => ({
        stepNum: Number(prevState.stepNum - 1),
      }));
    } else {
      this.setState({
        visible: false,
      });
    }
  };

  handleSteps = () => {
    const { stepNum: currentStepNum } = this.state;
    const {
      user: { suit },
    } = this.props;

    const suitSource = suit.source;
    const isSalla = suitSource === "Salla";
    const isZid = suitSource === "Zid";

    this.setState((prev) => {
      return {
        stepNum: isSalla && currentStepNum === 2 ? 4 : prev.stepNum + 1,
      };
    });

    // // sync state to suit
    // this.updateSteps();
  };

  handleStepNum = () => {
    this.setState({ stepNum: 1 });
  };

  handleChosenSMSType = (type) => {
    this.setState({ chosenSMSType: type });
  };

  handleOnboardingSteps = (step, type, store) => {
    if (store == "Zid") {
      this.setState({ smsType: "Use Your SMS Vendor" });
      this.handleChosenSMSType(I18n.t("onBoarding_SMS_card2_title"));
    }
    if (type) {
      this.setState(
        (prevState) => {
          return {
            onboarding: {
              ...prevState.onboarding,
              sms: {
                ...prevState.onboarding.sms,
                [type]: true,
              },
            },
          };
        },
        () => {
          this.updateSteps();
        }
      );
    } else {
      this.setState(
        (prevState) => {
          return {
            onboarding: {
              ...prevState.onboarding,
              [step]: true,
            },
          };
        },
        () => {
          this.updateSteps();
        }
      );
    }
  };

  handleAutomationsStep = (automations) => {
    this.setState(
      (prevState) => ({
        onboarding: {
          ...prevState.onboarding,
          automations,
          automators: true,
        },
      }),
      () => {
        this.updateSteps();
      }
    );
  };

  updateSteps = () => {
    const { updateSuit } = this.props;

    const { name, id } = this.currentSuit;

    const oldMeta = structuredClone(this.currentSuit?.meta);
    const newMeta = {
      onboarding: {
        ...oldMeta?.onboarding,
        ...this.state.onboarding,
      },
    };

    updateSuit(id, {
      meta: newMeta,
      suit_name: name,
    });
  };

  handleRestart = async () => {
    // clear the onboarding steps from the suit
    const { updateSuit } = this.props;

    const { name, id } = this.currentSuit;

    try {
      await updateSuit(id, {
        meta: {},
        suit_name: name,
      });

      this.setState({ stepNum: 0 });

      this.showModal();
    } catch (e) {
      console.error(e);
    }
  };

  render() {
    const { stepNum, smsType, onboarding, visible } = this.state;

    const { isArabic, logo } = this.props;

    const suit = this.props.user?.suit;
    const suitSource = suit?.source;
    const isSalla = suitSource === "Salla";
    const isInitialLogo = logo === "initial";

    const { router } = this.context;

    const onboardingModalDataArray = onboardingModalData(
      smsType,
      onboarding,
      isSalla
    );

    const image = onboardingModalDataArray[stepNum].image;
    const title = onboardingModalDataArray[stepNum].title;
    const step = onboardingModalDataArray[stepNum].step;
    const Body = onboardingModalDataArray[stepNum].body;

    return (
      <div
        style={{
          direction: isArabic ? "rtl" : "ltr",
        }}
      >
        <button
          style={{
            direction: isArabic ? "rtl" : "ltr",
          }}
          onClick={this.showModal}
          className={classes.onboardingBtn}
        >
          <img
            src={progress}
            alt="progress"
            style={{ width: "24px", height: "24px" }}
          />

          {isInitialLogo ? "" : I18n.t("onBoardingBtn")}

          {isInitialLogo && isArabic && <MdKeyboardArrowLeft size={34} />}

          {isInitialLogo && isArabic && <MdKeyboardArrowRight size={34} />}
        </button>

        {visible && (
          <Modal
            style={{
              direction: isArabic ? "rtl" : "ltr",
            }}
            className={`${classes.onBoadringModal} onBoadringModal`}
            title={
              <div>
                <div className="header">
                  <img src={image} />
                  <p>{title}</p>
                </div>

                {step && (
                  <div className={classes.steps}>
                    {onboardingModalDataArray.map((_, index) => {
                      if (index === onboardingModalDataArray.length - 1) {
                        return null;
                      }
                      return isSalla && index === 3 ? null : (
                        <div
                          key={index}
                          className={`${classes.step} ${
                            step === `step${index + 1}` && classes.active
                          }`}
                        >
                          {index + 1}
                        </div>
                      );
                    })}
                    <div className={classes.line}></div>
                  </div>
                )}
              </div>
            }
            visible={visible}
            onCancel={this.handleCancel}
            footer={null}
            centered
            destroyOnClose
          >
            <Body
              isSalla={isSalla}
              isArabic={isArabic}
              onboarding={onboarding}
              handleOnboardingSteps={this.handleOnboardingSteps}
              currentSuit={this.currentSuit}
              router={router}
              subdomain={this.currentSuit?.subdomain}
              handleSteps={this.handleSteps}
              handleBack={this.handleBack}
              handleSMSType={this.handleSMSType}
              handleCancel={this.handleCancel}
              handleProgress={this.handleProgress}
              handleChosenSMSType={this.handleChosenSMSType}
              chosenSMSType={this.state.chosenSMSType}
              smsType={this.state.smsType}
              handleStepNum={this.handleStepNum}
              handleRestart={this.handleRestart}
              handleAutomationsStep={this.handleAutomationsStep}
            />
          </Modal>
        )}
      </div>
    );
  }
}
export default connect(
  (store) => ({
    user: store.user,
  }),
  {
    getMobileAppPlans,
    updateSuit,
    getCurrentSuit,

    getUserSuits,
  }
)(OnBoardingModal);

OnBoardingModal.contextTypes = {
  router: PropTypes.object.isRequired,
};

OnBoardingModal.propTypes = {
  user: PropTypes.object.isRequired,
  getMobileAppPlans: PropTypes.func.isRequired,
  updateSuit: PropTypes.func.isRequired,
  getCurrentSuit: PropTypes.func.isRequired,
  getUserSuits: PropTypes.func.isRequired,
};
