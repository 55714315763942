import autocast from "autocast";
import { USER_DATA, USER_IS_LOGGED_IN } from "consts/local";
import {
  setCurrentPageSettings,
  setCurrentPageSettingsLiteral,
  setLayoutSettingsSafe,
} from "layouts/DefaultLayout/modules/layout";
import _ from "lodash";
import { setLocaleLang } from "modules/i18n";
import { isLoggedIn } from "modules/user";
import PropTypes from "prop-types";
import React from "react";
import * as redux from "react-redux";
import { getMobileAppPlans, getMobileMarketingPlans } from "reducers/Select";
import {
  getUserProfile,
  getUserSuits,
  handleLogout,
  loginWithGoogle,
  updateUserProfile,
} from "reducers/user";
import { setToLocalStore } from "store/localStore";
import { Toast } from "../modules/toast";

export class RoutedComponent extends React.Component {
  getLayoutOptions() {
    return {};
  }

  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  async componentWillMount() {
    const { setCurrentPageSettings } = this.props;

    const options = this.getLayoutOptions();
    if (setCurrentPageSettings) {
      setCurrentPageSettings(options);
    }
  }

  checkLogin(route) {
    if (route.private && !isLoggedIn()) {
      this.context.router.push("/auth/login");
    } else if (route.auth && isLoggedIn()) {
      this.context.router.push("/");
    }
  }

  componentDidMount() {
    const {
      getUserProfile,
      loginWithGoogle,
      setCurrentPageSettings,
      location,
      handleLogout,
    } = this.props;

    const { query } = location;

    const authtoken = query.authtoken;
    const lang = query.lang;
    const shopifyUpgraded = query.shopifyUpgraded;
    const showsol = query.showsol;
    const options = this.getLayoutOptions();

    if (authtoken) {
      // if a user already logged in logout him first to prevent any conflicts
      if (isLoggedIn()) {
        localStorage.removeItem("authToken");
        localStorage.removeItem(USER_DATA);
        localStorage.removeItem(USER_IS_LOGGED_IN);
      }

      setToLocalStore("authToken", authtoken);
      setToLocalStore(USER_IS_LOGGED_IN, true);

      getUserProfile().then((res) => {
        setToLocalStore(USER_DATA, res.value.body.user);

        loginWithGoogle();

        if (lang) {
          setLocaleLang(lang);
        }

        this.context.router.push("/");
      });
    }

    if (shopifyUpgraded === "true") {
      Toast.info("Shopify upgrade will take effect in a few minutes");
      handleLogout();
      this.context.router.push("/auth/login");
    }

    if (showsol) {
      setToLocalStore("showsol", true);
    }

    if (setCurrentPageSettings) {
      setCurrentPageSettings(options);
    }

    // Apply the layout settings from the ones provided in the URL
    if (this.props.location.query) {
      const urlSettings = _.mapValues(this.props.location.query, (val) =>
        autocast(val)
      );
      this.props.setLayoutSettingsSafe(urlSettings);
    }

    // Go to Top
    window.scrollTo(0, 0);
    this.checkLogin(this.props.route);
  }
}

// Attach restoreSettings action to the Component
export function connect(mapStateToProps = () => ({}), mapActionCreators = {}) {
  const extendedActionCreators = Object.assign([], mapActionCreators, {
    setCurrentPageSettings,
    setCurrentPageSettingsLiteral,
    setLayoutSettingsSafe,
    getUserProfile,
    getUserSuits,
    getMobileAppPlans,
    getMobileMarketingPlans,
    loginWithGoogle,
    updateUserProfile,
    handleLogout,
  });
  return redux.connect(mapStateToProps, extendedActionCreators);
}

RoutedComponent.propTypes = {
  getUserProfile: PropTypes.func,
  loginWithGoogle: PropTypes.func,
  updateUserProfile: PropTypes.func,
  setCurrentPageSettings: PropTypes.func,
  setLayoutSettingsSafe: PropTypes.func,
  location: PropTypes.shape({
    query: PropTypes.object,
  }),
  route: PropTypes.object,
};
