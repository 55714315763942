import { Checkbox } from "antd";
import { Copyicon } from "components";
import CustomTooltip from "components/CustomTooltip";
import {
  DATE_TYPE,
  HYPERLINK,
  IMAGE,
  NUMBER,
  OBJECT,
  STRING,
  TEMPLATE_MOBILE,
  URL,
} from "consts/TableColumnTypes";
import $ from "jquery";
import jsonexport from "jsonexport";
import _ from "lodash";
import { locale } from "modules/i18n";
import PropTypes from "prop-types";
import React from "react";
import { Modal } from "react-bootstrap";
import { MarvelDevices } from "react-css-devices";
import { findDOMNode } from "react-dom";
import { BiExport, BiImport } from "react-icons/bi";
import { BsGear } from "react-icons/bs";
import { FiChevronDown, FiChevronUp, FiMoreVertical } from "react-icons/fi";
import { I18n } from "react-redux-i18n";
import { connect } from "routes/routedComponent.js";
import { Toast } from "../../modules/toast";
import ActionMenu from "./ActionMenu";
import classes from "./DataTable.scss";
import Menu from "./Menu";

/**
 * ## Data Table responsible for displaying a list of items in a table form
 * - ### for a field to be clickable u provide clickable prop and handleItemClick prop that receives clicked row index and column.
 * - ### also for user id field to be clickable u provide clickableID.
 * - ### also for name field to be clickable u provide clickableName prop.
 * - ### also for campaign id field to be clickable u provide clickableCampaignID prop.
 */

class DataTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menu_open: false,
      selectedFile: null,
      multiMenu: false,
    };
  }

  componentDidUpdate() {
    $(".modal-dialog").width(400).css("top", "45%");
    $(".modal .modal-dialog .modal-content").css("background", "transparent");
  }

  onMenuClicked = (e) => {
    e.preventDefault();
    $(findDOMNode(this.columnsMenu)).slideToggle(500, () => {
      this.setState({ menu_open: !this.state.menu_open });
    });
  };

  containsName(item) {
    return (
      this.props.clickableName &&
      (item.name.toUpperCase() === "NAME" ||
        item.name.toUpperCase().includes("NAME"))
    );
  }

  containsUserID = (item) => {
    const itemWithoutSpaces = item.name.replace(/\s/g, "");
    const firstCondition =
      itemWithoutSpaces.toUpperCase() === "USERID" && this.props.clickableID;
    const secondCondition =
      item.name.toLowerCase() === "user id" && this.props.clickableID;
    return (
      // To make the ID clickable, pass clickableID prop, as this will check if the label includes ID and the clickableID prop is passed
      firstCondition || secondCondition
    );
  };

  containsCampaignID = (item) => {
    return (
      this.props.clickableCampaignID &&
      item.name.toLowerCase() === "campaign id"
    );
  };

  onMultiMenuClicked = () => {
    this.setState({ multiMenu: !this.state.multiMenu });
  };

  clearMenu = () => {
    $(findDOMNode(this.columnsMenu)).slideToggle(500, () => {
      this.setState({ menu_open: false });
    });
  };

  exportToCSV = () => {
    let data = [];
    const tableDataRows = this.props.tableData.rows;
    const checkedRows = tableDataRows.filter((row) => row.checked);
    if (checkedRows.length > 0) {
      data = checkedRows.map((row) => _.omit(row, ["checked"]));
    } else {
      data = tableDataRows.map((row) => _.omit(row, ["checked"]));
    }

    const notSelectedLabels = this.props.tableData.labels
      .map((label) => (label.selected ? null : label.name))
      .filter((index) => index);

    let rowObjArray = data.map((row) => {
      let obj = {};
      row.values.forEach((value, idx) => {
        obj[this.props.tableData.labels[idx].name] = value;
      });
      return obj;
    });
    let dataToExport = rowObjArray.map((obj) => _.omit(obj, notSelectedLabels));
    // export to excel function
    const csvFileName = this.props.csvFileName;
    jsonexport(dataToExport, function (err, csv) {
      if (!err) {
        var data = new Blob([csv], { type: "text/csv" });
        var csvURL = window.URL.createObjectURL(data);
        var tempLink = document.createElement("a");
        tempLink.href = csvURL;
        tempLink.setAttribute("download", csvFileName);
        tempLink.click();
      }
    });
  };

  renderGear = () => {
    const { menu_open } = this.state;
    return this.props.isPending ? (
      <div
        key="gear"
        className={`${classes["skeletonized-btn-gear"]} ${classes["skeleton-animation"]}`}
      />
    ) : (
      <div
        key="gear"
        className={classes.gearBtnContainer}
        id="tableColumnsVisibilityBtn"
      >
        <button
          className={classes.gearBtn}
          ref={(component) => (this.gearBtn = component)}
          onClick={this.onMenuClicked}
        >
          <BsGear />
          {I18n.t("tableSettings")}
          {menu_open ? <FiChevronUp /> : <FiChevronDown />}
        </button>
        <Menu
          setRef={(component) => {
            this.columnsMenu = component;
          }}
          gearBtn={this.gearBtn}
          display={this.state.menu_open}
          onColumnToggle={this.props.onColumnToggle}
          onResetColumns={this.props.onResetColumns}
          clearMenu={this.clearMenu}
          labels={this.props.tableData && this.props.tableData.labels}
        />
      </div>
    );
  };

  // render export to csv button
  renderCSV = () => {
    const rowsChecked = this.props.tableData
      ? this.props.tableData.rows.filter((row) => row.checked)
      : [];

    return this.props.isPending ? (
      <div
        key="csv"
        className={`${classes["skeletonized-btn-csv"]} ${classes["skeleton-animation"]}`}
      />
    ) : (
      <div
        key="csv"
        className={classes.exportBtnContainer}
        id="exportBtnContainer"
      >
        <button
          className={classes.exportBtn}
          disabled={!this.props.tableData || !this.props.tableData?.rows.length}
          onClick={
            this.props.customExport ? this.props.customExport : this.exportToCSV
          }
        >
          <BiExport className={classes.icon} />
          {rowsChecked.length > 0
            ? I18n.t("DataTableComponent_exportSelectedBtn")
            : I18n.t("DataTableComponent_exportAllBtn")}
        </button>
      </div>
    );
  };

  // render upload File Input to the Parent And takes an function as props to set parent state when file changes
  renderUpload = () => {
    return this.props.isPending ? (
      <div
        key="upload"
        className={`${classes["skeletonized-btn-upload"]} ${classes["skeleton-animation"]}`}
      />
    ) : (
      <div
        key="upload"
        className={classes.importBtnContainer}
        id="importBtnContainer"
      >
        <button className={classes.importBtn} onClick={this.props.uploadCSV}>
          <BiImport className={classes.icon} />
          {I18n.t("DataTableComponent_importBtn")}
        </button>
      </div>
    );
  };

  renderFilterButton = () => {
    return this.props.isPending ? (
      <div
        key="filterBtn"
        className={`${classes["skeletonized-btn-csv"]} ${classes["skeleton-animation"]}`}
      />
    ) : (
      <div key="filterBtn">
        <button
          style={{ position: "relative" }}
          className={classes["outline-button"]}
          onClick={this.props.uploadCSV}
        >
          <i
            className={`fa fa-cloud-upload ${classes["download-icon"]}`}
            aria-hidden="true"
          />
        </button>
      </div>
    );
  };

  route = () => {};

  multiActionClick = (name, segments) => {
    this.setState({ multiMenu: false }, () =>
      this.props.multiActionClick(name, segments)
    );
  };

  onActionClick = (idx) => {
    return (actionName) => {
      this.props.handleActionClicked(idx, actionName);
    };
  };

  clickPreview = (content) => {
    this.setState({ showPreview: true, content });
  };

  clickMoreEventDetails = (content) => {
    const parsedContent = content ? JSON.parse(content) : null;
    if (!parsedContent) {
      return Toast.info(I18n.t("noDataToDisplay"));
    }
    this.setState({ showMoreDetails: true, content: parsedContent });
  };

  renderPreview = () => {
    if (this.state.showPreview) {
      return (
        <div className="render-preview">
          <Modal
            show
            onHide={() => {
              this.setState({ showPreview: false });
            }}
          >
            <Modal.Body>
              <div
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  position: "relative",
                }}
              >
                <MarvelDevices
                  deviceName={"iphone6plus"}
                  color={"white"}
                  transform={0.8}
                  orientation={"portrait"}
                >
                  {/*Paste your content here*/}
                  <img src={"http://via.placeholder.com/667x375"} />
                </MarvelDevices>
                <div
                  style={{
                    width: "336.8px",
                    height: "594px",
                    backgroundColor: "#fff",
                    position: "absolute",
                    top: "87px",
                    borderRadius: "3px",
                    left: "18px",
                    zIndex: "1",
                  }}
                >
                  <iframe
                    style={{
                      width: "100%",
                      height: "100%",
                      border: "none",
                      overflow: "auto",
                    }}
                    srcDoc={this.state.content}
                    title="Preview Email"
                  ></iframe>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      );
    } else if (this.state.showMoreDetails) {
      const dataToRender = this.state.content;
      if (!dataToRender) return Toast.info(I18n.t("noDataToDisplay"));

      if (dataToRender.channel && dataToRender.channel === "email") {
        return (
          <div className="render-preview">
            <Modal
              show
              onHide={() => {
                this.setState({ showMoreDetails: false });
              }}
            >
              <Modal.Body>
                <div
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    position: "relative",
                  }}
                >
                  <MarvelDevices
                    deviceName={"iphone6plus"}
                    color={"white"}
                    transform={0.8}
                    orientation={"portrait"}
                  >
                    <img src={"http://via.placeholder.com/667x375"} />
                  </MarvelDevices>
                  <div
                    style={{
                      width: "336.8px",
                      height: "594px",
                      backgroundColor: "#fff",
                      position: "absolute",
                      top: "87px",
                      borderRadius: "3px",
                      left: "18px",
                      zIndex: "1",
                    }}
                  >
                    <iframe
                      style={{
                        width: "100%",
                        height: "100%",
                        border: "none",
                        overflow: "auto",
                      }}
                      srcDoc={dataToRender.message}
                      title="Preview Email"
                    ></iframe>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        );
      }
      // handle array data
      else if (Array.isArray(dataToRender)) {
        if (dataToRender.length > 0) {
          return (
            <div className="render-preview">
              <Modal
                show
                onHide={() => {
                  this.setState({ showMoreDetails: false });
                }}
              >
                <Modal.Body
                  style={{
                    backgroundColor: "white",
                    margin: "4rem 0",
                    maxHeight: "700px",
                    overflowY: "auto",
                    padding: "35px 0",
                    position: "relative",
                  }}
                >
                  <button
                    style={{
                      position: "absolute",
                      top: "0",
                      right: "0",
                      height: "40px",
                      width: "40px",
                      backgroundColor: "transparent",
                      fontWeight: "bolder",
                    }}
                    onClick={() => {
                      this.setState({ showMoreDetails: false });
                    }}
                  >
                    X
                  </button>
                  <div className={classes.extraDetails}>
                    {dataToRender.map((item, index) => {
                      if (typeof item === "object") {
                        return (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              gap: "6px",
                            }}
                          >
                            {Object.entries(item).map(
                              ([key, value], index2) => {
                                return (
                                  <div
                                    key={index2}
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "6px",
                                    }}
                                  >
                                    <p>{key}</p> : <p>{value}</p>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        );
                      } else {
                        return (
                          <div key={index}>
                            <p>{index + 1} : </p> <p>{item}</p>
                          </div>
                        );
                      }
                    })}
                  </div>
                </Modal.Body>
              </Modal>
            </div>
          );
        }
      } else {
        // handle object data
        const newArrTable = Object.entries(dataToRender);
        return (
          <div className="render-preview">
            <Modal
              show
              onHide={() => {
                this.setState({ showMoreDetails: false });
              }}
            >
              <Modal.Body
                style={{
                  backgroundColor: "white",
                  margin: "4rem 0",
                  maxHeight: "700px",
                  overflowY: "auto",
                }}
              >
                <div className={classes.extraDetails}>
                  {newArrTable.map((row, index) => (
                    <div key={index}>
                      <p>{row[0]} : </p> <p>{row[1]}</p>
                    </div>
                  ))}
                </div>
              </Modal.Body>
            </Modal>
          </div>
        );
      }
    }
    return null;
  };

  render() {
    const {
      tableData,
      multiRowsActions,
      order,
      filterContainer,
      renderFilterContainer,
      isPending,
      globalChecked,
      onGlobalCheckboxClicked,
      onSortClicked,
      checkDataTableRow,
      handleItemClick,
      clickable,
      toggleRowActionMenu,
      largeActionMenu,
      actions,
      clearActionMenu,
      minHeight,
    } = this.props;
    const { multiMenu } = this.state;

    let selectedLabelsIndexes = null;
    let labelsToRender = null;
    let hasSelectedMultiRows = null;
    let selectedRowsCnt = 0;
    let selectedRows = null;

    // used in giving the data table container a minimum height
    const finalMinHeight = minHeight || 450;

    const DisableButtons =
      !this.props.tableData || !this.props.tableData?.rows?.length;

    if (tableData) {
      selectedLabelsIndexes = tableData.labels
        .map((label, idx) => (label.selected ? idx : null))
        .filter((index) => index !== null);
      // ------------------------------------- //
      labelsToRender =
        tableData?.labels?.length > 0 &&
        tableData.labels.filter((label) => label.selected);
      selectedRows = _.get(this.props, ["tableData", "rows"]).filter(
        (data) => data.checked
      );
      selectedRowsCnt = selectedRows.length;
      hasSelectedMultiRows = multiRowsActions && selectedRows.length > 1;
    }

    const currentLocale = locale();

    return (
      <div className={classes.dataTable} id="DataTable">
        {this.renderPreview()}
        <div className={classes.dataTableHeader}>
          {order
            ? order.map((element) => {
                return element.type === "gear"
                  ? this.renderGear()
                  : element.type === "csv"
                  ? this.renderCSV()
                  : element.type === "upload"
                  ? this.renderUpload()
                  : element.type === "filter"
                  ? this.renderFilterButton()
                  : element.renderComponent();
              })
            : ["gear", "csv"].map((el) => {
                return el === "gear"
                  ? this.renderGear()
                  : el === "csv"
                  ? this.renderCSV()
                  : null;
              })}

          {hasSelectedMultiRows && (
            <div className={classes.multiActionBtnContainer}>
              <button
                className={classes.multiActionBtn}
                onClick={this.onMultiMenuClicked}
              >
                <span className={classes.count}>{selectedRowsCnt}</span>
                Select Action
                <i
                  className={`fa fa-caret-${!multiMenu ? "down" : "up"}`}
                  aria-hidden="true"
                ></i>
              </button>
              {multiMenu && (
                <ActionMenu
                  largeActionMenu={largeActionMenu}
                  clearActionMenu={this.onMultiMenuClicked}
                  display={multiMenu}
                  actions={multiRowsActions}
                  onActionClick={this.multiActionClick}
                />
              )}
            </div>
          )}
        </div>
        {/* {!order && (
          <div className={classes.dataTableHeader}>
            {this.renderGear()}
            {this.renderCSV()}
          </div>
        )} */}
        {filterContainer ? renderFilterContainer() : null}

        {/* multi action menu */}
        {/* {hasSelectedMultiRows && (
          <div className={classes['actions-section']}>
            <button onClick={this.onMultiMenuClicked}>
              Action {selectedRowsCnt} items
              <i className="fa fa-caret-down" aria-hidden="true"></i>
            </button>
            {multiMenu && (
              <ActionMenu
                largeActionMenu={largeActionMenu}
                clearActionMenu={this.onMultiMenuClicked}
                display={multiMenu}
                actions={multiRowsActions}
                onActionClick={this.multiActionClick}
              />
            )}
          </div>
        )} */}

        <div
          className={classes.dataTableContainer}
          style={{ minHeight: `${finalMinHeight}px` }}
        >
          <table className={classes.table}>
            {isPending ? (
              <thead>
                {[0, 0, 0, 0, 0].map((_, index) => (
                  <tr key={index}>
                    {[0, 0, 0, 0].map((_, index) => (
                      <th
                        key={index}
                        scope="col"
                        className={`${classes["skeletonized-header-label"]} ${classes["skeleton-animation"]}`}
                      />
                    ))}
                  </tr>
                ))}
              </thead>
            ) : (
              <thead
                className={`${classes.tableHead} ${
                  currentLocale === "ar" ? classes.rtl : null
                }`}
              >
                <tr>
                  {onGlobalCheckboxClicked && (
                    <th>
                      <Checkbox
                        checked={globalChecked}
                        onClick={onGlobalCheckboxClicked}
                        disabled={DisableButtons}
                      />
                    </th>
                  )}

                  <th>{I18n.t("No")}</th>
                  {labelsToRender &&
                    labelsToRender.map((_, idx) => (
                      <th key={idx} scope="col">
                        <div className={classes.tableHeadDataContainer}>
                          <span>{I18n.t(labelsToRender[idx].name)}</span>

                          {labelsToRender[idx].has_sort && (
                            <i
                              className={`fa fa-sort`}
                              onClick={() =>
                                onSortClicked(
                                  labelsToRender[idx].name,
                                  labelsToRender[idx].type
                                )
                              }
                            />
                          )}
                          {labelsToRender[idx].hasTooltip && (
                            <CustomTooltip
                              style={{ marginLeft: "5px" }}
                              position={"right"}
                              behind={I18n.t(labelsToRender[idx].tooltipBehind)}
                            >
                              <i
                                className={`fa fa-question ${classes["more-info-icon"]}`}
                                aria-hidden="true"
                              />
                            </CustomTooltip>
                          )}
                        </div>
                      </th>
                    ))}
                  {labelsToRender?.length > 0 && tableData?.actions && (
                    <th>
                      <FiMoreVertical />
                    </th>
                  )}
                </tr>
              </thead>
            )}
            {isPending ? null : (
              <tbody className={classes.tableBody}>
                {tableData?.rows.map((row, rowIdx) => {
                  const dataColsToRender = tableData.rows[
                    rowIdx
                  ]?.values.filter((_, idx) =>
                    selectedLabelsIndexes?.includes(idx)
                  );

                  return (
                    <tr key={rowIdx}>
                      {checkDataTableRow && (
                        <td>
                          <Checkbox
                            checked={tableData.rows[rowIdx].checked}
                            onClick={() => checkDataTableRow(rowIdx)}
                          />
                        </td>
                      )}

                      <td>{rowIdx + 1}</td>

                      {dataColsToRender?.map((col, index) => {
                        const item = col;
                        const nextItem = dataColsToRender[index + 1];
                        const currentLabel = labelsToRender[index];
                        const labelType = currentLabel.type;
                        const labelName = currentLabel.name;

                        return (
                          <td key={index} data-label={labelName}>
                            {/* object type */}
                            {labelType === OBJECT && (
                              <i
                                className={`${classes["mobile-preview"]} fa fa-external-link`}
                                onClick={() => this.clickMoreEventDetails(item)}
                                aria-hidden="true"
                              />
                            )}

                            {/* image type */}
                            {labelType === IMAGE && (
                              <img
                                className={`${classes.imageTableCell}`}
                                src={item}
                                alt={nextItem}
                                width={46}
                                height={46}
                              />
                            )}

                            {/* template mobile type */}
                            {labelType === TEMPLATE_MOBILE && (
                              <i
                                className={`${classes["mobile-preview"]} fa fa-mobile`}
                                onClick={() => this.clickPreview(item)}
                                aria-hidden="true"
                              />
                            )}

                            {(labelType === STRING ||
                              labelType === NUMBER ||
                              labelType === URL ||
                              labelType === DATE_TYPE ||
                              labelType === HYPERLINK) && (
                              <div
                                className={`${
                                  labelType === URL && classes.urlTableCell
                                }`}
                                onClick={
                                  (clickable &&
                                    this.containsCampaignID(currentLabel)) ||
                                  this.containsName(currentLabel) ||
                                  this.containsUserID(currentLabel)
                                    ? () =>
                                        handleItemClick(rowIdx, currentLabel)
                                    : null
                                }
                                style={
                                  this.containsName(currentLabel) ||
                                  this.containsCampaignID(currentLabel) ||
                                  (this.containsUserID(currentLabel) &&
                                    clickable)
                                    ? { cursor: "pointer" }
                                    : { cursor: "cursor" }
                                }
                              >
                                {item}
                                {labelType === URL && item && (
                                  <span className={classes["copy-icon"]}>
                                    <Copyicon value={item} />
                                  </span>
                                )}
                              </div>
                            )}
                          </td>
                        );
                      })}

                      {labelsToRender?.length > 0 && tableData?.actions && (
                        <td
                          style={{
                            maxWidth: "10px",
                            textAlign: "center",
                            verticalAlign: "center",
                            position: "relative",
                          }}
                          scope="col"
                        >
                          <FiMoreVertical
                            onClick={() => {
                              if (!tableData.rows[rowIdx].actionsMenu) {
                                toggleRowActionMenu(rowIdx);
                              }
                            }}
                            style={{ cursor: "pointer" }}
                          />
                          {tableData?.rows[rowIdx]?.actionsMenu && (
                            <ActionMenu
                              largeActionMenu={largeActionMenu}
                              onActionClick={this.onActionClick(rowIdx)}
                              actions={actions}
                              clearActionMenu={() => clearActionMenu(rowIdx)}
                            />
                          )}
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            )}
          </table>
        </div>
      </div>
    );
  }
}

export default connect(undefined, {})(DataTable);

DataTable.propTypes = {
  tableData: PropTypes.shape({
    labels: PropTypes.array.isRequired,
    rows: PropTypes.array.isRequired,
    actions: PropTypes.array,
  }),
  multiRowsActions: PropTypes.array,
  order: PropTypes.array,
  isPending: PropTypes.bool,
  globalChecked: PropTypes.bool,
  onGlobalCheckboxClicked: PropTypes.func,
  onSortClicked: PropTypes.func,
  checkDataTableRow: PropTypes.func,
  handleItemClick: PropTypes.func,
  clickable: PropTypes.bool,
  toggleRowActionMenu: PropTypes.func,
  largeActionMenu: PropTypes.bool,
  actions: PropTypes.array,
  clearActionMenu: PropTypes.func,
  minHeight: PropTypes.number,
  handleActionClicked: PropTypes.func,
  clickableCampaignID: PropTypes.bool,
  clickableName: PropTypes.bool,
  clickableID: PropTypes.bool,
  onColumnToggle: PropTypes.func,
  onResetColumns: PropTypes.func,
  customExport: PropTypes.func,
};
