import { i18nReducer as i18n } from "react-redux-i18n";
import { reducer as form } from "redux-form";
import appPush from "./AppPush";
import automator from "./Automator";
import MicroPages from "./MicroPages";
import select from "./Select";
import UploadCsvAppUsers from "./UploadCsvAppUsers";
import WhatsApp from "./WhatsApp";
import config from "./config";
import location from "./location";
import products from "./products";
import push from "./push";
import shopify from "./shopify";
import sidebar from "./sidebar";
import smartlink from "./smartlink";
import template from "./template";
import user from "./user";

const reducers = {
  template,
  location,
  user,
  smartlink,
  products,
  select,
  form,
  i18n,
  config,
  push,
  sidebar,
  UploadCsvAppUsers,
  appPush,
  shopify,
  MicroPages,
  WhatsApp,
  automator,
};
export default reducers;
