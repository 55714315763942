import React from "react";
import classes from "./FooterLanguageSwitcher.scss";

const languages = [
  {
    id: "1",
    label: "English",
    value: "en",
  },
  {
    id: "2",
    label: "العربية",
    value: "ar",
  },
  {
    id: "3",
    label: "Türkçe",
    value: "tr",
  },
];

const FooterLanguageSwitcher = ({ localeValue, handleLanguageClick }) => {
  return (
    <div className={classes.languagesContainer}>
      {languages.map((language) => {
        const { id, label, value } = language;
        return (
          <button
            className={`${value === localeValue ? classes.active : ""}`}
            key={id}
            name={value}
            onClick={handleLanguageClick}
          >
            {label}
          </button>
        );
      })}
    </div>
  );
};

export default FooterLanguageSwitcher;
