import React, { Component } from "react";
import classes from "./CustomTooltip.scss";

export default class CustomTooltip extends Component {
  render() {
    return (
      <div
        style={this.props.style}
        className={`${classes["content"]} ${this.props.className}`}
      >
        <p>
          <span
            className={classes[`tooltip-${this.props.position}`]}
            data-tooltip={this.props.behind}
          >
            {this.props.children}
          </span>
        </p>
      </div>
    );
  }
}
