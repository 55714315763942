import React, { Component } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { connect } from "react-redux";

class Tooltip2 extends Component {
  tooltip = (<Tooltip id="tooltip">{this.props.description}</Tooltip>);
  render() {
    return (
      <OverlayTrigger placement="bottom" overlay={this.tooltip}>
        <i
          style={{ color: "#630bde", marginLeft: "5px" }}
          className="fa fa-question-circle"
          aria-hidden="true"
        />
      </OverlayTrigger>
    );
  }
}
export default connect()(Tooltip2);
