import ENDPOINT from "consts/api";
import api from "modules/api";

export default class shopifypay {
  static shopifySubscripe = (xShopifyName, planID) => {
    return api(
      "post",
      ENDPOINT.SHOPIFY_SUBSCRIPE.shopifySubscripeUrl(),
      false,
      `plan_id=${planID}`
    ).set("x-shopify-shop-domain", `${xShopifyName}`);
  };
}
