import _ from "lodash";
import { isLoggedIn } from "modules/user";
import products from "./products";

const generateRoutes = (suit = false) => {
  return _.cloneDeep(
    isLoggedIn()
      ? suit === "demo"
        ? []
        : suit
        ? [...products(suit["plan_summary"]["plan_product"])]
        : []
      : []
  );
};

export default generateRoutes;
