import API_ENDPOINTS from "../consts/api";
import superAgentInstance from "../modules/api";

export default class PushEndpoints {
  static getSuitPushStatistics = (suitId, data) =>
    superAgentInstance(
      "get",
      API_ENDPOINTS.PUSHSTATICTICS(suitId),
      false,
      data
    );

  static getSuitPushs = (suitId, type) =>
    superAgentInstance("get", API_ENDPOINTS.LISTPUSH(suitId), false, { type });
  static getSuitPushsSearch = (suitId, nameVal, type) =>
    superAgentInstance(
      "get",
      API_ENDPOINTS.SEARCHPUSH(suitId, nameVal),
      false,
      { type }
    );
  static getWebPushAnalytics = (suitId, data) =>
    superAgentInstance(
      "get",
      API_ENDPOINTS.WEBPUSHSUBSCRIBERS(suitId),
      false,
      data
    );
  static getCampaignAnalytics = (suitId, campaignId, data) =>
    superAgentInstance(
      "get",
      API_ENDPOINTS.Campaign_ANALYTICS(suitId, campaignId),
      false,
      data
    );
  static deleteCampaign = (suitId, campaignId) => {
    return superAgentInstance(
      "delete",
      API_ENDPOINTS.DELETEPUSH(suitId, campaignId)
    );
  };

  static getSuitWebPushStatistics = (suitId, data) =>
    superAgentInstance(
      "get",
      API_ENDPOINTS.WEBPUSHSTATICTICS(suitId),
      false,
      data
    );

  static deleteCampaignBulk = (suitId, campaignIds) =>
    superAgentInstance(
      "delete",
      API_ENDPOINTS.PUSH_DELETE_BULK(suitId),
      campaignIds
    );

  static getSuitAppPushs = (suitId, apiKey, data) =>
    superAgentInstance(
      "get",
      API_ENDPOINTS.getSuitAppPushs(suitId, apiKey, data)
    );

  static getSuitWebPushs = (suitId, data) =>
    superAgentInstance("get", API_ENDPOINTS.getSuitWebPushs(suitId, data));

  static getSuitSMS = (suitId, data) =>
    superAgentInstance("get", API_ENDPOINTS.getSuitSMS(suitId, data));

  static getSuitEmails = (suitId, start, end, page) =>
    superAgentInstance(
      "get",
      API_ENDPOINTS.PUSH.getSuitEmails(suitId, start, end, page)
    );

  // SMART LINKS
  static createTargetList = (suitId, data, query = "upload_files=true") => {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key], data.file.name);
    });
    return superAgentInstance(
      "post",
      API_ENDPOINTS.TARGET_LIST.ALL(suitId),
      formData,
      query
    );
  };

  static listTargetList = (suitId) => {
    return superAgentInstance("get", API_ENDPOINTS.TARGET_LIST.ALL(suitId)).set(
      "Accept",
      "application/json"
    );
  };

  static deleteTargetList = (suitId, targetListName) => {
    return superAgentInstance(
      "delete",
      API_ENDPOINTS.TARGET_LIST.SINGLE(suitId, targetListName)
    ).set("Accept", "application/json");
  };

  // deleteTargetListBulk = {"sms_target_lists": ["test", "test"]} names
  static deleteTargetListBulk = (suitId, targetListNames) => {
    return superAgentInstance(
      "delete",
      API_ENDPOINTS.TARGET_LIST.BULK(suitId),
      targetListNames
    ).set("Accept", "application/json");
  };

  static downloadTargetList = (suitId, targetListName) => {
    return superAgentInstance(
      "get",
      API_ENDPOINTS.TARGET_LIST.DOWNLOAD(suitId, targetListName)
    ).set("Accept", "application/json");
  };

  static getEmailCampaigns = (suitId) => {
    return superAgentInstance(
      "get",
      API_ENDPOINTS.EMAIL_CAMPAIGNS.ALL(suitId)
    ).set("Accept", "application/json");
  };
}
