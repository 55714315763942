import classNames from "classnames";
import { OutsideClick } from "components";
import React from "react";
import { Navbar as ReactBootstrapNavbar } from "react-bootstrap";
import updateChildElementOfType from "./../utils/updateChildElementOfType";
import classes from "./Navbar.scss";

const Navbar = (props) => {
  const { className, children, ...otherProps } = props;

  const navbarClass = classNames(className, classes.navbar);

  const updatedChildren = updateChildElementOfType(
    children,
    [OutsideClick],
    [
      {
        targetType: Navbar.Collapse,
        props: (prevProps) => ({
          className: `${prevProps.className} ${classes.navbarCollapse}`,
        }),
      },
    ]
  );

  return (
    <ReactBootstrapNavbar className={navbarClass} {...otherProps}>
      {updatedChildren}
    </ReactBootstrapNavbar>
  );
};

export default Navbar;
