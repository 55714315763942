export const extractWhatsAppVariables = (message) => {
  const regex = /{{\s*([\w\d]+)\s*}}/g;
  const variables = [];
  let match;

  while ((match = regex.exec(message)) !== null) {
    const variable = match[1];
    variables.push(variable);
  }

  return variables;
};

const BODY = "BODY";
const HEADER = "HEADER";
const BUTTONS = "BUTTONS";

export const extractWhatsAppComponents = (components, category) => {
  let bodyVariables;
  let headerVariables;
  let buttonsVariables;

  let bodyComponent;
  let headerComponent;
  let buttonsComponent;

  let notSupportedComponents = [];

  if (category !== "AUTHENTICATION") {
    Object.values(components).forEach((component) => {
      if (component.type === BODY) {
        bodyVariables = extractWhatsAppVariables(component.text);
        bodyComponent = component;
      }

      if (component.type === HEADER) {
        if (component["text"] && component["format"] === "TEXT") {
          headerVariables = extractWhatsAppVariables(component.text);
          headerComponent = component;
        } else if (component["format"] !== "TEXT") {
          notSupportedComponents.push(component);
        }
      }
    });
  } else {
    Object.values(components).forEach((component) => {
      if (component.type === BODY) {
        bodyVariables = [""];
        bodyComponent = component;
      }

      if (component.type === BUTTONS) {
        // get the first button
        const button = component.buttons[0];

        buttonsVariables = [button.text];
        buttonsComponent = button;
      }
    });
  }

  return {
    body: bodyVariables,
    header: headerVariables,
    buttons: buttonsVariables,
    bodyComponent,
    headerComponent,
    buttonsComponent,
    notSupportedComponents,
  };
};
