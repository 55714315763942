import { Button } from "antd";
import PropTypes from "prop-types";
import React from "react";
import { I18n } from "react-redux-i18n";
import classes from "./SecondaryButton.scss";

/**
 * A secondary button component.
 *
 * @param {Object} props - The props object.
 * @param {boolean} props.pending - Whether the button is in a pending state.
 * @param {string|ReactNode} props.value - The text or ReactNode to display inside the button.
 * @param {ReactNode} props.icon - The icon to display inside the button.
 * @param {Object} props.style - The inline style object to apply to the button.
 * @param {function} props.onClick - The function to call when the button is clicked.
 * @param {boolean} props.disabled - Whether the button is disabled.
 * @param {boolean} props.block - Whether the button should take up the full width of its container.
 * @param {string} props.className - The CSS class name(s) to apply to the button.
 * @param {string} props.type - The type of the button (e.g. "button", "submit", "reset").
 * @param {string} props.size - The size of the button (e.g. "small", "medium", "large").
 * @returns {ReactNode} The rendered button component.
 */
export default function SecondaryButton({
  pending,
  value,
  icon,
  style,
  onClick,
  disabled,
  block,
  className,
  type,
  size,
  children,
  icon2,
  id,
  ...rest
}) {
  return (
    <Button
      id={id}
      htmlType={type}
      style={style}
      className={`${className} ${classes["our-secondary-btn"]}`}
      onClick={onClick}
      disabled={disabled}
      size={size}
      block={block}
      loading={pending}
      {...rest}
    >
      {icon}
      {typeof value === "string" ? I18n.t(value) : value}
      {children}
      {icon2}
    </Button>
  );
}

SecondaryButton.propTypes = {
  pending: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  icon: PropTypes.node,
  style: PropTypes.object,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  block: PropTypes.bool,
  className: PropTypes.string,
  type: PropTypes.string,
  size: PropTypes.string,
  children: PropTypes.node,
  icon2: PropTypes.node,
};

SecondaryButton.defaultProps = {
  type: "button",
  size: "large",
};
