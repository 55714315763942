import { Col, Modal, Row } from "components";
import PropTypes from "prop-types";
import React, { Component } from "react";
import Swiper from "react-id-swiper";
import { connect } from "react-redux";
import { Translate } from "react-redux-i18n";
import { disableTourSlider, getUserSuits } from "reducers/user";
import Digitalfirst from "static/Digitalfirst.png";
import Digitalforth from "static/Digitalforth.png";
import Digitalsecond from "static/Digitalsecond.png";
import Digitalthird from "static/Digitalthird.png";
import apppushicon from "static/apppushicon.png";
import mdpicon from "static/mdpicon.png";
import smartdeeplinkicon from "static/smartdeeplinkicon.png";
import splashicon from "static/splashicon.png";
import webpushicon from "static/webpushicon.png";
import classes from "./PrjectGuid.scss";

let index = 0;

class ProjectGuid extends Component {
  constructor(props) {
    super(props);
    this.goNext = this.goNext.bind(this);
    this.goPrev = this.goPrev.bind(this);
    this.hide = this.hide.bind(this);
    this.show = this.show.bind(this);
    this.swiper = null;
    props.getUserSuits();
    this.state = {
      valid: true,
      done: true,
      show: false,
    };
  }
  static contextTypes = {
    router: PropTypes.object.isRequired,
  };
  goNext() {
    if (this.swiper) {
      this.swiper.slideNext();
    }
    if (index < 5) {
      index = index + 1;
      this.setState({ valid: false });
    }
    if (index === 4) {
      this.setState({ done: false });
    }
  }

  goPrev() {
    if (this.swiper) {
      this.swiper.slidePrev();
    }
    if (index !== 0) {
      index = index - 1;
      this.setState({ done: true });
    }
    if (index === 0) {
      this.setState({ valid: true });
    }
  }

  componentWillMount() {
    index = 0;
    const {
      user: { suits },
    } = this.props;
    if (suits.length === 0) {
      this.show();
    }
  }
  handelswiper(node) {
    if (node) {
      this.swiper = node.swiper;
    }
  }
  hide() {
    this.setState({ show: false }, () => {
      this.props.disableTourSlider();
    });
  }
  show() {
    this.setState({ show: true });
  }

  render() {
    const params = {
      pagination: {
        el: ".swiper-pagination",
        clickable: false,
      },
    };

    return (
      <div>
        <Modal
          bsSize="large"
          show={this.state.show}
          dialogClassName={classes.addModal}
        >
          <Modal.Body>
            <div className="mainContent">
              <Swiper {...params} ref={(node) => this.handelswiper(node)}>
                <div className={classes.slide}>
                  <Row>
                    <Col md={3}>
                      <img className={classes.imgico} src={Digitalfirst} />
                    </Col>
                    <Col md={8}>
                      <img
                        className={classes.secondslidesmallicon}
                        src={apppushicon}
                      />
                      <img
                        className={classes.secondslidesmallicon}
                        src={smartdeeplinkicon}
                      />
                      <img
                        className={classes.secondslidesmallicon}
                        src={webpushicon}
                      />
                      <img
                        className={classes.secondslidesmallicon}
                        src={mdpicon}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <p className={classes.bulidmobileappsfaster}>
                      <Translate value="Go Viral on social media " />
                    </p>

                    <p className={classes.bodycontant}>
                      <Translate value="Let your users do the marketing for you, within your landing pages enjoy the social share buttons which are deep links that would open the app to share with a click " />
                    </p>
                  </Row>
                </div>

                <div className={classes.slide}>
                  <Row>
                    <Col md={3}>
                      <img className={classes.imgico} src={Digitalsecond} />
                    </Col>
                    <Col md={8}>
                      <img
                        className={classes.secondslidesmallicon}
                        src={apppushicon}
                      />
                      <img
                        className={classes.secondslidesmallicon}
                        src={smartdeeplinkicon}
                      />
                      <img
                        className={classes.secondslidesmallicon}
                        src={webpushicon}
                      />
                      <img
                        className={classes.secondslidesmallicon}
                        src={mdpicon}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <p className={classes.retain}>
                      {" "}
                      <Translate value="More SMS campaign with less cost" />
                    </p>
                    <p className={classes.secondSlideContant}>
                      <Translate value="Affordable, Media-rich SMS campaign for you, gain a competitive advantage over your competitors without breaking the bank" />
                    </p>
                  </Row>
                </div>
                <div className={classes.slide}>
                  <Row>
                    <Row>
                      <Col md={3}>
                        <img className={classes.imgiconn} src={Digitalthird} />
                      </Col>
                      <Col md={8}>
                        <img
                          className={classes.secondslidesmallicon}
                          src={apppushicon}
                        />
                        <img
                          className={classes.secondslidesmallicon}
                          src={smartdeeplinkicon}
                        />
                        <img
                          className={classes.secondslidesmallicon}
                          src={webpushicon}
                        />
                        <img
                          className={classes.secondslidesmallicon}
                          src={mdpicon}
                        />
                      </Col>
                    </Row>
                  </Row>
                  <Row>
                    <p className={classes.retain}>
                      <Translate value="Reach your users wherever they’re" />
                    </p>
                    <p className={classes.secondSlideContant}>
                      <Translate value="Target your users wherever they’re. Use SMS, Email, Push Notification, your customers are always connected and engaging with you through appgain" />
                    </p>
                  </Row>
                </div>
                <div className={classes.slide}>
                  <Row>
                    <Col md={8}>
                      <img
                        className={classes.secondslidesmallicon}
                        src={apppushicon}
                      />
                      <img
                        className={classes.secondslidesmallicon}
                        src={smartdeeplinkicon}
                      />
                      <img
                        className={classes.secondslidesmallicon}
                        src={webpushicon}
                      />
                      <img
                        className={classes.secondslidesmallicon}
                        src={mdpicon}
                      />
                    </Col>
                    <Col md={3}>
                      <img className={classes.imgico} src={Digitalforth} />
                    </Col>
                  </Row>
                  <Row>
                    <p className={classes.retain}>
                      <Translate value="Always Analyze to Improve" />
                    </p>
                    <p className={classes.secondSlideContant}>
                      <Translate value="With Appgain’s Integration with Google analytics and HotJar, always know what campaign is working and what part are they engaging with the most " />
                    </p>
                  </Row>
                </div>
                <div className={classes.slide}>
                  <Row>
                    <Col md={2} />
                    <img className={classes.splashicon} src={splashicon} />
                    <Col md={1} />
                  </Row>
                  <Row>
                    <p className={classes.secondSlideContant}>
                      <Translate
                        value="To send push or email messages, you will need to create an
                      app on AppGainio"
                      />
                    </p>
                    <button
                      className={classes.createproject}
                      onClick={() => {
                        this.props.disableTourSlider();
                        this.context.router.push(`/create-new-project`);
                      }}
                    >
                      <Translate value="Create your first project" />
                    </button>
                  </Row>
                </div>
              </Swiper>
              {this.state.done && (
                <a
                  className={classes.skip}
                  onClick={this.state.valid ? this.hide : this.goPrev}
                >
                  <Translate value={this.state.valid ? "Skip" : "Back"} />
                </a>
              )}
              <button
                onClick={this.state.done ? this.goNext : this.hide}
                className={classes.next}
              >
                <Translate value={this.state.done ? "Next" : "Done"} />
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default connect(
  (store) => ({
    user: store.user,
  }),
  {
    getUserSuits,
    disableTourSlider,
  }
)(ProjectGuid);
