import React from "react";
import classes from "./SpinLoader.scss";
const SpinLoader = () => {
  return (
    <div className={classes["loader-container"]}>
      <div className={classes["loader"]}>
        <i className="fa fa-spinner" aria-hidden="true" />
      </div>
    </div>
  );
};

export default SpinLoader;
