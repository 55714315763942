import { Icon, Tooltip } from "antd";
import React from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { I18n, Translate } from "react-redux-i18n";
import Copyicon from "../Copyicon";
import classes from "./DetailsList.scss";

class DetailsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: "hidden",
      outvisible: true,
      projectIcon: "",
      projectProfile: "",
      CustomDomain: "",
    };
  }

  visibleTool = (condition) => {
    if (condition) {
      this.setState({ visible: "visible", outvisible: false });
    } else {
      this.setState({ visible: "hidden", outvisible: true });
    }
  };

  handleInput = (e, type) => {
    this.props.functionParent(e, type);
  };

  renderRowItem = (data) => {
    let items = data;
    return (
      <div>
        <ol
          style={{
            paddingLeft: "16px",
            fontWeight: "bold",
          }}
        >
          {Object.entries(items).map((item, index) => (
            <li key={index}>
              <span className={classes.key}>{item[0]}:</span>
              <span className={classes.value}>{item[1]}</span>
            </li>
          ))}
        </ol>
      </div>
    );
  };

  render() {
    return (
      <dl className={classes.detailsList}>
        <div>
          {this.props.data.map(
            ({
              key,
              value,
              description,
              href,
              link,
              canCopy,
              info,
              inputField,
              lock,
              addButton,
            }) => (
              <div key={key}>
                <dt className={classes.key}>
                  <Translate value={key} />
                  {info && !href && (
                    <Tooltip title={I18n.t(description)} placement="right">
                      <Icon
                        type="info-circle"
                        theme="twoTone"
                        twoToneColor="#630bde"
                        style={{ fontSize: "16px" }}
                      />
                    </Tooltip>
                  )}
                  {addButton && lock ? addButton : null}
                  {info && href && link && (
                    <i
                      onMouseDown={() => this.visibleTool(true)}
                      className={`${classes.tooltip} fa fa-question-circle`}
                      style={{
                        cursor: "pointer",
                        color: "#24a0ed",
                        fontSize: "16px",
                        marginLeft: "6px",
                      }}
                      aria-hidden="true"
                    >
                      <OutsideClickHandler
                        disabled={this.state.outvisible}
                        onOutsideClick={() => {
                          this.visibleTool(false);
                        }}
                      >
                        <span id="tooltiptext" className={classes.tooltiptext}>
                          {I18n.t(description)}
                          <a href={href}>{I18n.t(link)}</a>
                        </span>
                      </OutsideClickHandler>
                    </i>
                  )}
                </dt>
                <dd
                  className={classes.value}
                  title={typeof value === "object" ? null : value}
                >
                  {inputField && lock ? (
                    inputField
                  ) : typeof value === "object" ? (
                    <span className={classes.widthsize}>
                      {this.renderRowItem(value)}
                    </span>
                  ) : (
                    <span className={classes.widthsize}>{value}</span>
                  )}
                  {canCopy && <Copyicon value={value} />}
                </dd>
              </div>
            )
          )}
        </div>
      </dl>
    );
  }
}

export default DetailsList;
