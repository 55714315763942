/**
 * @description check if sms communication channel is configured
 *
 * @param channels channels config object returned from notify server
 * @return boolean
 */
export const isSMSConfigured = (channels) => {
  // sms types
  // const AWS_SNS = "AWS-SNS";
  const SMS_GLOBAL = "HTTP_GET";
  // const UNIFONIC = "UREST";
  // const VICTORY_LINK = "SMS-VICTORYLINK";
  // const TWILIO = "SMS-TWILIO";
  // const ALFA = "SMS-ALFA";
  // const CEQUENS = "SMS-CEQUENS";
  // const JORMAIL = "SMS-JORMAIL";
  // const SMS_COUNTRY = "SMS-COUNRTY";
  // const MISR = "SMS-MISR";

  const isSMS = !!channels.sms;
  const isVendorValid = !!channels.sms.vendor.trim();

  const type = channels.sms.connection_info.type;

  if (type === SMS_GLOBAL) {
    const isValidSMSUrl =
      !!channels.sms.connection_info.url &&
      channels.sms.connection_info.url.trim().toLowerCase() !==
        "http://mysmsglobalurl";

    return isSMS && isVendorValid && isValidSMSUrl;
  } else {
    return isSMS && isVendorValid;
  }
};

/**
 * @description check if whats app communication channel is configured
 *
 * @param channels channels config object returned from notify server
 * @return boolean
 */
export const isWhatsAppConfigured = (channels) => {
  // whats app types
  const META = "meta";

  const isWhatsApp = !!channels.whatsapp;
  const isVendorValid = !!channels.whatsapp.vendor.trim();

  const type = channels.whatsapp.connection_info.type;

  if (type === META) {
    const isValidWABAID =
      !!channels.whatsapp.connection_info.waba_id &&
      !!channels.whatsapp.connection_info.waba_id.trim();

    const isValidSenderId =
      !!channels.whatsapp.connection_info.sender_id &&
      !!channels.whatsapp.connection_info.sender_id.trim();

    return isWhatsApp && isValidWABAID && isValidSenderId && isVendorValid;
  } else {
    return isWhatsApp && isVendorValid;
  }
};

/**
 * @description check if email communication channel is configured
 *
 * @param channels channels config object returned from notify server
 * @return boolean
 */
export const isEmailConfigured = (channels) => {
  // email types
  // const AWS_SES = "AWS-SES";
  const SMTP = "SMTP";
  // const SEND_GRID = "SEND-GRID";

  const isEmail = !!channels.email;
  const isVendorValid =
    !!channels.email.vendor &&
    channels.email.vendor.trim().toLowerCase() !== "my email vendor";

  const type = channels.email.connection_info.type;

  if (type === SMTP) {
    const isValidUserName =
      !!channels.email.connection_info.user_name &&
      channels.email.connection_info.user_name.trim().toLowerCase() !==
        "noreply@mydomain.com";

    const isValidServerUrl =
      !!channels.email.connection_info.serverURL &&
      channels.email.connection_info.serverURL.trim().toLowerCase() !==
        "http://smtp.mydomain.com";

    const isValidPassword =
      !!channels.email.connection_info.password &&
      channels.email.connection_info.password.trim().toLowerCase() !==
        "mypassword";

    return (
      isEmail &&
      isVendorValid &&
      isValidUserName &&
      isValidServerUrl &&
      isValidPassword
    );
  }

  return isEmail && isVendorValid;
};
