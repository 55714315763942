import api from "modules/api";
import EP from "consts/api";

export default class Templates {
  static getSuitTemplates = (suitId, type) =>
    api("get", EP.TEMPLATES.ALL(suitId), false, `type=${type}`);
  static getCurrentTemplate = (suitId, templateId) =>
    api("get", EP.TEMPLATES.SINGLE(suitId, templateId));
  static deleteTemplate = (suitId, templateId) =>
    api("delete", EP.TEMPLATES.SINGLE(suitId, templateId));
  static createTemplate = (suitId, data) =>
    api("post", EP.TEMPLATES.ALL(suitId), data);
  static editTemplate = (suitId, templateId, data) =>
    api("put", EP.TEMPLATES.SINGLE(suitId, templateId), data);
}
