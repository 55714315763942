import React from "react";
import { config } from "consts/api";
import classes from "../DevicePreview.scss";
import _Lodash from "lodash";
class LandingPreview extends React.Component {
  componentDidMount() {
    this.submitForm(this.props.data);
  }

  componentWillReceiveProps(nextProps) {
    if (!_Lodash.isEqual(nextProps.data, this.props.data)) {
      this.submitForm(nextProps.data);
    }
  }
  submitForm(data) {
    this.refs.form[0].value = JSON.stringify(data);
    this.refs.form.submit();
  }
  render() {
    let iframeClass = `${classes.preview} `;
    if (this.props.analytics) iframeClass += `${classes.previewAnalytics}`;
    return (
      <div className={classes.LandingPageReviewContain}>
        <iframe
          name="preview__iframe"
          className={iframeClass}
          frameBorder="0"
        />
        <form
          ref="form"
          method="post"
          target="preview__iframe"
          className="hidden"
          action={config.MOBILE_LANDING_PREVIEW}
        >
          <input type="hidden" name="data" />
        </form>
      </div>
    );
  }
}

LandingPreview.defaultProps = {
  data: {},
};

export default LandingPreview;
