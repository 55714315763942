import PropTypes from "prop-types";
import React from "react";
import NewBreadcrumb from "../NewBreadcrumb";
import classes from "./PageHeader.module.scss";

/**
 * PageHeader component.
 *
 * @component
 * @param {Object} props - Component props.
 * @param {Array.<string>} props.breadcrumbPath - Array of strings representing the breadcrumb path.
 * @param {Array.<React.Component>} [props.headerButtons] - Array of React components to be displayed as header buttons.
 * @returns {React.Element} Rendered PageHeader component.
 */
const PageHeader = ({ breadcrumbPath, headerButtons }) => {
  return (
    <div className={classes.header}>
      <NewBreadcrumb fullPath={breadcrumbPath} />
      <div className={classes.headerButtons}>
        {headerButtons &&
          headerButtons.map((Button, index) => {
            return (
              <div key={index} className={classes.headerButtonWrapper}>
                {Button}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default PageHeader;

PageHeader.propTypes = {
  // array of strings
  breadcrumbPath: PropTypes.array.isRequired,
  // array of react components
  headerButtons: PropTypes.array,
};
