import {
  Confirm,
  DetailsList,
  FileUploadInput,
  MultiValueInput,
  SpinLoader,
} from "components";
import _ from "lodash";
import uploadToImgur from "modules/imgur";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import { getMobileAppPlans, getMobileMarketingPlans } from "reducers/Select";
import {
  clearDemoData,
  deleteSuit,
  fillDemoData,
  generateAPIKey,
  getCurrentSuit,
  removeCurrentSuit,
  updateSuit,
} from "reducers/user";
import { Field, reduxForm } from "redux-form";
import { Toast } from "../../../../modules/toast";
import classes from "./ProjectInfo.scss";

class ProjectInfo extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      fileSizeError: false,
      lock: false,
      show: false,
      imgLoading: {},
      displayConfirm: false,
      urlText: "",
      projectName: "",
      iconURL: "",
      domainText: null,
    };
  }

  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  handleUploadImg = (e, value) => {
    if (!this.fileSizeCheck(value)) {
      return this.setState({ fileSizeError: true });
    }

    this.setState({ fileSizeError: false, imgLoading: true });

    uploadToImgur(
      "app_icon_url",
      this.props,
      value,
      (data) => {
        if (data.data.link) {
          this.setState({ imgLoading: false, iconURL: data.data.link });
        }
      },
      () => this.setState({ imgLoading: false })
    );
  };

  componentDidMount() {
    const { currentSuit } = this.props;

    this.setState({
      domainText: currentSuit.domain || "NA",
    });
  }

  handleShow = () => {
    this.setState({ show: true });
  };

  handleDeleteButton = async () => {
    const {
      deleteSuit,
      user: { activeSuitId },
    } = this.props;

    await deleteSuit(activeSuitId);
    this.handleDismiss();
    Toast.success(I18n.t("SettingsPage_projectInfo_deleteMsg"));
    this.context.router.push("/");
  };

  handleDismiss = () => {
    this.setState({ displayConfirm: false });
  };

  handleCreateButton = () => {
    const {
      user: { activeSuitId },
      fillDemoData,
    } = this.props;

    fillDemoData(activeSuitId);

    this.handleCreateDismiss();
  };

  generatAPI() {
    const { generateAPIKey, currentSuit } = this.props;

    let data = {
      suit_name: currentSuit.name,
      reset_fields: ["appApiKey"],
    };

    generateAPIKey(data, currentSuit.id);
  }

  editFunction = () => {
    const { currentSuit } = this.props;

    this.setState({
      projectName: currentSuit.name,
      urlText: currentSuit.web_url,
      domainText: currentSuit.domain ? currentSuit.domain : [],
      iconURL: currentSuit.app_icon_url,
    });

    if (this.state.lock) {
      this.setState({ lock: false });
    } else {
      this.setState({ lock: true });
    }
  };

  cancelConfirm = () => {
    this.setState({ displayConfirm: false });
  };

  onModalConfirm = () => {
    this.handleDeleteButton();
  };

  cancelClicked = () => {
    this.setState({ lock: false });
  };

  handleSave = () => {
    const {
      updateSuit,
      user: { activeSuitId },
      getCurrentSuit,
      currentSuit,
    } = this.props;

    let data = _.pickBy({
      status: "active",
      domain: this.state.domainText,
      suit_name: this.state.projectName,
      web_url:
        this.state.urlText === currentSuit.web_url ? null : this.state.urlText,
      app_icon_url:
        this.state.iconURL === currentSuit.app_icon_url
          ? null
          : this.state.iconURL,
      reset_fields: [],
    });

    updateSuit(activeSuitId, { ...data })
      .then(() => {
        Toast.success(I18n.t("SettingsPage_projectInfo_editMsg"));
        getCurrentSuit(activeSuitId);
      })
      .then(this.setState({ lock: false }));
  };

  fileSizeCheck = (file) => {
    return file.size / 1000 <= 500;
  };

  render() {
    const { userIsLoading, currentSuit } = this.props;

    const {
      displayConfirm,
      lock,
      projectName,
      fileSizeError,
      domainText,
      urlText,
      iconURL,
    } = this.state;

    let cdomain = false;

    // check if the Custom Domain existing in common_features or plan_product if exist appear it to the use if not hide it
    if (currentSuit) {
      currentSuit.plan_summary.common_features.map((feature) => {
        if (feature.name === "Custom Domain") {
          cdomain = true;
        }
      });

      currentSuit.plan_summary.plan_product.map((feature) => {
        if (feature.name === "Custom Domain") {
          cdomain = true;
        }
      });
    }

    let changedFields = {};
    if (currentSuit) {
      changedFields = _.pickBy({
        status: "active",
        domain: domainText,
        suit_name: projectName,
        web_url: urlText === currentSuit.web_url ? null : urlText,
        app_icon_url: iconURL === currentSuit.app_icon_url ? null : iconURL,
        reset_fields: [],
      });
    }

    return (
      <div>
        {userIsLoading && <SpinLoader />}

        <div className={classes.projectInfo}>
          <h2 className={classes.secondaryTitle}>
            {I18n.t("SettingsPage_projectInfo_title")}
          </h2>
          <DetailsList
            data={
              cdomain
                ? [
                    {
                      key: "SettingsPage_projectInfo_StoreName_title",
                      value: currentSuit.name || "NA",
                      description: "SettingsPage_projectInfo_StoreName_desc",
                      canCopy: false,
                      info: true,
                      lock: lock,
                      inputField: (
                        <input
                          className={classes.storNameInput}
                          value={projectName}
                          onChange={(e) =>
                            this.setState({ projectName: e.target.value })
                          }
                          placeholder={I18n.t(
                            "SettingsPage_projectInfo_StoreName_desc_placeholder"
                          )}
                        />
                      ),
                    },
                    {
                      key: "subdomain",
                      value: `${currentSuit.subdomain}.shrinkit.me` || "NA",
                      description: "SettingsPage_projectInfo_Subdomain_desc",
                      canCopy: true,
                      info: true,
                    },

                    {
                      key: "SettingsPage_projectInfo_StoreLogo_title",
                      value: currentSuit.app_icon_url || "NA",
                      description: "SettingsPage_projectInfo_StoreLogo_desc",
                      canCopy: false,
                      info: true,
                      lock: this.state.lock,
                      inputField: (
                        <div className={classes.logoInputContainer}>
                          <Field
                            name="logo"
                            style={{ marginBottom: "20px" }}
                            accept="image/*"
                            component={FileUploadInput}
                            iconClass={
                              this.state.imgLoading["components[0].logo.src"]
                                ? "fa fa-spinner fa-spin"
                                : "fa fa-camera"
                            }
                            onChange={this.handleUploadImg}
                            hint={I18n.t("512px*512px, Size < or = to 500kb")}
                            optional
                            disabled={
                              this.state.imgLoading["components[0].logo.src"]
                            }
                          />
                        </div>
                      ),
                    },

                    {
                      key: "SettingsPage_projectInfo_Storewebsite_title",
                      value: currentSuit.web_url || "NA",
                      description: "SettingsPage_projectInfo_Storewebsite_desc",
                      canCopy: false,
                      info: true,
                      lock: this.state.lock,
                      inputField: (
                        <input
                          className={classes.websiteInput}
                          value={this.state.urlText}
                          onChange={(e) =>
                            this.setState({ urlText: e.target.value })
                          }
                          placeholder="http://myweb.com/"
                        />
                      ),
                    },
                    {
                      key: "custom domain",
                      value:
                        this.state.domainText &&
                        Array.isArray(this.state.domainText)
                          ? this.state.domainText.join(" | ")
                          : "NA",
                      description: "SettingsPage_projectInfo_CustomDomain_desc",
                      link: I18n.t(
                        "SettingsPage_projectInfo_CustomDomain_link"
                      ),
                      canCopy: false,
                      info: true,
                      lock: this.state.lock,
                      inputField: (
                        <div>
                          <MultiValueInput
                            name="domain"
                            values={this.state.domainText || []}
                            placeholder={I18n.t(
                              "SettingsPage_projectInfo_CustomDomain_placeholder"
                            )}
                            onValuesChange={(newValues) => {
                              this.setState({ domainText: newValues });
                            }}
                          />
                          <p>
                            {I18n.t(
                              "SettingsPage_projectInfo_CustomDomain_note"
                            )}
                          </p>
                        </div>
                      ),
                    },
                    {
                      key: "SettingsPage_projectInfo_StorewebType_title",
                      value: currentSuit.source || "NA",
                      description: "SettingsPage_projectInfo_StorewebType_desc",
                      canCopy: false,
                      info: true,
                    },
                  ]
                : [
                    {
                      key: "SettingsPage_projectInfo_StoreName_title",
                      value: currentSuit.name || "NA",
                      description: "SettingsPage_projectInfo_StoreName_desc",
                      canCopy: false,
                      info: true,
                      lock: this.state.lock,
                      inputField: (
                        <input
                          className={classes.websiteInput}
                          value={this.state.projectName}
                          onChange={(e) =>
                            this.setState({ projectName: e.target.value })
                          }
                          placeholder={I18n.t(
                            "SettingsPage_projectInfo_StoreName_desc_placeholder"
                          )}
                        />
                      ),
                    },
                    {
                      key: "subdomain",
                      value: `${currentSuit.subdomain}.shrinkit.me` || "NA",
                      description: "SettingsPage_projectInfo_Subdomain_desc2",
                      canCopy: true,
                      info: true,
                    },
                    {
                      key: "SettingsPage_projectInfo_StoreLogo_title",
                      value: currentSuit.app_icon_url || "NA",
                      description: "SettingsPage_projectInfo_StoreLogo_desc",
                      canCopy: false,
                      info: true,
                      lock: this.state.lock,
                      inputField: (
                        <div className={classes.logoInputContainer}>
                          <Field
                            name="logo"
                            style={{ marginBottom: "20px" }}
                            accept="image/*"
                            component={FileUploadInput}
                            iconClass={
                              this.state.imgLoading["components[0].logo.src"]
                                ? "fa fa-spinner fa-spin"
                                : "fa fa-camera"
                            }
                            onChange={this.handleUploadImg}
                            hint={I18n.t("512px*512px, Size < or = to 500kb")}
                            optional
                            disabled={
                              this.state.imgLoading["components[0].logo.src"]
                            }
                          />
                          <div></div>
                        </div>
                      ),
                    },
                    {
                      key: "SettingsPage_projectInfo_Storewebsite_title",
                      value: currentSuit.web_url || "NA",
                      description: "SettingsPage_projectInfo_Storewebsite_desc",
                      canCopy: false,
                      info: true,
                      lock: this.state.lock,
                      inputField: (
                        <input
                          className={classes.websiteDescriptionInput}
                          value={this.state.urlText}
                          onChange={(e) =>
                            this.setState({ urlText: e.target.value })
                          }
                          placeholder="http://myweb.com/"
                        />
                      ),
                    },
                    {
                      key: "SettingsPage_projectInfo_StorewebType_title",
                      value: currentSuit.source || "NA",
                      description: "SettingsPage_projectInfo_StorewebType_desc",
                      canCopy: false,
                      info: true,
                    },
                  ]
            }
          />

          <div className={classes.buttonsContainer}>
            {fileSizeError && (
              <p className={classes.imgSizeError}>
                {I18n.t("SettingsPage_projectInfo_fileSize_note")}
              </p>
            )}

            {lock === true ? (
              <button
                className={classes.saveBtn}
                disabled={
                  Object.keys(changedFields).length === 3 &&
                  changedFields.suit_name === currentSuit.name
                }
                onClick={() => {
                  this.handleSave();
                }}
              >
                {I18n.t("save")}
              </button>
            ) : (
              <button
                className={classes.editBtn}
                onClick={() => {
                  this.editFunction();
                }}
              >
                {I18n.t("edit")}
              </button>
            )}
            <button
              onClick={() => this.setState({ displayConfirm: true })}
              className={classes.deleteBtn}
            >
              {I18n.t("delete")}
            </button>
          </div>
        </div>

        <Confirm
          onConfirm={this.onModalConfirm}
          onClose={this.cancelConfirm}
          display={displayConfirm}
          title="SettingsPage_projectInfo_deleteConfirm_title"
          description="SettingsPage_projectInfo_deleteConfirm_desc"
          btnText="SettingsPage_projectInfo_deleteConfirm_title"
          btnType="critical"
          isPending={userIsLoading}
        />
      </div>
    );
  }
}
export default reduxForm({
  form: "configadvanced",
})(
  connect(
    (store) => ({
      user: store.user,
      userIsLoading: store.user.isPending,
    }),
    {
      generateAPIKey,
      getCurrentSuit,
      getMobileMarketingPlans,
      getMobileAppPlans,
      fillDemoData,
      deleteSuit,
      clearDemoData,
      updateSuit,
      removeCurrentSuit,
    }
  )(ProjectInfo)
);

ProjectInfo.propTypes = {
  currentSuit: PropTypes.shape({
    name: PropTypes.string.isRequired,
    subdomain: PropTypes.string.isRequired,
    web_url: PropTypes.string,
    app_icon_url: PropTypes.string.isRequired,
    plan_summary: PropTypes.object.isRequired,
    domain: PropTypes.any,
    id: PropTypes.string,
    source: PropTypes.string,
  }),
  userIsLoading: PropTypes.bool,
  updateSuit: PropTypes.func,
  fillDemoData: PropTypes.func,
  generateAPIKey: PropTypes.func,
  getCurrentSuit: PropTypes.func,
  deleteSuit: PropTypes.func,
  user: PropTypes.shape({
    activeSuitId: PropTypes.string,
  }),
};
