import { Radio, Select } from "antd";
import axios from "axios";
import { SpinLoader } from "components";
import PropTypes from "prop-types";
import React from "react";
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import { updateSuit } from "reducers/user";
import Kuwait from "static/Kuwait.svg";
import Qatar from "static/Qatar.svg";
import Turkey from "static/Turkey.svg";
import UAE from "static/UAE.svg";
import egypt from "static/egypt.svg";
import jordan from "static/jordan.svg";
import oman from "static/oman.svg";
import saudi from "static/saudi.svg";
import classes from "../OnBoardingModal.scss";

const countrySelection = [
  { icon: saudi, title: "onBoarding_SMS_package_Saudi", value: "Saudi" },
  { icon: UAE, title: "onBoarding_SMS_package_UAE", value: "UAE" },
  { icon: oman, title: "onBoarding_SMS_package_Oman", value: "Oman" },
  { icon: Kuwait, title: "onBoarding_SMS_package_Kuwait", value: "Kuwait" },
  { icon: Qatar, title: "onBoarding_SMS_package_Qatar", value: "Qatar" },
  { icon: jordan, title: "onBoarding_SMS_package_Jordan", value: "Jordan" },
  { icon: egypt, title: "onBoarding_SMS_package_Egypt", value: "Egypt" },
  { icon: Turkey, title: "onBoarding_SMS_package_turkiye", value: "turkiye" },
];

const packageSelection = [
  { title: I18n.t("5000 SMS"), value: "5000 SMS" },
  { title: I18n.t("20,000 SMS"), value: "20,000 SMS" },
  { title: I18n.t("50,000 SMS"), value: "50,000 SMS" },
  { title: I18n.t("100,000 SMS"), value: "100,000 SMS" },
  { title: I18n.t("250,000 SMS"), value: "250,000 SMS" },
  { title: I18n.t("300,000 SMS"), value: "300,000 SMS" },
  { title: I18n.t("500,000 SMS"), value: "500,000 SMS" },
  { title: I18n.t("1,000,000 SMS"), value: "1,000,000 SMS" },
];

class SMSPackage extends React.Component {
  state = {
    country: "",
    Package: "",
    value: "",
    isPending: false,
  };

  handleChangeCountry = (e) => {
    this.setState({ country: e });
  };

  handleChangePackage = (e) => {
    this.setState({ Package: e.target?.value });
  };

  handleSubmit = async () => {
    const { country, Package } = this.state;

    const {
      user: {
        data: { name, mobile_number, email },
      },
    } = this.props;

    try {
      this.setState({ isPending: true });
      await axios.post(
        `https://notify.appgain.io/5e1748405f5dd4000c438527/send`,
        {
          email: {
            $customerName: name,
            $customerEmail: email,
            $country: country,
            $smsPackage: Package,
            $customerPhone: mobile_number,

            template_name: "SMS Package Purchase",
            subject: "SMS Package Purchase",
            type: "HTML",
            receivers: [
              {
                userID: "12321",
                email: "sales@appgain.io",
              },
            ],
          },
        },

        {
          headers: {
            "Content-Type": "application/json",
            appApiKey:
              "ffe1e9643c4ec484c119fb595d19e3da8db95c2023fae1b7d1c12a51e66f054a",
          },
        }
      );

      this.props.handleOnboardingSteps("sms", "package");
      this.props.handleSteps();
    } catch (error) {
      console.error(error);
    } finally {
      this.setState({ isPending: false });
    }
  };

  render() {
    const { handleBack, isArabic } = this.props;
    const { isPending } = this.state;
    return (
      <div className={classes.SMSPackage}>
        {isPending && <SpinLoader />}

        <form className={classes.SMSPackagePurchase}>
          <div className={classes.SMSInput}>
            <label>{I18n.t("onBoarding_SMS_package_countryLabel")}</label>

            <Select
              showSearch
              placeholder={I18n.t(
                "onBoarding_SMS_package_countryPlaceholder_saudi"
              )}
              optionFilterProp="children"
              onChange={(e) => this.handleChangeCountry(e)}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {countrySelection?.map((country, index) => (
                <Select.Option key={index} value={country.value}>
                  <img
                    src={country.icon}
                    alt={country.value}
                    className={classes.flag}
                  />
                  {I18n.t(country.title)}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div className={classes.SMSInput}>
            <label>{I18n.t("onBoarding_SMS_package_packageLabel")}</label>

            <Radio.Group
              onChange={this.handleChangePackage}
              value={this.state.value}
            >
              <Select
                showSearch
                placeholder={I18n.t(
                  "onBoarding_SMS_package_packagePlaceholder"
                )}
                optionFilterProp="children"
                onChange={(e) => this.handleChangePackage(e)}
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                onSearch={this.onSearch}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {packageSelection?.map((Package, index) => (
                  <Select.Option key={index} value={Package.value}>
                    <Radio value={Package.value}>{Package.title}</Radio>
                  </Select.Option>
                ))}
              </Select>
            </Radio.Group>
          </div>
        </form>
        <div className="buttonsContainer">
          <button
            onClick={() => {
              handleBack();
            }}
            className="confirm cancel"
          >
            {isArabic ? <BsArrowRightShort /> : <BsArrowLeftShort />}
            <span>{I18n.t("backBtn")}</span>
          </button>

          <button
            onClick={() => {
              this.handleSubmit();
            }}
            className="confirm"
          >
            <span>{I18n.t("chargeBtn")}</span>
            {isArabic ? <BsArrowLeftShort /> : <BsArrowRightShort />}
          </button>
        </div>
      </div>
    );
  }
}

export default connect(
  (store) => ({
    user: store.user,
  }),
  {
    updateSuit,
  }
)(SMSPackage);

SMSPackage.propTypes = {
  handleBack: PropTypes.func,
  handleOnboardingSteps: PropTypes.func,
  handleSteps: PropTypes.func,
  isArabic: PropTypes.bool,
  user: PropTypes.object,
};
